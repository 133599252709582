import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AlertController, ModalController } from '@ionic/angular';
import { BuyAtStoreComponent } from 'src/app/extra/modals/buy-at-store/buy-at-store.component';
import { StoreHelplineHoursComponent } from 'src/app/extra/modals/store-helpline-hours/store-helpline-hours.component';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { LocationService } from 'src/app/services/location.service';
import { StoreService } from 'src/app/services/store.service';
import { StoreNotFoundComponent } from 'src/app/shared/home/store-not-found/store-not-found.component';
import { CategoryModalComponent } from '../floating-category/category-modal/category-modal.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

declare var cordova: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() showCart: any = false;
  @Input() showNotification: any = true;
  @Input() showSearch: any = false;
  @Input() showBackButton: any = false;
  @Input() backButtonRoute: any = '/';
  @Input() params: any = '';
  @Input() title: any = ''
  @Input() description: any = '';

  cartCount: number = 0;
  currentLocation: string = ''
  isLoaded: boolean = false;

  customerSupportData: any;

  iabRef: any;

  constructor(private cartService: CartService,
    public router: Router,
    private toastService: ToastsService,
    private locationService: LocationService,
    private alertController: AlertController,
    private authService: AuthService,
    private storeService: StoreService,
    private modalController: ModalController,
    private location: Location,
    private navController: NavController,
    private mixpanelService: MixedPanelsService,
    private iab: InAppBrowser
  ) {

  }

  get address() {
    return this.locationService.address;
  }

  get addressPlace() {
    return this.locationService.addressPlace;
  }

  get currentAddress() {
    return localStorage.getItem('currentAddress')
  }

  get currentStoreDistance() {
    const distance = localStorage.getItem('storeDistance');
    return (distance ? distance : '1')
  }

  get estimateTime() {
    const time = localStorage.getItem('estimatedDeliveryTime');
    return (time ? Math.round(+time) : '5')
  }

  goToSearch() {
    this.mixpanelService.track("b_search", { "screenName" : "Homepage"});
    this.router.navigate(['search'], { queryParams: { q: '' }, queryParamsHandling: 'merge' })
  }

  ngOnInit() {
    this.cartService.cartSubject$.subscribe((cartData: any) => {
      if (cartData.length === 0) {
        this.cartCount = 0;
        this.isLoaded = true;
      }
      else {
        this.cartCount = cartData.cartProducts.length;
        this.isLoaded = true;
      }
    }
    );
    this.title = 'Your location'
    this.description = 'I-Thum Tower,Sector-62 Noida,201301'
  }

  navigateToCart() {
    if (this.cartCount === 0) {
      this.toastService.showToast('Please add products to cart first')
    }
    else {
      this.router.navigate(['cart']);
    }
  }

  navigateToComponent() {
    this.navController.setDirection('back');
    this.router.navigate([this.backButtonRoute])
  }

  checkIfUserLoggedIn(route: string, section: string = '') {
    const isLoggedIn = this.authService.getToken()
    if (isLoggedIn) {
      this.router.navigate([route])
    }
    else {
      this.showAlert(section);
    }
  }

  async changeCurrentLocation() {
    const modal = await this.modalController.create({
      component: StoreNotFoundComponent,
      backdropDismiss: true,
      cssClass: 'storeNotFound',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      keyboardClose: false,
      componentProps: {
        sectionType: 'search'
      },
      canDismiss: (data?: any, role?: string) => {
        return new Promise<boolean>(resolve => resolve(role === "close" || role === 'backdrop'))
      }
    });
    return await modal.present();
  }

  // -------------------------------Alert if user is not logged in----------------------

  async showAlert(sectionName: string = '') {
    const alert = await this.alertController.create({
      cssClass: 'custom-login-alert',
      header: 'Login Required',
      message: `Please log in to access the ${sectionName} section.`,
      buttons: [
        {
          text: 'Keep browsing',
          role: 'cancel',
          cssClass: 'secondary-option',
          handler: () => {
          }
        }, {
          cssClass: 'primary-option',
          text: 'Proceed to login',
          handler: () => {
            const currentUrl = this.router.url;
            const navigationExtras = {
              state: {
                redirectUrl: currentUrl
              }
            };
            this.router.navigate(['login'], navigationExtras)

          }
        }
      ]
    });

    await alert.present();

  }

  contactStoreSupport() {
    this.storeService.storeHelplineHours().subscribe((res: any) => {
      if (res?.success) {
        // this.mixpanelService.track("Call Support Clicked", {});
        this.mixpanelService.track("b_order_on_call", { "screenName" : "Homepage"});

        this.callHelpline();
      }
      else {
        this.customerSupportData = res?.customerSupportHours
        this.openHelplineHoursModal(res?.modalData)
      }
    })
  }

  // Code for android only
  // openWhatsAppChat() {
  //   this.mixpanel.track("WhatsApp Clicked", {});

  //   const countryCode = '+91'; 
  //   const phoneNumber = `${countryCode}${this.storeService.StoreContactNo}`;
  //   const message = "hi";

  //   const encodedMessage = encodeURIComponent(message.replace(/\+/g, '%2B').replace(/ /g, '%20'));
  //   const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
  //   window.open(url, '_blank');

  // }

  // Common code for both android and iOS
  openWhatsAppChat() {
    // this.mixpanelService.track("WhatsApp Clicked", {});
    this.mixpanelService.track("b_order_on_whatsapp", { "screenName" : "Homepage"});

    const countryCode = '+91';
    const phoneNumber = `${countryCode}${this.storeService.StoreContactNo}`;
    const message = "hi";

    const encodedMessage = encodeURIComponent(message.replace(/\+/g, '%2B').replace(/ /g, '%20'));
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

    const browser = this.iab.create(url, '_system');
    browser.show();
  }

  async openHelplineHoursModal(modalData: any) {
    let modal = await this.modalController.create({
      component: StoreHelplineHoursComponent,
      cssClass: 'helpline-hours-modal',
      backdropDismiss: true,
      componentProps: {
        modalData: this.customerSupportData
      },
    })

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (data.status) {
      }
    }
  }

  // Code for android only
  // callHelpline() {
  //   const mobileNo = this.storeService.StoreContactNo
  //   window.open('tel:' + mobileNo, '_system');
  // }

  // Common code for both android and iOS
  callHelpline() {
    console.log("call helpline called")
    const mobileNo = this.storeService.StoreContactNo;
    const telUrl = 'tel:' + mobileNo;

    const browser = this.iab.create(telUrl, '_system');
    browser.show();
  }


  async buyatstoreModal() {
    let modal = await this.modalController.create({
      component: BuyAtStoreComponent,
      cssClass: 'buyatStore-modal',
      backdropDismiss: true,
      componentProps: {
        parentScreenName: "Home page header"
      }
    })

    return await modal.present();
  }

  async categoryModal() {
    let modal = await this.modalController.create({
      component: CategoryModalComponent,
      cssClass: 'help-modal',
      backdropDismiss: true,
    })

    return await modal.present();
  }
}
