<!-- -----------home--page---------------- -->
<app-header [showBackButton]="false" [showSearch]="true" [showCart]="true" [showNotification]="true"
  [backButtonRoute]="currentUrl"></app-header>

<ion-content [fullscreen]="true">
    <!-- ------------track-order------------- -->
    <app-order-status-track *ngIf="isOrderActive" [orderId]="trackingOrderId"></app-order-status-track>
    <!-- ----------------end------------------ -->
    <ion-refresher class="refreshing-circle" slot="fixed" [pullFactor]="0.5" [pullMin]="100" [pullMax]="150"
      (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content refreshingText="loading products.."></ion-refresher-content>
    </ion-refresher>
    <!-- -------Category--list----- -->
    <div *ngIf="isLoaded">
      <div *ngFor="let section of sections">
        <div *ngIf="section?.sectionType === 'tiles'">
            <app-celebration-section [sectionData]="section"></app-celebration-section>
        </div>
         <div class="common-margin">
        <div class="banner" *ngIf="section?.sectionType === 'banner'">
          <!-- ------single-slider------- -->
          <div class="single-slider">
            <swiper-container [centeredSlides]="true" [spaceBetween]="10" [slidesPerView]="section.bannerImages.length > 1 ? '1.2' : '1' " [initialSlide]="1.5" [modules]="swiperModules" [autoplay]="true" #swiper (afterinit)="swiperReady()" [pagination]="{clickable: true, dynamicBullets: true}">
              <swiper-slide class="ion-activatable ripple-parent rounded-rectangle" *ngFor="let banner of section?.bannerImages">
                <ion-img [src]="banner.imageUrl" alt=""
                  (click)="handleBannerRouting(banner?.type,banner,section?._id)" />
                  <ion-ripple-effect></ion-ripple-effect>
              </swiper-slide>
            </swiper-container>
          </div>
          <!-- ------------end---------------- -->
        </div>
        <div class="category" *ngIf="section.sectionType === 'category'">
          <div class="title-list">
            <ion-row>
              <ion-col size="12">
                <h6>{{ section.title }}</h6>
              </ion-col>
            </ion-row>
          </div>
          <ion-row class="category-type">
            <ion-col class="pointer ion-activatable ripple-parent rounded-rectangle" *ngFor="let category of section.categoryList" size="4" size-sm="2" size-md="3"
              size-lg="3" [routerLink]="['/category-list', category.slug, category._id]" (click)="navigateToCategory(category)">
              <ion-img src="{{ category.categoryImage[0] }}" alt=""
                [ngStyle]="{ 'background-color': category?.backgroundColorCode }" style="border-radius: 10px"
                (ionError)="loadDefaultImg($event)" />
              <p class="overflow-control">
                {{ category.categoryName | titlecase }}
              </p>
              <ion-ripple-effect></ion-ripple-effect>
            </ion-col>
          </ion-row>

        </div>
        <!-- -----------sub-category----------- -->
        <div *ngIf="section.sectionType === 'subCategory'">
          <div class="title-list">
            <ion-row>
              <ion-col size="12">
                <h6>{{section?.title | titlecase}}</h6>
              </ion-col>
            </ion-row>
          </div>
          <ion-row>
            <ion-col *ngFor="let category of section?.categoryList" class="pointer" size="4" size-sm="4" size-md="3"
              size-lg="3">
              <app-category-card [categoryData]="category"></app-category-card>
            </ion-col>
          </ion-row>
        </div>
        <!-- ---------------end---------------- -->
        <div class="productList" *ngIf="
          section.sectionType === 'productList' ||
          section.sectionType === 'sponsored' ||
          section.sectionType === 'newProducts' ||
          section.sectionType === 'subscription'
        ">
          <div class="title-list">
            <ion-row>
              <ion-col size="9">
                <h6>{{ section.title }}</h6>
              </ion-col>
              <ion-col size="3" class="ion-text-end">
                <h6 class="view-all" (click)="navigateToProductOffers(section)">View All</h6>
              </ion-col>
            </ion-row>
          </div>

          <div class="multi-slider" *ngIf="section.sectionType === 'sponsored' || section.sectionType === 'productList' || section.sectionType === 'newProducts' || section.sectionType === 'subscription'">
            <swiper-container [slidesPerView]="checkScreen()" [loop]="false">
              <swiper-slide *ngFor="let product of section?.productList">
                <app-product-card [cardType]="'list'" [productData]="product" 
                  [subscription-check]="section.sectionType === 'subscription'" [parentScreenName]="'Home page '+section?.sectionType+' section'"></app-product-card>
              </swiper-slide>
            </swiper-container>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div *ngIf="!isLoaded">
      <app-home-skeleton></app-home-skeleton>
    </div>
    <!-- --------end----------- -->
    <ion-infinite-scroll (ionInfinite)="loadMoreProducts($event)" threshold="30%">
    <!-- <ion-infinite-scroll-content
      *ngIf="totalPages > page"
      [loadingText]="loadingText"
      loadingSpinner="bubbles"
    > -->
    <ion-infinite-scroll-content [loadingText]="loadingText" loadingSpinner="bubbles">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
    <!-- ----------desktop-footer------------ -->
    <app-desktop-footer></app-desktop-footer>
  <!-- -----------------end------------------ -->
</ion-content>
<!-- ------------footer------------- -->
<ion-footer>
  <app-footer></app-footer>
</ion-footer>
<!-- ----------end------------- -->
