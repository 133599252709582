import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})
export class AboutusComponent  implements OnInit , ViewWillEnter{

  constructor(private router:Router) { }
  appVersion: any;

  ionViewWillEnter() {
    this.appVersion = environment.appVersion
  }

  ngOnInit() {}

  navigateTo(route:string=''){
    this.router.navigate([route], {queryParams:{ returnUrl : 'about-us' }})
  }

}
