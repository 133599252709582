import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit {

  @Input() id: any;
  @Input() appliedFilters: string[] = []

  brandData: any = [];
  valueChangesSubscription: Subscription | undefined;


  filterForm!: FormGroup;
  filters = [
    { label: 'Filter 1', value: 'filter1' },
    { label: 'Filter 2', value: 'filter2' },
  ];

  constructor(private modalController: ModalController,
    private categoryService: CategoryService,
    private fb: FormBuilder,
    private toastService: ToastsService
  ) { }

  ngOnInit() {
    this.brandList()
    // this.setAppliedFilters();
  }

  brandList() {
    this.categoryService.brandListByCategory(this.id).subscribe((res: any) => {
      if (res?.success) {
        this.brandData = res?.brands;
        this.initializeForm();
        if (this.brandData.length > 0) {
          this.setAppliedFilters();
        }
      }
    })
  }

  initializeForm() {
    this.filterForm = this.fb.group({});
    this.brandData.forEach((filter: any, index: string) => {
      this.filterForm.addControl('checkbox' + index, new FormControl(false));
    });

    // Subscribe to changes in the FormGroup value
    this.valueChangesSubscription = this.filterForm.valueChanges.subscribe(value => {
      this.enableDisableClearButton();
    });
  }

  enableDisableApplyButton() {
    const applyFilterButton = document.getElementById('applyFilterButton');

    if (applyFilterButton) {
      // Get the values of all checkboxes
      const checkboxValues = Object.values(this.filterForm.value);
      // Enable buttons if at least one checkbox is selected, otherwise disable them
      const atLeastOneCheckboxSelected = checkboxValues.some(value => value === true);
      // Enable/disable buttons based on checkbox state
      if (atLeastOneCheckboxSelected) {
        applyFilterButton.removeAttribute('disabled');
      } else {
        applyFilterButton.setAttribute('disabled', 'true');
      }
    }
  }

  enableDisableClearButton() {
    const clearFilterButton = document.getElementById('clearFilterButton');

    if (clearFilterButton) {
      // Get the values of all checkboxes
      const checkboxValues = Object.values(this.filterForm.value);
      // Enable buttons if at least one checkbox is selected, otherwise disable them
      const atLeastOneCheckboxSelected = checkboxValues.some(value => value === true);
      // Enable/disable buttons based on checkbox state
      if (atLeastOneCheckboxSelected) {
        clearFilterButton.removeAttribute('disabled');
      } else {
        clearFilterButton.setAttribute('disabled', 'true');
      }
    }
  }

  setAppliedFilters() {
    this.appliedFilters.forEach(appliedFilter => {
      // Find the index of the filter in the filters array
      const index = this.brandData.findIndex((filter: any) => filter._id === appliedFilter);
      if (index !== -1) {
        // Get the name of the corresponding form control
        const controlName = 'checkbox' + index;
        // Set the value of the form control to true
        this.filterForm.get(controlName)?.setValue(true);
      }
    });
  }




  applyFilter() {
    const selectedFilters = Object.keys(this.filterForm.controls)
      .filter(key => this.filterForm.controls[key].value)
      .map(key => this.brandData[+key.replace('checkbox', '')]._id);

    // Compare the arrays appliedFilters and selectedFilters

    if (this.areArraysEqual(this.appliedFilters, selectedFilters)) {
      this.toastService.showToast('Selected filters already applied!');
    } else {
      this.closeModal(true, selectedFilters);
    }
  }

  areArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  clearFilter() {
    Object.values(this.filterForm.controls).forEach(control => {
      control.setValue(false);
    });

    this.closeModal(true, []);
  }

  closeModal(status: boolean = false, data: any = []) {
    this.modalController.dismiss({ status, data });
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
