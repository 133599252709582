import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var google: any; // Declare the 'google' variable
import { } from 'googlemaps';

import { LocationService } from 'src/app/services/location.service';
import { Location } from 'src/app/interface';

import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent {
  @ViewChild('map') mapElement: any;
  map: google.maps.Map | undefined;
  // centerLocation = [28.672107, 77.453560]
  // address: string = "";
  // addressPlace: string = "";

  constructor(private locationService: LocationService, private router: Router,
    private toastService:ToastService
  ) { }

  get address() {
    return this.locationService.address;
  }

  get addressPlace() {
    return this.locationService.addressPlace;
  }

  get storeDistance() {
    return this.locationService.storeDistance;
  }

  ngAfterViewInit() {
    this.checkCurruntLocation();
  }

  checkCurruntLocation() {
    if (this.locationService.isLocationAvailable) {
      this.initMap();
    } else {
      // ask for the location permission and save currunt location.
    }
  }

  async initMap() {
    let coords = new google.maps.LatLng(this.locationService.location?.latitude, this.locationService.location?.longitude);
    let mapOptions: google.maps.MapOptions = {
      center: coords,
      zoom: environment.googleMapZoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: false,
      fullscreenControl: false
    }

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.map?.addListener("idle", () => {
      let center = this.map?.getCenter();
      // Check if 'center' is not undefined before using it
      if (center) {
        let latitude = center.lat();
        let longitude = center.lng();
        this.locationService.setLocation({ latitude, longitude, accuracy: 0 });
        this.fetchLocationAddress(this.locationService.location);
      } else {
        console.error("Center is undefined");
      }
    });

    // Create a custom control for location pin in center
    var locationPinDiv = document.createElement("div");
    var locationPinIcon = document.createElement("div");
    locationPinIcon.className = "location-pin";
    locationPinIcon.innerHTML = '<img src="../../../assets/img/daykart/location.png" alt="Icon" style="width: 30px; height: 30px;">';
    locationPinDiv.appendChild(locationPinIcon);
    // Add the custom control to the map
    this.map?.controls[google.maps.ControlPosition.CENTER].push(locationPinDiv);

    // Create a custom control with a button for curruntLocation
    var currentLocationDiv = document.createElement("div");
    var currentLocationIcon = document.createElement("div");
    currentLocationIcon.className = "current-location-icon";
    currentLocationIcon.innerHTML = '<img src="../../../assets/img/daykart/Location Icon.svg" alt="Icon" style="width: 30px; height: 30px;"> <span>Go to current location</span>';
    currentLocationDiv.appendChild(currentLocationIcon);
    // Add the custom control to the map
    this.map?.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(currentLocationDiv);
    // Add a click event listener to the button
    currentLocationIcon.addEventListener("click", () =>{
      alert("Button Clicked!");
      
    });
  }

  setMapLocation(){
  const newCenter = { lat: 34.0522, lng: -118.2437 }; // Example coordinates (Los Angeles)
  this.map?.setCenter(newCenter);
  }

  fetchLocationAddress(location: Location | null) {
    const point = { lat: location?.latitude, lng: location?.longitude };
    this.locationService.pointLocationAddress(point).subscribe((res: any) => {
      this.locationService.userAddress.next(res.address);
      this.locationService.storeDistance = res.distance;
    });
  }

  completeAddress() {
    this.router.navigate(['/address-summary']);
  }


  // ----------------------------Location search------------------------------

  searchInput: any='';
  dataFound: number = 2;
  searchSuggestions: any;


  searchLocation() {
    if (this.searchInput === '') {
      this.searchSuggestions = [];
      return;
    }
    this.locationService.searchPlace({ input: this.searchInput }).subscribe((res: any) => {
      if (res.success) {
        this.searchSuggestions = res.data.predictions;
        if (this.searchSuggestions.length === 0) {
         this.dataFound = 0;
        }
        else {
          this.dataFound = 1;
        }
      }
    },
      (error) => {
        this.toastService.showToast(error?.message)
      })


  }

  selectAddress(prediction: any) {
    // this.newAddress(prediction.place_id);
    this.resetSearchField();
  }

  newAddress(placeId: string) {
    this.locationService.placeDetails({ placeId: placeId }).subscribe((res: any) => {
      if (res.success) {
        const location = {
          latitude : res?.location?.lat,
          longitude : res?.location?.lng,
          accuracy : 100
        }
        this.locationService.setLocation(location)
        this.fetchLocationAddress(location);
        // this.checkStoreAvailability(res.location.lat, res.location.lng);
      }
    },
    (error)=>{
      this.toastService.showToast(error?.message)
    })
  }

  resetSearchField() {
    this.searchInput = '';
    this.searchSuggestions = [];
    this.dataFound = 2;
  }

}
