<!-- ----header--section------ -->
<app-title-header [title]="'Account Setting'" [backButtonRoute]="'account'"></app-title-header>
<!-- ------------end------------>
<ion-content class="ion-padding-horizontal">
  <ion-row class="account-setting"> 
    <ion-col size="12">
      <h5>Request for Account Data Deletion</h5>
      <p>We understand the importance of your privacy and are committed to ensuring your data is handled securely. If you wish to delete your account and all associated data from our e-commerce grocery app, please fill out the following Google form. Your request will be processed promptly, and we will confirm the deletion once it is complete.</p>
      <a href="https://forms.gle/m1jYvntPfAh3yTRt5">Submit your data deletion request here</a>
    </ion-col>
  </ion-row>
</ion-content>