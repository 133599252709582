import {ComponentRef, inject, Injectable}            from '@angular/core';
import {ApiService}                                  from './api.service';
import {CacheService}                                from './cache.service';
import {CommonService}                               from './common.service';
import {ConfirmPopupComponent}                       from '../shared/components/confirm-popup/confirm-popup.component';
import {ComponentAppendService}                      from './component-append.service';
import {LogService}                                               from './log.service';
import {IConfirmPopup, IResponse, ISubProduct, ISubscriptionData} from '../shared/interfaces';
import {CONFIRM_BUTTONS_ACTION, STORAGE}                          from '../shared/constants';
import {Observable}                                               from 'rxjs';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private api: ApiService = inject(ApiService);
  private cacheService: CacheService = inject(CacheService);
  private common: CommonService = inject(CommonService);
  private logger: LogService = inject(LogService);
  private componentAppendService: ComponentAppendService = inject(ComponentAppendService);

  constructor(private mixpanelService: MixedPanelsService) {
    this.getSubscribed(true).then((r: ISubscriptionData[]) => this.logger.log('getSubscribed executed in constructor', r));
  }

  public getSubscriptionProducts(): Observable<any> {
    return this.api.get(this.api.apiEndpoint.getProducts);
  }

  public async getSubscribed(refresh: boolean = false): Promise<ISubscriptionData[]> {
    if (!refresh) {
      const subscriptions = await this.cacheService.retrieveData(STORAGE.SUBSCRIPTIONS);
      if (subscriptions) return subscriptions;
    }
    const response = await this.api.get(this.api.apiEndpoint.getSubscribed + window.localStorage.getItem(STORAGE.USER_MOBILE)).toPromise();
    if (response.success) {
      this.cacheService.cacheData(STORAGE.SUBSCRIPTIONS, response.data);
      return response.data;
    } else {
      return [];
    }
  }

  public async checkIfProductIsInSubscription(productId: string) {
    let dbProducts = await this.cacheService.retrieveData(STORAGE.SUB_PRODUCTS);
    //this.logger.log('checkIfProductIsInSubscription', dbProducts);
    if (!dbProducts || dbProducts.length === 0) {
      return new Promise<boolean>((resolve) => {
        this.getSubscriptionProducts().subscribe((response: any) => {
          if (response.success) {
            dbProducts = response.data.products;
            this.cacheService.cacheData(STORAGE.SUB_PRODUCTS, dbProducts);
            const isIn = dbProducts.some((sub: ISubProduct) => sub.product_id === productId);
            resolve(isIn);
          }
        });
      });
    } else {
      return dbProducts.some((sub: ISubProduct) => sub.product_id === productId);
    }
  }

  public async stopSubscription(_id: string, navigateBack?: boolean): Promise<void> {
    this.sendMixpanelEvent("Stop subscription popup", {subscriptionId: _id});
    const data: IConfirmPopup = {
      heading: {
        text: 'Are you sure you want to stop your subscription?',
      },
      message: {
        text: 'This will permanently delete your item from your subscription list. To resume with ease, would you like to pause it instead?',
      },
      buttons: {
        cancel: {//in this case it will be yes, go ahead
          text: 'Stop',
          action: CONFIRM_BUTTONS_ACTION.CANCEL,
        },
        ok: {//this will be pause
          text: 'Pause',
          action: CONFIRM_BUTTONS_ACTION.OK,
        },
      },
    };
    const confirmPopup: ComponentRef<any> = await this.componentAppendService.open(ConfirmPopupComponent, data);
    confirmPopup.instance.closed.subscribe(async (returnData: any): Promise<void> => {
      if (returnData === CONFIRM_BUTTONS_ACTION.OK) {
        this.sendMixpanelEvent("Proceed to pause popup button", {subscriptionId: _id});
        await this.showPausePopup(_id);
      } else if (returnData === CONFIRM_BUTTONS_ACTION.CANCEL) {
        //we stop
        const response: boolean = await this._stopSub(_id);
        if (response) {
          await this.common.showToast('Subscription stopped successfully');
          this.common.refreshSub$.next(true);
          if (navigateBack) {
            this.common.location.back();
          }
          this.sendMixpanelEvent("Stop subscription button", {subscriptionId: _id});
        } else {
          await this.common.showAlert('Oops!', 'Something went wrong. We could not stop your subscription. Please try again.');
        }
      } else {
        //we do not do anything as the user has just closed it
      }
      confirmPopup.destroy();
    });
  }

  public async showPausePopup(_id: string, navigateBack?: boolean): Promise<void> {
    this.sendMixpanelEvent("Pause subscription popup", {subscriptionId: _id});
    const data: IConfirmPopup = {
      icon: 'pause.svg',
      heading: {
        text: 'Pause Subscription',
        center_align: true,
      },
      message: {
        text: 'Are you sure you want to pause your grocery delivery subscription? You can resume it anytime from your Subscription Management.',
      },
      buttons: {
        cancel: {
          text: 'Cancel',
          action: CONFIRM_BUTTONS_ACTION.CANCEL,
        },
        ok: {//this will be pause
          text: 'Pause',
          action: CONFIRM_BUTTONS_ACTION.OK,
        },
      },
    };
    const confirmPopup: ComponentRef<any> = await this.componentAppendService.open(ConfirmPopupComponent, data);
    confirmPopup.instance.closed.subscribe(async (returnData: any): Promise<void> => {
      if (returnData === CONFIRM_BUTTONS_ACTION.OK) {
        const response: boolean = await this._pauseSub(_id);
        if (response) {
          await this.common.showToast('Subscription paused successfully');
          this.sendMixpanelEvent("Pause subscription button", {subscriptionId: _id});

          this.common.refreshSub$.next(true);
          if (navigateBack) {
            this.common.location.back();
          }
        } else {
          await this.common.showAlert('Oops!', 'Something went wrong. We could not pause your subscription. Please try again.');
        }
      }
      else if (returnData === CONFIRM_BUTTONS_ACTION.CANCEL){
        this.sendMixpanelEvent("Cancel button", {subscriptionId: _id});
      }
      confirmPopup.destroy();
    });
  }

  private async _stopSub(_id: string): Promise<boolean> {
    try {
      const response = await this.api.delete(this.api.apiEndpoint.stopSubscription + window.localStorage.getItem(STORAGE.USER_MOBILE) + '/' + _id).toPromise();
      return !!response.success;
    } catch (error) {
      this.logger.error('_stopSub', error);
      return false;
    }
  }

  private async _pauseSub(_id: string): Promise<boolean> {
    try {
      const response = await this.api.put(this.api.apiEndpoint.pauseRestartSubscription, {_id: _id, paused: true, mobile: window.localStorage.getItem(STORAGE.USER_MOBILE)}).toPromise();
      return !!response.success;
    } catch (error) {
      this.logger.error('_pauseSub', error);
      return false;
    }
  }

  public async restartSubscription(_id: string): Promise<void> {
    try {
      const response = await this.api.put(this.api.apiEndpoint.pauseRestartSubscription, {_id: _id, paused: false, mobile: window.localStorage.getItem(STORAGE.USER_MOBILE)}).toPromise();
      this.logger.log('restartSubscription', response);
      if (response.success) {
        await this.common.showToast('Subscription restarted successfully');
        this.common.refreshSub$.next(true);
      } else {
        await this.common.showAlert('Oops!', 'Something went wrong. We could not restart your subscription. Please try again.');
      }
    } catch (error) {
      this.logger.error('onRestartButtonClick', error);
      await this.common.showAlert('Oops!', 'Something went wrong. We could not restart your subscription. Please try again.');
    }
  }

  public async startSubscription(subData: ISubscriptionData): Promise<boolean> {
    try {
      let data: any = subData;
      delete data._id;
      delete data.product;
      delete data.paused;
      const response: IResponse = await this.api.post(this.api.apiEndpoint.startSubscription, {...subData, mobile: window.localStorage.getItem(STORAGE.USER_MOBILE)}).toPromise();
      if (response.success) {
        this.logger.log('startSubscription success', response.data);
        return true;
      } else {
        this.logger.error('startSubscription error', response);
        return false;
      }
    } catch (error) {
      this.logger.log('error in startSubscription', error);
      return false;
    }
  }

  public async editSubscription(subData: ISubscriptionData): Promise<boolean> {
    try {
      const response = await this.api.put(this.api.apiEndpoint.editSubscription, {...subData, mobile: window.localStorage.getItem(STORAGE.USER_MOBILE)}).toPromise();
      this.logger.log('editSubscription', response);
      if (response.success) {
        await this.common.showToast('Subscription edited successfully');
        this.common.refreshSub$.next(true);
        return true;
      } else {
        await this.common.showAlert('Oops!', 'Something went wrong. We could not edit your subscription. Please try again.');
      }
      return false;
    } catch (error) {
      this.logger.error('editSubscription', error);
      await this.common.showAlert('Oops!', 'Something went wrong. We could not edit your subscription. Please try again.');
      return false;
    }
  }

  sendMixpanelEvent(eventType:string='', eventData: any = {}){
		switch(eventType){
      case 'Stop subscription popup': {
        this.mixpanelService.track("s_stop_popup", {"screenName" : "My subscriptions","subscriptionId": eventData?.subscriptionId});
			break;
    }
		  case 'Stop subscription button': {
			this.mixpanelService.track("b_stop", { "screenName" : "Stop subscription popup", "subscriptionId": eventData?.subscriptionId});
			break;      
		}

    case 'Proceed to pause popup button': {
			this.mixpanelService.track("b_pause", { "screenName" : "Stop subscription popup", "subscriptionId": eventData?.subscriptionId});
			break;      
		}

    case 'Pause subscription popup': {
			this.mixpanelService.track("s_pause_popup", { "screenName" : "Stop subscription popup", "subscriptionId": eventData?.subscriptionId});
			break;      
		}

    case 'Pause subscription button': {
			this.mixpanelService.track("b_pause", { "screenName" : "Pause subscription popup", "subscriptionId": eventData?.subscriptionId});
			break;      
		}

    case 'Cancel button': {
			this.mixpanelService.track("b_cancel", { "screenName" : "Pause subscription popup", "subscriptionId": eventData?.subscriptionId});
			break;      
		}

		 

		}
	}
  
}
