import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private activeOrderInfoSubject = new BehaviorSubject<any>(null);
  activeOrderInfo$ = this.activeOrderInfoSubject.asObservable();

  constructor(private http:HttpClient) { 
    this.initializeActiveOrders();
  }

  orderList(page:number=1,limit:number=10) {
    return this.http.get(`${environment.apiUrl}/order?page=${page}&limit=${limit}`);
  }

  activeOrderList() {
    return this.http.get(`${environment.apiUrl}/order?type=active`);
  }

  placeOrder(payload:any){
    return this.http.post(`${environment.apiUrl}/order`, payload);
  }

  cancelOrder(body:any){
    return this.http.post(`${environment.apiUrl}user/order/cancel`,body);
  }

  orderDetails(id:any){
    return this.http.get(`${environment.apiUrl}/order/detail?orderId=${id}`);
  }

  orderTracking(id:any){
    return this.http.get(`${environment.apiUrl}/order/tracking?orderId=${id}`);
  }

  

  orderStatus(statusCode:number){
    switch (statusCode) {
      case 0:
        return 'Order is Pending'
      case 1:
        return 'Item is Ordered';
      case 2:
        return 'Order is Packed';
      case 3:
        return 'Order is Shipped';
      case 4:
        return 'Order is Delivered';
      case 5:
        return 'Order is Cancelled';
      default:
        return 'Invalid data'
    }
  }

  // ---------------------------------------Active order tracking-----------------------

  private initializeActiveOrders() {
    this.activeOrderList().subscribe((res:any)=>{
      if(res?.success){
        this.activeOrderInfoSubject.next(res?.orders)
      }
      else{
        this.activeOrderInfoSubject.next([]);
      }
    })
  }

  public updateActiveOrders() {
    this.initializeActiveOrders();
  }

  setActiveOrderInfo(order: any) {
    this.activeOrderInfoSubject.next(order);
    localStorage.setItem('activeOrderInfo', JSON.stringify(order));
  }

  clearActiveOrderInfo() {
    this.activeOrderInfoSubject.next(null);
    localStorage.removeItem('activeOrderInfo');
  }

  getActiveOrderInfo() {
    return this.activeOrderInfoSubject.value;
  }

  orderTrackingForPopup(id:any){
    return this.http.get(`${environment.apiUrl}/order/tracking?orderId=${id}&displayMode=popup`);
  }

  // -------------------------Cashfree payment--------------------------------------------

  placeOrderWithCashfree(payload:any){
    return this.http.post(`${environment.apiUrl}/cashfree/order`, payload);
  }

  verifyCashfreeOrder(payload:any){
    return this.http.post(`${environment.apiUrl}/cashfree/verify`, payload);
  }
}
