import {Injectable} from '@angular/core';
import {Storage}    from '@ionic/storage-angular';

@Injectable({
	            providedIn: 'root',
            })
export class StorageService {
	constructor(private storage: Storage) {
		this.init();
	}
	
	async init(): Promise<void> {
		// If using, define drivers here: await this.storage.defineDriver(/*...*/);
		await this.storage.create().then(() => {
			console.log('storage created: ');
		});
	}
	
	// Create and expose methods that users of this service can
	// call, for example:
	public set(key: string, value: object | string): Promise<any> | undefined {
		//check if value is object
		if (typeof value === 'object') {
			value = JSON.stringify(value);
		}
		return this.storage.set(key, value);
	}
	
	public async get(key: string): Promise<any> {
		const data = await this.storage.get(key);
		if (data && typeof data === 'string') {
			return JSON.parse(data);
		}
		return data;
	}
	
	public remove(key: string): Promise<any> | undefined {
		return this.storage.remove(key);
	}
	
	public clear(): Promise<any> | undefined {
		return this.storage.clear();
	}
}