import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { AddressService } from 'src/app/services/address.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit {

  @Input() addressId: any;
  @Input() data: any

  constructor(private popoverController: PopoverController,
    private router: Router,
    private alertController: AlertController,
    private addressService: AddressService,
    private toastService: ToastsService,
    private modalController: ModalController
  ) { }


  ngOnInit() {
  }

  editAddress() {
    this.popoverController.dismiss();
    this.modalController.dismiss();
    this.router.navigate(['address-summary'], { queryParams: { addressId: this.addressId } })
  }

  async openDeleteAddressAlert() {

    const alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: 'Are you sure you want to delete this address?',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'primary',
          role: 'cancel',
          handler: () => {
            this.popoverController.dismiss();
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            this.addressService.deleteAddress(this.addressId).subscribe((res: any) => {
              if (res?.success) {
                this.toastService.showToast(res?.message);
                alert.dismiss();
                this.dismissWithData(true,'deleteAddress');
              }
              else {
                this.toastService.showToast(res?.message)
                alert.dismiss();
              }
            })
          },
        },
      ],
    });
    await alert.present();
  }

  setDefaultAddress() {
    this.addressService.setDefaultAddress({ addressId: this.addressId }).subscribe((res: any) => {
      if (res?.success) {
        this.toastService.showToast(res?.message);
        this.dismissWithData(true,'defaultAddressChange');
      }
      else {
        this.popoverController.dismiss()
        this.toastService.showToast(res?.message);
      }
    })

  }

  dismissWithData(status: boolean = false, action:string='') {
    this.popoverController.dismiss({ data: {
      status : status,
      actionType : action,
      addressData: this.data
    } })
  }


}
