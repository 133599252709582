<!-- -------address--modal--------------- -->
<ion-content>
  <ion-row class="select-address">
    <ion-col size="12">
      <h2>
        {{ "select_address" | translate }}
      </h2>
    </ion-col>
    <ion-col size="8">
      <h5>{{ "your_saved_addresses" | translate }}</h5>
    </ion-col>
    <ion-col size="4" class="ion-text-end">
      <ion-button
        (click)="navigateToAddAddress()"
        expand="block"
        class="add-address-btn"
        >{{ "add_new" | translate }}+</ion-button
      >
    </ion-col>

    <ion-col size="12">
      <ion-radio-group [(ngModel)]="selectedAddressId">
        <ng-container>
          <ion-row
            class="border-section ion-margin-bottom"
            *ngFor="let address of addressData"
            (click)="onRowClick(address._id, address)"
          >
            <ion-col size="1" class="radio">
              <ion-radio labelPlacement="end" [value]="address?._id">
              </ion-radio>
            </ion-col>
            <ion-col size="8">
              <p>{{ address?.addressType | titlecase }}</p>
              <p class="user-address">
                {{ address?.completeAddress + " " + address?.googleAddress }}
              </p>
            </ion-col>
            <ion-col size="3" class="address-update">
              <span
                (click)="editAddress(address?._id); $event.stopPropagation()"
                >Edit</span
              >
              <ion-icon
                name="trash-outline"
                (click)="
                  openDeleteAddressAlert(address?._id); $event.stopPropagation()
                "
              ></ion-icon>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-radio-group>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer>
  <ion-toolbar class="custom-toolbar">
    <ion-row class="bottom-btn">
      <ion-col size="12">
        <ion-button
          expand="block"
          class="ion-margin-bottom common-btn"
          [disabled]="!selectedAddressId"
          (click)="useThisAddress()"
        >
          Use this Address</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-toolbar>
  
</ion-footer>
