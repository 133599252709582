export enum SEGMENT {
	MY_SUBSCRIPTIONS = 'my_subscriptions',
	EXPLORE = 'explore',
}
export enum CONFIRM_BUTTONS_ACTION {
	CANCEL = 'cancel',
	OK = 'ok',
}

export enum ENUMFREQUENCY {
	DAILY = 1,
	WEEKLY = 7,
	MONTHLY = 30,
	ALTERNATE_DAYS = 3
}

export const MONTHS: string[] = [
	'January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December',
];
export const FREQUENCY = [
	{
		name: 'Daily',
		value: ENUMFREQUENCY.DAILY,
	},
	{
		name: 'Weekly',
		value: ENUMFREQUENCY.WEEKLY,
	},
	{
		name: 'Monthly',
		value: ENUMFREQUENCY.MONTHLY,
	},
	{
		name: 'Alternate Days',
		value: ENUMFREQUENCY.ALTERNATE_DAYS,
	},
];
export const DEFAULT_MAX_QUANTITY_AVAILABLE: number = 10000;

export enum STORAGE {
	SUB_PRODUCTS = 'sub_products',
	SUBSCRIBED_PRODUCTS = 'subscribed_products',
	SUBSCRIPTIONS = 'subscriptions',
	PRODUCT_ID_DATA = 'product_id_data_',
	USER_MOBILE = 'mobile',
}