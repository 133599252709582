import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManagerService } from 'src/app/services/misc/modal-manager.service';
import { NavigationService } from 'src/app/services/misc/navigation.service';

@Component({
  selector: 'app-celebration-section',
  templateUrl: './celebration-section.component.html',
  styleUrls: ['./celebration-section.component.scss'],
})
export class CelebrationSectionComponent implements OnInit {

  @Input() sectionData:any={};

  tileData:any={};

  constructor(private router:Router, private navigationService:NavigationService,
    private modalManagerService:ModalManagerService
  ) { }

  ngOnInit() { 
    console.log(this.sectionData);
    this.tileData = this.sectionData?.tileData;
    console.log(this.tileData)
    // this.sectionData = {
    //   title: 'Celebrate Teej',
    //   subtitle: 'Lowest prices!',
    //   titleTextColor: '#ffffff', // Text color for title
    //   subtitleTextColor: '#ffffff', // Text color for subtitle
    //   background: {
    //     type: 'gradient', // or 'solid'
    //     color1: '#FF5733',
    //     color2: '#FFC300'
    //   },
    //   tiles: [
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png',
    //       text: 'Special Offer', // Optional text
    //       textColor: '#ffffff', // Text color on image
    //       textBackgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color of text
    //       backgroundColor: 'rgba(0,0,0,0)',
    //       navigationLink: '/page1'
    //     },
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png',
    //       // No text provided for this tile
    //       backgroundColor: 'rgba(0,0,0,0)',
    //       navigationLink: '/page2'
    //     },
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png',
    //       text: 'Limited Time', // Optional text
    //       textColor: '#ffffff', // Text color on image
    //       textBackgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color of text
    //       backgroundColor: 'rgba(0,0,0,0)',
    //       navigationLink: '/page3'
    //     },
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png',
    //       // No text provided for this tile
    //       backgroundColor: 'rgba(0,0,0,0)',
    //       navigationLink: '/page4'
    //     },
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png',
    //       text: 'Sale!', // Optional text
    //       textColor: '#ffffff', // Text color on image
    //       textBackgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color of text
    //       backgroundColor: 'rgba(0,0,0,0)',
    //       navigationLink: '/page5'
    //     },
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png',
    //       // No text provided for this tile
    //       backgroundColor: 'rgba(0,0,0,0)',
    //       navigationLink: '/page6'
    //     }
    //   ]
    // };

    // this.sectionData = {
    //   title: 'Celebrate Teej', // Required title
    //   subtitle: 'Lowest prices!', // Required subtitle
    //   titleTextColor: '#ffffff', // Text color for the title
    //   subtitleTextColor: '#ffffff', // Text color for the subtitle
    //   background: {
    //     type: 'gradient', // Type of background (can be 'solid' or 'gradient')
    //     color1: '#FF5733', // First background color
    //     color2: '#FFC300'  // Second background color (for gradient)
    //   },
    //   tiles: [
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png', // Image for the tile
    //       navigationLink: '/page1' // Navigation link for the tile
    //     },
    //     {
    //       imageUrl: '../../../assets/img/daykart/store.png', // Another tile image
    //       navigationLink: '/page2' // Another tile navigation link
    //     },
    //   ]
    // };
    
  }
  

  // Function to generate the background style based on the type
  getBackgroundStyle(background:any) {
    if (background.type === 'gradient') {
      return `linear-gradient(${background.color1}, ${background.color2})`;
    }
    return background.color1; // solid color
  }

  // Function to handle tile click
  handleTileClick(routeType: 'productList' | 'category' | 'product', navigationData: any) {
    console.log('title clicked')
    // Handle navigation or action here
    const sectionId = this.sectionData?._id;
    const routes = {
      productList: () => this.router.navigate(['/product-list/tiles'], {
        queryParams: { bannerId: navigationData?._id, sectionId: sectionId }
      }),
      category: () => this.router.navigate(['/category-list', navigationData?.slug, navigationData?.rootCategoryId], {
        queryParams: { activeSubcategoryId: navigationData?.subCategoryId }
      }),
      product: () => this.modalManagerService.openProductDetailModal(navigationData?.productId)
    };
  
    if (routes[routeType]) {
      routes[routeType]();  // Execute the corresponding route action
    } else {
      console.warn('Unknown route type:', routeType);
    }
   
  }


}
