<app-modal-header [title]="'Need help with your order? '"></app-modal-header>
<ion-content>
  <ion-row class="help-section">
    <ion-col size="12">
      <ion-button
        class="common-btn"
        expand="block"
        (click)="contactStoreSupport()"
      >
        <div class="text-left">
          <ion-icon name="call-outline"></ion-icon>
          <span class="ion-margin-start">{{ "order_on_call" | translate }}</span>
        </div>
      </ion-button>
    </ion-col>
    <ion-col size="12">
      <ion-button
        expand="block"
        class="common-btn"
        (click)="openWhatsAppChat()"
      >
        <div class="text-left">
          <ion-icon name="logo-whatsapp"></ion-icon>
          <span class="ion-margin-start">{{ "order_on_whatsapp" | translate }} </span>
        </div>
      </ion-button>
    </ion-col>

    <ion-col size="12">
      <ion-button
        expand="block"
        class="common-btn"
        (click)="openBuyatstoreModal()"
      >
        <div class="text-left">
          <ion-icon name="storefront-outline"></ion-icon>
          <span class="ion-margin-start">{{ "buy_at_store" | translate }}</span>
        </div>
      </ion-button>
    </ion-col>

    <ion-col size="12">
      <div>
        <h6>{{ "note" | translate }}</h6>
      </div>
      <small>{{ "exclusive_description" | translate }}</small>
    </ion-col>
  </ion-row>
</ion-content>
