import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewDidLeave } from '@ionic/angular';
import { interval, takeWhile, takeUntil, Subject } from 'rxjs';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-status-track',
  templateUrl: './order-status-track.component.html',
  styleUrls: ['./order-status-track.component.scss'],
})
export class OrderStatusTrackComponent implements OnInit, ViewDidLeave, OnDestroy {

  @Input() orderId: any;

  activeOrderData: any;
  stopTracking = false;
  private destroy$ = new Subject<void>(); // Subject to unsubscribe from observables

  isOrderDeliveredOrCancelled:boolean = false;

  constructor(private orderService: OrderService, private router: Router,
    private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {
    this.loadOrderStatus();
    this.mixpanelService.track("s_home", {"screenName" : "Homepage"})
  }

  loadOrderStatus() {
    this.orderService.orderTrackingForPopup(this.orderId).subscribe((res: any) => {
      if (res?.success) {
        this.activeOrderData = res?.data;
        if(res?.data?.orderStatus === 'delivered'){
          this.isOrderDeliveredOrCancelled = true;
        }
        else if(res?.data?.orderStatus === 'cancelled'){
          this.activeOrderData.deliveryStatusDescription = "Order Cancelled";
        }
        else{
          this.startOrderTracking();
        }
      }
      else {

      }
    })
  }

  startOrderTracking() {
    this.stopOrderTracking(); // Stop any existing tracking interval

    interval(20000)
      .pipe(
        // Stop the interval when stopTracking is true
        takeWhile(() => !this.stopTracking),
        // Unsubscribe from the interval when component is destroyed
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.fetchAndUpdateEstimatedTime()
      });
  }

  fetchAndUpdateEstimatedTime() {
    this.orderService.orderTrackingForPopup(this.orderId).subscribe((res: any) => {
      if (res?.success) {
        if (res?.data?.disableTracking === true) {
          this.stopTracking = true;
          this.destroy$.next(); // Emit signal to complete the observable
          if(res?.data?.orderStatus === 'delivered'){                        
            this.isOrderDeliveredOrCancelled = true;
            this.activeOrderData = res?.data;
          }
          else if(res?.data?.orderStatus === 'cancelled'){
            this.isOrderDeliveredOrCancelled = true;
            this.activeOrderData = res?.data;
            // this.activeOrderData.deliveryStatusDescription = "Order Cancelled";
          }
        }
        else {
          this.activeOrderData = res?.data;
        }
      }
      else {

      }
    })
  }

  goToOrderTracking() {
    console.log(this.activeOrderData)
    this.mixpanelService.track("b_track_order", { "screenName" : "Homepage", "orderID": this.activeOrderData?.orderID});

    this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: this.orderId , backButtonRoute: '/'} })
  }

  goToOrderDetails() {
    this.mixpanelService.track("b_order_info", { "screenName" : "Homepage" , "orderID": this.activeOrderData?.orderID});

    this.orderService.updateActiveOrders();
    this.router.navigate([`account/order-list/order-details`], { queryParams: { orderId: this.orderId , backButtonRoute: '/' } })
  }

  stopOrderTracking() {
    this.destroy$.next(); // Trigger the destruction of the current interval
  }

  ionViewDidLeave() {
    // console.log("view did leave called")
    // this.destroy$.next();
    // this.destroy$.complete();
  }

  ngOnDestroy() {
    // console.log("destroy called")
    // this.destroy$.next();
    // this.destroy$.complete();
  }

}
