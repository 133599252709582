import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, ViewWillEnter, ViewWillLeave {

  orderListData: any = [];
  dataFound: number = 0;

  page: number = 1;
  limit: number = 10;
  totalPages: number = 0;
  loadingText: string = 'loading more orders...'
  @ViewChild(IonInfiniteScroll) infiniteScroll!: IonInfiniteScroll;

  constructor(private orderService: OrderService,
    private router: Router,
    private toastService: ToastService,
    private productService: ProductService
  ) { }

  ionViewWillEnter() {
    this.orderList();
  }

  ngOnInit() { }

  orderList() {
    this.orderService.orderList(this.page, this.limit).subscribe((res: any) => {
      if (res?.success) {
        this.orderListData = res?.orders;
        this.dataFound = 1;
        this.totalPages = res?.pages;
      }
      else {
        this.dataFound = -1;
      }
    })
  }

  loadMoreOrders(event: any) {
    console.log("load more called")
    if (this.page < this.totalPages) {
      this.page = this.page + 1;
      this.orderService.orderList(this.page, this.limit).subscribe(
        (newData: any) => {
          this.orderListData = this.orderListData.concat(newData.orders);
          event.target.complete();
        },
        (error) => {
          console.error('Error loading data:', error);
          event.target.complete();
        }
      );
    }
    else if (this.page === this.totalPages) {
      this.loadingText = 'No more content...'
      setTimeout(() => {
        event.target.disabled = true;
      }, 600);
    }
  }


  navigateTo(id: any, routeTo: any) {
    this.router.navigate([routeTo], { queryParams: { orderId: id } })
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  resetPagination() {
    this.page = 1;
    this.limit = 10;
    this.totalPages = 0;
  }

  ionViewWillLeave() {
    this.resetPagination();
  }

}
