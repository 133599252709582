<!-- ----header--section------ -->
  <app-title-header [title]="'profile'" [backButtonRoute]="'/'" [parentScreenName]="'Profile section'"
  ></app-title-header>
  <!-- ------------end------------>
  <!-- --------account-----page--- -->
  <ion-content [fullscreen]="true" class="ion-padding-horizontal">
    <ion-grid>
      <ion-row class="account-page">
        <ion-col size="12">
          <h4><b>{{'myAccount' | translate}}</b></h4>
        </ion-col>
        <ion-col size="12">
          <h5 class="text-gray">{{userMobileNumber}}</h5>
        </ion-col>
      </ion-row>
  
      <ion-row>
        <ion-col size="12">
          <h5 class="text-gray">Your Information</h5>
        </ion-col>
      </ion-row>
  
      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" [routerLink]="['/account/order-list']">
        <ion-col size="1">
          <ion-icon name="bag-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10" >
         <h5>{{'your_orders' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
  
      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" (click)="navigateTo('/cart')">
        <ion-col size="1">
          <ion-icon name="cart-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10" >
         <h5>{{'myCart' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
  
      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" (click)="navigateTo('account/wallet')">
        <ion-col size="1">
          <ion-icon name="wallet-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10" >
         <h5>{{'myWallet' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>

		<ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" (click)="navigateTo('/subscriptions')">
        <ion-col size="1">
          <ion-icon name="ribbon-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10">
         <h5>{{ 'mySubscriptions' | translate }}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
  
      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" [routerLink]="['/account/address-book']">
        <ion-col size="1">
          <ion-icon name="map-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10">
        <h5>{{'address_book' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>      
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
  
      <!-- <ion-row class="product-summary1">
        <ion-col size="1">
         <ion-icon name="wallet-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10">
         <h5>{{'wallet' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>
        </ion-col>
      </ion-row> -->
  
      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" (click)="changeLanguage()">
        <ion-col size="1">
          <ion-icon name="globe" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10">
        <h5>{{'language' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>      
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
  
      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" [routerLink]="['/account/about-us']">
        <ion-col size="1">
          <ion-icon name="information-circle" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10">
         <h5>{{'aboutUs' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>

      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" [routerLink]="['/account/account-setting']">
        <ion-col size="1">
          <ion-icon name="settings-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10">
         <h5>{{'Account Setting' | translate}}</h5>
        </ion-col>
        <ion-col size="1" class="right-arrow">
          <ion-icon class="right-errow" name="chevron-forward-outline"></ion-icon>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
  
      <ion-row class="product-summary1 ion-activatable ripple-parent rounded-rectangle" (click)="logout()">
        <ion-col size="1">
          <ion-icon name="power-outline" class="product-icon"></ion-icon>
        </ion-col>
        <ion-col size="10">
         <h5>{{'logout' | translate}}</h5>
        </ion-col>
        <ion-col size="1">
          <!-- <ion-icon name="chevron-forward-outline"></ion-icon> -->
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
  
      <ion-row>
        <ion-col size="12">
          <h6 class="text-gray ion-text-center">Version {{appVersion}}</h6>
          <p class="text-gray ion-text-center" *ngIf="!environmentType">Development Build</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
  <!-- -----------------end--------------------- -->