import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { StoreHelplineHoursComponent } from 'src/app/extra/modals/store-helpline-hours/store-helpline-hours.component';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { HomeService } from 'src/app/services/home.service';
import { ProductService } from 'src/app/services/product.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss'],
})
export class CategoryModalComponent implements OnInit, ViewWillEnter {
  categories: any[] = [];
  categoryList: any;
  customerSupportData: any;

  constructor(private homeService: HomeService,
     private productService: ProductService, 
     private storeService: StoreService,
     public modalController: ModalController,
     private router: Router,
     private mixpanelService: MixedPanelsService
     ) { }


  ionViewWillEnter() {
    // this.getSections();
  }

  ngOnInit() {
    // this.getSections()
  }

  callHelpline() {
    const mobileNo = this.storeService.StoreContactNo
    window.open('tel:' + mobileNo, '_system');
  }

  getSections() {
    this.homeService.section('category').subscribe((res: any) => {
      this.categoryList = res?.data;
    })
  }

  navigateToSelectedCategory(slug:string,id:string){
    this.closeModal();
    this.router.navigate(['/category-list',slug,id])
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  async openHelplineHoursModal(modalData: any) {
    let modal = await this.modalController.create({
      component: StoreHelplineHoursComponent,
      cssClass: 'helpline-hours-modal',
      backdropDismiss: true,
      componentProps: {
        modalData: this.customerSupportData
      },
    })

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (data.status) {
      }
    }
  }


  contactStoreSupport() {
    this.storeService.storeHelplineHours().subscribe((res: any) => {
      if (res?.success) {
        this.mixpanelService.track("Call Support Clicked", {});
        this.callHelpline();
      }
      else {
        this.customerSupportData = res?.customerSupportHours
        this.openHelplineHoursModal(res?.modalData)
      }
    })
  }

  openWhatsAppChat() {
    this.mixpanelService.track("WhatsApp Clicked", {});

    const countryCode = '+91'; 
    const phoneNumber = `${countryCode}${this.storeService.StoreContactNo}`;
    const message = "hi";

    const encodedMessage = encodeURIComponent(message.replace(/\+/g, '%2B').replace(/ /g, '%20'));
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(url, '_blank');

  }

  closeModal() {
    this.modalController.dismiss();
  }

}
