import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastService } from './toast.service';

// import { NativeGeocoderOptions, NativeGeocoderResult } from '@awesome-cordova-plugins/native-geocoder';
import { Geolocation, GeolocationOptions } from '@awesome-cordova-plugins/geolocation/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@awesome-cordova-plugins/native-geocoder/ngx';

import { environment } from 'src/environments/environment';
import { Location } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  coordinate = new BehaviorSubject<Location | null>(null);
  userAddress = new BehaviorSubject<string>("");
  permissionGranted: Boolean = false;
  storeDistance: string = "1";

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private toastService: ToastService,
    private geolocation: Geolocation,
    private diagnostic: Diagnostic,
    private nativeGeocoder: NativeGeocoder,
    private locationAccuracy: LocationAccuracy
  ) {
    const savedLocation = localStorage.getItem('location');
    // this.coordinate = new BehaviorSubject(savedLocation ? JSON.parse(savedLocation) : null);
    this.coordinate.next(savedLocation ? JSON.parse(savedLocation) : null);
  }

  get isLocationAvailable() {
    const coordinate = this.coordinate.value;
    return coordinate ? true : false;
  }

  get location() {
    const coordinate = this.coordinate.value
    return coordinate;
  }

  get address() {
    return this.userAddress.value;
  }

  get addressPlace() {
    const parts = this.address.split(',');
    // Trim the spaces from the second value
    return parts.length > 1 ? parts[1].trim() : "";
  }

  pointLocationAddress(location: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/address/location`, location)
  }

  async requestPermissions() {
    try {
      // const isMobileApp = this.platform.is('cordova');
      const isCordova = localStorage.getItem('isCordova');
      if (isCordova === 'native') {
        console.log("service called: is cordova native found")
        const isHighAccuracyRequested = await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);

        if (isHighAccuracyRequested) {
          console.log("service called: asking for high accuracy", isHighAccuracyRequested)

          this.getCurrentCoordinate();
        } else {
          console.log("service called: high accuracy not found, asking permission again", isHighAccuracyRequested)
          await this.askPermission();
        }
      }
      else {
        // when user uses browser
        console.log("service called: isCordova browser found")

        if ('geolocation' in navigator) {
          // alert('Geolocation is Available');
          this.handlePermission()
        } else {
          console.warn('Geolocation is NOT Available');
        }
      }
    } catch (error) {
    }
  }

  async browserLocation() {
    if ("geolocation" in navigator) {
            // Define options for geolocation
      const options = {
        enableHighAccuracy: true,  // Enable high accuracy
        timeout: 5000,  // Maximum time (in milliseconds) to wait for location acquisition
        maximumAge: 0  // Maximum age (in milliseconds) of a possible cached location that is acceptable to return
      };
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          const coordinate = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          };
          this.setLocation(coordinate);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.error("The request to get user location timed out.");
              break;
          }
        },
        options
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  handlePermission() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        console.log('Permission granted');
        this.browserLocation();
      } else if (result.state === 'prompt') {
        console.log('Permission prompt');
        // The browser will prompt the user for permission
        this.browserLocation();
      } else if (result.state === 'denied') {
        console.log('Permission denied');
        // Handle the case where permission is denied
        // this.handlePermission();
        this.browserLocation()
      }
  
      result.addEventListener('change', () => {
        console.log('Permission state changed to', result.state);
      });
    });
  }

  async getCurrentCoordinate() {
    try {
      // Define options for geolocation
      const options: GeolocationOptions = {
        enableHighAccuracy: true,  // Enable high accuracy
        timeout: 5000,  // Maximum time (in milliseconds) to wait for location acquisition
        maximumAge: 0  // Maximum age (in milliseconds) of a possible cached location that is acceptable to return
      };
      const data = await this.geolocation.getCurrentPosition(options);

      const coordinate = {
        latitude: data.coords.latitude,
        longitude: data.coords.longitude,
        accuracy: data.coords.accuracy,
      };
      this.setLocation(coordinate);
    } catch (error) {
      console.error('Error getting current position:', error);
    }
  }

  askPermission() {
    this.diagnostic.requestLocationAuthorization().then((res) => {
      this.getCurrentCoordinate();
    },
      (error) => {
        alert('No Permission allowed..');
      })
  }

  setLocation(coordinate: Location = { latitude: 0, longitude: 0, accuracy: 0 }) {
    const location = JSON.stringify(coordinate);
    localStorage.setItem('location', location);
    this.coordinate.next(coordinate);
  }

  findAddress(lat: number, lng: number): Observable<any> {
    const apiKey = environment.apiKey; // Replace with your actual API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    return this.http.get(url);
  }

  getAddress() {
    return localStorage.getItem('address');
  }

  getUserLocation() {
    const location: any = localStorage.getItem('location');
    return JSON.parse(location)
  }

  // New Location

  promptPermission() {
    this.diagnostic.requestLocationAuthorization().then((res) => {
      alert(res)
      this.setNewcoordinate();
    },
      (error) => {
        alert('No Permission allowed..')
      })
  }

  async setNewcoordinate() {
    try {
      const data = await this.geolocation.getCurrentPosition();

      const coordinate = {
        latitude: data.coords.latitude,
        longitude: data.coords.longitude,
        accuracy: data.coords.accuracy,
      };
      this.setLocation(coordinate);
      return true;
    } catch (error) {
      console.error('Error getting current position:', error);
      return false;
    }
  }

  searchPlace(payload: any) {
    return this.http.post(`${environment.apiUrl}/address/placeprediction`, payload);
  }

  placeDetails(payload: any) {
    return this.http.post(`${environment.apiUrl}/address/placedetail`, payload);
  }

}

