import {inject}                                                             from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService}                                                        from '../../services/auth.service';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const isLoggedIn = inject(AuthService).checkIfUserLoggedIn();
  const router = inject(Router);
  if (!isLoggedIn) {
    router.navigate(['/login']).then(r => {
    });
  }

  return isLoggedIn;
};
