<app-modal-header [title]="'Sort by'"></app-modal-header>
<ion-content class="ion-padding">
  <ion-radio-group [(ngModel)]="selectedOption" (ionChange)="applySort($event)">
    <div *ngFor="let option of sortOptions">
      <ion-radio
        class="ion-margin-bottom"
        labelPlacement="end"
        value="{{ option.value }}"
        >{{ option.label }}</ion-radio
      >
    </div>
  </ion-radio-group>
</ion-content>
