<app-title-header
  [backButtonRoute]="'/cart'"
  [title]="'payment'"
  [parentScreenName]="'Payment'"
></app-title-header>
<!-- --------------end-------------------- -->
<!-- ------------payment------------ -->
<ion-content class="ion-padding-horizontal">
  <div class="order-total">
    <ion-row>
      <ion-col size="6">
        <h4> {{ "order_total" | translate }}</h4>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <h4><b>&#8377; {{ orderTotal }}</b></h4>
        <!-- <h4><b>&#8377; {{ billData?.grandTotal }}</b></h4> -->
      </ion-col>
    </ion-row>
  </div>

  <ion-row>
    <!-- <ion-col size="12">
      <ion-list>
        <ion-radio-group value="payment-options">
          <ion-label>Cash</ion-label>
          <ion-item lines="none">
            <ion-radio value="cash" justify="space-between">
              Pay Online Payment</ion-radio>
          </ion-item>
          <ion-label>Online</ion-label>
          <ion-item lines="none">
            <ion-radio value="online" justify="space-between">
              Cash on Delivery</ion-radio>
          </ion-item>
          <ion-label>Wallet</ion-label>
          <ion-item lines="none">
            <ion-radio value="wallet" justify="space-between">
              Pay on Wallet</ion-radio>
          </ion-item>

        </ion-radio-group>
      </ion-list>
    </ion-col> -->

    <ion-col size="12">
      <ion-list>
        <ion-radio-group [(ngModel)]="selectedOption" (ionChange)="onOptionChange($event.detail.value)">
          <ng-container *ngFor="let option of paymentOptions">
            <!-- <ion-label>{{option?.label}}</ion-label> -->
            <ion-item lines="none">
              <ion-radio [value]="option?.value" justify="space-between">
                {{option?.description}}</ion-radio>
            </ion-item>
            
          </ng-container>
        </ion-radio-group>
      </ion-list>
    </ion-col>
  </ion-row>

  <!-- <ion-row>
    <ion-col size="12">
      <h3>{{ "payment_methods" | translate }}</h3>
    </ion-col>
  </ion-row> -->

  <!-- <ion-row *ngFor="let options of paymentOptions; let i = index" class="payment-options"
    (click)="changePaymentOption(i)">
    <ion-col size="2">
      <ion-img src="{{ options.icon }}" />
    </ion-col>
    <ion-col size="8">
      <h4>
        {{ options?.name }}
      </h4>
    </ion-col>
    <ion-col class="check-icon" size="2" class="ion-text-center">
      <p>
        <ion-icon color="success" [name]="options?.isChecked ? 'checkbox' : 'square-outline'" size="small"></ion-icon>
      </p>
    </ion-col>
  </ion-row> -->
</ion-content>
<!-- ----------footer-------------- -->
<ion-footer>
  <ion-toolbar class="custom-toolbar">
    <ion-row class="bottom-btn">
      <ion-col size="12">
        <ion-button (click)="orderNow()" [disabled]="!selectedAddress" expand="block" class="ion-margin-bottom common-btn">{{ "confirm_order" |
          translate }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>

</ion-footer>
<!-- --------------end-------------- -->

<!-- ----------new-design---------- -->
<!-- <ion-content class="ion-padding">
  <ion-row class="order-amount">
    <ion-col size="8">
      <h4>Order Total</h4>
    </ion-col>
    <ion-col size="4" class="ion-text-end">
      <h4><b>&#8377; {{ billData?.grandTotal }}</b></h4>
    </ion-col>
  </ion-row>
</ion-content> -->