import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonicSlides, ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';

import { Items, Data, CategoryObject, Location } from 'src/app/interface';
import { Swiper } from 'swiper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { HomeService } from 'src/app/services/home.service';
import { LocationService } from 'src/app/services/location.service';
import { StoreService } from 'src/app/services/store.service';
import { ToastService } from 'src/app/services/toast.service';

import { StoreNotFoundComponent } from './store-not-found/store-not-found.component';
import { AuthService } from 'src/app/services/auth.service';

import { config } from 'src/app/config';
import { ProductService } from 'src/app/services/product.service';
import { ProductDetailComponent } from 'src/app/layout/product-detail/product-detail.component';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { OrderService } from 'src/app/services/order.service';
import { UtilityService } from 'src/app/services/utility.service';
import { OrderStatusTrackComponent } from 'src/app/layout/order-status-track/order-status-track.component';
import { NavigationService } from 'src/app/services/misc/navigation.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, ViewWillEnter, ViewWillLeave {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  sections: Data[] = [];
  sponsoredProducts: any[] = []
  addProduct: any;
  banners: any[] = [];
  cartCount: number = 0;
  currentUrl: any;

  isLoaded: boolean = false;

  isOrderActive: boolean = false;
  trackingOrderId: any;

  @ViewChild(OrderStatusTrackComponent) orderTrackComponent!: OrderStatusTrackComponent;

  page: number = 1;
  initialLimit: number = 10;
  paginationLimit: number = 10;
  totalPages: number = 0;
  loadingText: string = 'loading products...'
  readonly SUBSCRIPTION_CATEGORY_ID: string = '665d6be9b38d749ee6246742';
  constructor(
    public authService: AuthService,
    private homeService: HomeService,
    private productService: ProductService,
    private storeService: StoreService,
    private router: Router,
    private locationService: LocationService,
    private toastService: ToastService,
    private modalController: ModalController,
    private cd: ChangeDetectorRef,
    private mixpanelService: MixedPanelsService,
    private utilityService: UtilityService,
    private orderService: OrderService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    console.log("home page onInit called")

    this.isLoaded = false;
    this.resetPagination();
    this.mixpanelService.track('Page Viewed', { page: 'Home' });

    const isCordova = localStorage.getItem('isCordova');
    console.log("value of isCordova found:", isCordova)

    if (isCordova === 'cordovaBrowser' || isCordova === 'other') {
      this.locationService.coordinate.subscribe((location: any) => {
        if (location) {
          const storeId = localStorage.getItem('storeId');
          if (storeId) {
            this.loadHomePage();
          }
          else {
            this.findCurrentAddress(location);
          }
        }
        else {
          this.locationService.requestPermissions();
        }
      });
    }
    else {
      this.locationService.coordinate.subscribe((location: any) => {
        console.log("location value after subscribe to subject:", location)
        if (location) {
          console.log("location found in native")
          const storeId = localStorage.getItem('storeId');
          if (storeId) {
            console.log("store found so load home page")
            this.loadHomePage();
          }
          else {
            console.log("storeId not found so find address")

            this.findCurrentAddress(location);
          }
        }
        else {
          console.log("location not found so ask for permission")

          // this.locationService.requestPermissions();
        }
      });
    }

    if (this.utilityService.isUserLoggedIn()) {
      this.checkForActiveOrders();
    }

    this.currentUrl = this.router.url;
  }

  checkMe() {
    // const payload = {
    //   action: "navigation",
    //   bannerId: "6683c3acff9fdffdd8d0cd66",
    //   sectionId:"6683c3acff9fdffdd8d0cd68",
    //   activity: "bannerProducts"
    // }
    const payload = {
      action: "navigation",
      orderId:"66e19d33cc09952db9f6a989",
      activity: "order"
    }
    this.navigationService.handleNavigation(payload)
  }

  ionViewWillEnter(): void {
    console.log("home page view will enter called")
    if (this.orderTrackComponent && this.isOrderActive) {
      this.orderTrackComponent.startOrderTracking();
    }
    // this.isLoaded = false;
    // this.resetPagination();
    // const isCordova = localStorage.getItem('isCordova');
    // if (isCordova === 'cordovaBrowser' || isCordova === 'other') {
    //   this.locationService.coordinate.subscribe((location: any) => {
    //     if (location) {
    //       const storeId = localStorage.getItem('storeId');
    //       if (storeId) {
    //         this.loadHomePage();
    //       }
    //       else {
    //         this.findCurrentAddress(location);
    //       }
    //     }
    //     else {
    //       this.locationService.requestPermissions();
    //     }
    //   });
    // }
    // else {
    //   this.locationService.coordinate.subscribe((location: any) => {
    //     if (location) {
    //       const storeId = localStorage.getItem('storeId');
    //       if (storeId) {
    //         this.loadHomePage();
    //       }
    //       else {
    //         this.findCurrentAddress(location);
    //       }
    //     }
    //     else {
    //       this.locationService.requestPermissions();
    //     }
    //   });
    // }

    // this.currentUrl = this.router.url;
  }

  ionViewWillLeave() {
    // this.resetPagination();
    console.log("home page view will leave called")
    if (this.orderTrackComponent && this.isOrderActive) {
      this.orderTrackComponent.stopOrderTracking();
    }
  }

  checkForActiveOrders() {
    this.orderService.activeOrderInfo$.subscribe(orderData => {
      console.log('order', orderData)
      if (orderData && orderData.length > 0) {
        this.isOrderActive = true;
        this.trackingOrderId = orderData[0]?._id;
      }
      else {
        this.isOrderActive = false;
      }
    });
  }

  resetPagination() {
    this.page = 1;
    this.initialLimit = 10;
    this.paginationLimit = 10;
    this.totalPages = 0;
  }

  findCurrentAddress(location: any) {
    const point = { lat: location?.latitude, lng: location?.longitude };
    this.locationService.pointLocationAddress(point).subscribe(async (res: any) => {

      if (!res?.isStoreAvailable) {
        this.selectNewLocation(res?.isStoreAvailable, res?.address)
      }
      else {
        const isModal = await this.modalController.getTop();
        if (isModal) {
          isModal.dismiss('', 'close')
        }
        this.loadHomePage();
        localStorage.setItem('storeDistance', res?.distance);
        localStorage.setItem('storeId', res?.nearestStore?.storeId);
        localStorage.setItem('storeContactNo', res?.nearestStore?.mobile);
        localStorage.setItem('currentAddress', res?.address);
        localStorage.setItem('estimatedDeliveryTime', res?.time)
      }

    });
  }

  async selectNewLocation(isStoreAvailable: boolean, place: string) {
    const isModal = await this.modalController.getTop();

    if (!isModal) {
      const modal = await this.modalController.create({
        component: StoreNotFoundComponent,
        backdropDismiss: false,
        cssClass: 'storeNotFound',
        initialBreakpoint: .9,
        breakpoints: [0, .9],
        keyboardClose: false,
        componentProps: {
          sectionType: 'search',
          isStoreAvailable: 0,
          placeName: place
        },
        canDismiss: (data?: any, role?: string) => {
          return new Promise<boolean>(resolve => resolve(role === "close"))
        }
      });
      return await modal.present();
    }


  }



  getSections() {
    this.isLoaded = false;
    this.homeService.section('home', this.page, this.initialLimit).subscribe((res: CategoryObject) => {
      if (res.success) {
        this.sections = res.data;
        this.isLoaded = true;
        this.totalPages = res?.pages;
      }
      else {
        this.isLoaded = false;
        this.sections = [];
      }

    })
  }

  loadMoreProducts(event: any) {


    if (this.page < this.totalPages) {
      this.page = this.page + 1;

      const scrollPercentage = (this.page / this.totalPages) * 100;

      // Track pagination event with scroll percentage
      this.mixpanelService.track('Homepage Scrolled', {
        page: this.page,
        scrollPercentage: scrollPercentage
      });

      this.homeService.section('home', this.page, this.paginationLimit).subscribe(
        (newData: any) => {
          this.sections = this.sections.concat(newData.data);
          event.target.complete();
        },
        (error) => {
          console.error('Error loading data:', error);
          event.target.complete();
        }
      );
    }
    else if (this.page === this.totalPages) {
      this.loadingText = 'No more content...'
      setTimeout(() => {
        event.target.disabled = true;
      }, 2000);
    }
  }

  swiperReady() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  bannerProducts(id: any) {
    this.router.navigate(['search'], {
      queryParams: {
        type: 'banner',
        bannerId: id
      }
    })
  }

  async storeNotAvailable() {

    const modal = await this.modalController.create({
      component: StoreNotFoundComponent,
      backdropDismiss: false,
      cssClass: 'storeNotFound',
      initialBreakpoint: 0.85,
      breakpoints: [0, 0.85],
      keyboardClose: false,
      componentProps: {
        sectionType: 'search'
      },
      canDismiss: (data?: any, role?: string) => {
        return new Promise<boolean>(resolve => resolve(role === "close"))
      }
    });
    return await modal.present();


  }

  handleBannerRouting(routeType: string, navigationData: any, sectionId: string = '') {
    this.mixpanelService.track("Homepage Banner Clicked", {
      'banner_id': navigationData?._id
    });

    if (routeType === 'productList') {
      this.router.navigate(['/product-list/banner'], { queryParams: { bannerId: navigationData?._id, sectionId: sectionId } })
    }
    else if (routeType === 'category') {
      this.router.navigate(['/category-list', navigationData?.slug, navigationData?.rootCategoryId], {
        queryParams: {
          activeSubcategoryId: navigationData?.subCategoryId
        }
      })
    }
    else if (routeType === 'product') {
      this.showProductDetails(navigationData?.productId);
    }
  }

  async showProductDetails(productId: string) {
    let modal = await this.modalController.create({
      component: ProductDetailComponent,
      cssClass: 'product-detail',
      backdropDismiss: true,
      componentProps: {
        productId: productId
      },
    })

    modal.onDidDismiss()
      .then((data: any) => {
        if (data?.check) {
        }
      });

    return await modal.present();

  }

  /**
   * This method is used to go to the product list page but if sectionId is 665d6be9b38d749ee6246742 then
   * redirect to subscription page.
   * This method is changed by Gaurav Chandra
   * @param navigationData
   */
  navigateToProductOffers(navigationData: any) {
    this.mixpanelService.track("b_view_all", { "screenName": "Homepage", "sectionTitle": navigationData?.title });

    if (navigationData?.sectionType !== 'subscription') {
      this.router.navigate(['/product-list/banner'], { queryParams: { bannerId: '', sectionId: navigationData?._id } });
    } else {
      this.router.navigate(['/subscription']);
    }
  }

  loadHomePage() {
    // this.allCategoryList();
    // this.productList();
    this.getSections();
  }


  handleRefresh(event: any) {
    setTimeout(() => {
      // this.isLoaded = false;
      this.resetPagination();
      this.loadHomePage();
      event.target.complete();
    }, 1000);
  }
  // --------------media-----query------------------
  checkScreen() {
    if (window.innerWidth >= 960) {
      return 5.125;
    } else if (window.innerWidth >= 720) {
      return 4;
    }
    else {
      return 3;
    }
  }

  navigateToCategory(categoryData: any,) {
    // this.mixpanelService.track('Category Viewed', {
    //   'category_name': categoryData?.categoryName,
    //   'category_id': categoryData?._id
    // });

    this.mixpanelService.track("s_list_of_categories", { "screenName": "Homepage", "categoryName": categoryData?.categoryName });
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  swiperConfig = {
    autoplay: {
      delay: 1000
    }
  }
  swiperModules = [IonicSlides]

}
