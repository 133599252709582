<!-- ----header--section------ -->
<!-- <app-header [showBackButton]="true" [title]="categoryGroup" [showSearch]="false" [showCart]="true"
  [showNotification]="true" [backButtonRoute]="'/'"></app-header> -->
<!-- -------end---------- -->

<!-- ------header-----start----- -->
<ion-header [translucent]="true">
  <ion-toolbar class="custom-toolbar">
    <div class="header-title">
      <ion-grid>
        <ion-row>
          <ion-col
            size="1"
            class="ion-activatable ripple-parent rounded-rectangle"
          >
            <ion-icon
              name="arrow-back"
              class="back-arrow"
              (click)="navigateToComponent()"
            ></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-col>

          <ion-col size="10">
            <h5 class="location">
              {{ categoryGroup | titlecase }}
            </h5>
          </ion-col>
          <ion-col
            size="1"
            class="mid-center-content pointer ion-text-center ion-activatable ripple-parent rounded-rectangle"
          >
            <app-support-icon
              [parentScreenName]="'Category List'"
              [supportIconColor]="'#ffffff'"
            ></app-support-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <ion-grid>
      <ion-row
        class="search-field ion-activatable ripple-parent rounded-rectangle"
      >
        <ion-col size="1" class="ion-text-center">
          <ion-icon name="search-outline"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <ion-input
            type="text"
            placeholder="{{ 'search_placeholder_category' | translate }}"
            [(ngModel)]="searchInput"
            (ionInput)="searchInCategoryProducts()"
            [debounce]="800"
            clearInput
            readonly="true"
            (click)="navigateToSearch()"
          ></ion-input>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>
<!-- -----------end------------ -->

<!-- --------category-list------------ -->
<ion-content [fullscreen]="true" class="common-bg-color">
  <!-- -------------------------------------sub-categories------------------------------------------------------ -->
  <ion-row *ngIf="categoryLoaded === 2" class="sub-category-section">
    <ion-col size-xs="2" size-md="1" size-sm="1" class="left-section">
      <ion-row class="sort-filter-top">
        <ion-col
          size="12"
          *ngFor="let category of subCategories"
          (click)="changeSubCategory(category._id)"
        >
          <div
            class="side-category ion-activatable ripple-parent rounded-rectangle"
            [ngClass]="
              activeSubCategoryId === category?._id
                ? 'active-category'
                : 'inactive-category'
            "
          >
            <ion-img
              src="{{ category.categoryImage[0] }}"
              alt=""
              (ionError)="loadDefaultImg($event)"
            />
            <small class="overflow-control">{{
              category.categoryName | titlecase
            }}</small>
            <ion-ripple-effect></ion-ripple-effect>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>

    <!-- -----------------------------------------sub-categories products-------------------------------- -->
    <ion-col size="10" class="right-section">
      <div
        *ngIf="
          productLoaded === 2 ||
          (selectedFilterOption.length > 0 && productLoaded === 1)
        "
        class="filter-section"
      >
        
          <ion-button
            fill="outline"
            class="filter-sort-by"
            (click)="openSortModal()"
            [ngClass]="sortValue !== '' ? 'active-sort' : 'inactive-sort'"
          >
            <ion-icon
              class="sort-icon"
              *ngIf="sortValue === ''; else elseBlock"
              name="swap-vertical-outline"
            ></ion-icon>
            <ng-template #elseBlock>
              <i class="sort-icon" [ngClass]="getIconName()"></i>
            </ng-template>

            {{ sortValue === "" ? "Sort by" : (sortValue | titlecase) }}
          </ion-button>
          <ion-button
            fill="outline"
            class="filter-sort-by"
            (click)="openFilterModal()"
            [ngClass]="
              selectedFilterOption.length !== 0
                ? 'active-filter'
                : 'inactive-filter'
            "
            >Brand<span *ngIf="selectedFilterOption.length > 0">{{
              "(" + selectedFilterOption.length + ")"
            }}</span>
            <ion-icon name="chevron-down-outline" class="brand-icon"></ion-icon
          ></ion-button>
          </div>
        

      <ion-row class="relative-product">
        <ion-col
          size-xs="6"
          size-sm="3"
          size-lg="3"
          size-xl="2"
          class="product-box"
          *ngFor="let product of products"
        >
          <app-product-card
            [cardType]="'list'"
            (messageEvent)="refreshCategoryList($event)"
            [productData]="product"
            [parentScreenName]="'Category Page'"
          ></app-product-card>
        </ion-col>
      </ion-row>
      <!-- ---------no-data-found----------- -->
      <div
        class="mid-center"
        *ngIf="productLoaded === 1 && categoryLoaded === 2"
      >
        <app-product-not-found
          [searchedValue]="searchInput"
        ></app-product-not-found>
      </div>
      <!-- ---------------end-------------- -->
    </ion-col>
  </ion-row>

  <app-page-not-found
    buttonText="go back"
    *ngIf="categoryLoaded === 1"
  ></app-page-not-found>

  <ion-row>
    <ion-col size="2">
      <app-sub-category-skeleton
        *ngIf="categoryLoaded === 0"
      ></app-sub-category-skeleton>
    </ion-col>
    <ion-col size="10">
      <app-categorylist-skeleton
        *ngIf="productLoaded === 0"
      ></app-categorylist-skeleton>
    </ion-col>
  </ion-row>

  <ion-infinite-scroll (ionInfinite)="loadMoreProducts($event)">
    <ion-infinite-scroll-content
      *ngIf="totalPages > page"
      [loadingText]="loadingText"
      loadingSpinner="bubbles"
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<!-- -----------footer---------- -->
<div class="items-bottom">
  <app-footer></app-footer>
</div>
<!-- ------------end------------ -->
