import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTrim'
})
export class TextTrimPipe implements PipeTransform {

  transform(value: string, type: string=''): unknown {
    let newVal = '';
    const extraData = '...';

    if (value != null && value != undefined) {
      newVal = value;
      if (type === 'productName') {
        if (value.length > 20) {
          newVal = value.slice(0, 20).concat(extraData)
          return newVal;
        }
        else {
          return newVal;
        }

      }
      // if (type === 'email') {
      //   if (value.length > 20) {
      //     newVal = value.slice(0, 20).concat(extraData)
      //     return newVal;
      //   }
      //   else {
      //     return newVal;
      //   }
      // }
      // if (type === 'address') {
      //   if (value?.length > 40) {
      //     newVal = value.slice(0, 40).concat(extraData)
      //     return newVal;
      //   }
      //   else {
      //     return newVal;
      //   }
      // }
      return newVal;
    }
    else {
      return newVal;
    }



  }

}
