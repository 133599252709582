import {Injectable}  from '@angular/core';
import {environment} from '../../../environments/environment';

export interface ILoggerService {
	info(value: any, rest?: any): void;
	
	log(value: any, rest?: any): void;
	
	warn(value: any, rest?: any): void;
	
	error(value: any, rest?: any): void;
	
	table(obj: any): void;
}

@Injectable({
	            providedIn: 'root',
            })
export class LogService implements ILoggerService {
	readonly LOG_TAG: string = 'DAYKART_APP: ';
	
	constructor() {
	}
	
	info(value: any, rest?: any): void {
		if (!environment.production) {
			console.info(this.LOG_TAG + value, rest);
		}
	}
	
	log(value: any, rest?: any): void {
		if (!environment.production) {
			console.log('%c' + this.LOG_TAG + value, 'color:#01B4BB', rest);
		}
	}
	
	warn(value: any, rest?: any): void {
		if (!environment.production) {
			console.warn(this.LOG_TAG + value, rest);
		}
	}
	
	error(value: any, rest: any): void {
		if (!environment.production) {
			console.error(this.LOG_TAG + value, rest);
		}
	}
	
	table(obj: any): void {
		if (!environment.production) {
			console.table(obj);
		}
	}
}