import { Component, Input, OnInit } from '@angular/core';
import { StoreHelplineHoursComponent } from '../store-helpline-hours/store-helpline-hours.component';
import { StoreService } from 'src/app/services/store.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { UtilityService } from 'src/app/services/utility.service';
import { BuyAtStoreComponent } from '../buy-at-store/buy-at-store.component';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss'],
})
export class CustomerSupportComponent  implements OnInit {

  @Input() parentScreenName: any;
  customerSupportData: any;

  constructor(
     private storeService: StoreService,
     public modalController: ModalController,
     private router: Router,
     private mixpanelService: MixedPanelsService,
     private utilityService: UtilityService
     ) { }

  ngOnInit() {
  }

  callHelpline() {
    this.mixpanelService.track("b_phone_call", { "screenName" : this.parentScreenName});

    const mobileNo = this.storeService.StoreContactNo;
    this.utilityService.callToMobileNumber(mobileNo)
  }


  async openHelplineHoursModal(modalData: any) {
    let modal = await this.modalController.create({
      component: StoreHelplineHoursComponent,
      cssClass: 'helpline-hours-modal',
      backdropDismiss: true,
      componentProps: {
        modalData: this.customerSupportData
      },
    })

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (data.status) {
      }
    }
  }


  contactStoreSupport() {
    this.storeService.storeHelplineHours().subscribe((res: any) => {
      if (res?.success) {
        this.mixpanelService.track("Call Support Clicked", {});
        this.callHelpline();
      }
      else {
        this.customerSupportData = res?.customerSupportHours
        this.openHelplineHoursModal(res?.modalData)
      }
    })
  }

  openWhatsAppChat() {
    // this.mixpanelService.track("WhatsApp Clicked", {});
    this.mixpanelService.track("b_whatsapp", { "screenName" : this.parentScreenName});

    const storeMobileNumber = this.storeService.StoreContactNo;
    if(storeMobileNumber){
      this.utilityService.openWhatsAppChat(storeMobileNumber);
    }
  }

  async openBuyatstoreModal() {
    await this.utilityService.closePopups('modal');

    let modal = await this.modalController.create({
      component: BuyAtStoreComponent,
      cssClass: 'buyatStore-modal',
      backdropDismiss: true,
      componentProps: {
        parentScreenName: this.parentScreenName
      }
    })

    return await modal.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }


}
