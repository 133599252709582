import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingControllerService {

  private loading: HTMLIonLoadingElement | null = null;

  constructor(private loadingController: LoadingController) {}

  async showLoading(message: string, spinner: 'lines' | 'lines-small' | 'dots' | 'bubbles' | 'circles' | 'crescent' = 'crescent',duration?: number, backdropDismiss: boolean = false) {
    this.loading = await this.loadingController.create({
      message: message,
      duration: duration,
      spinner: spinner,
      backdropDismiss: backdropDismiss
    });

    await this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }
}
