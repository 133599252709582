<!-- ----------desktop---view--footer---------- -->
<ion-grid class="footer ion-margin-top">
  <ion-row>
    <ion-col size="12" size-md="4" class="contact-section">
      <h4>Contact Us</h4>
      <ion-list lines="none">
        <ion-item>
          <ion-label class="label-bold">Store Manager:</ion-label>
          <ion-label>Manish Bisht</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="label-bold">Phone No:</ion-label>
          <ion-label>8700965679</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="label-bold">Email Id:</ion-label>
          <ion-label>contactus@daykart.com</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="label-bold">GST No:</ion-label>
          <ion-label>09AAJCD9768N1ZK</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="label-bold">Address:</ion-label>
          <ion-label
            >Shop No 1,2,3,4,5,6 and 7, NH-24, Village Mehrauli, Pargana Dasna,
            Golf Links, Ghaziabad, Ghaziabad, Uttar Pradesh, 201002</ion-label
          >
        </ion-item>
      </ion-list>
    </ion-col>

    <ion-col size="12" size-md="4" class="store-section">
      <h4>Available Store</h4>
      <ion-list lines="none">
        <ion-item>
          <ion-icon name="location" slot="start"></ion-icon>
          <ion-label
            >Shop No. 36, Galleria Market, River Heights Rd, Sehani Khurd, Raj
            Nagar Extension, Ghaziabad, Uttar Pradesh 201017
            <h2 (click)="callStore('9311049438')">Ph.No. 9311049438</h2>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-icon name="location" slot="start"></ion-icon>
          <ion-label
            >Shop No 1,2,3,4,5,6 and 7, NH-24, Village Mehrauli, Pargana Dasna,
            Golf Links, Ghaziabad, Ghaziabad, Uttar Pradesh, 201002
            <h2 (click)="callStore('8700965679')">Ph.No. 8700965679</h2>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-col>

    <ion-col size="12" size-md="4" class="social-section">
      <h4>Social Media & Download links</h4>
      <ion-list lines="none">
        <ion-item class="download-item">
          <ion-label>Download App:</ion-label>
          <a [href]="returnStoreUrl('android')" target="_blank" rel="noopener noreferrer"><ion-img
            src="../../../../assets/img/daykart/play-store.png"
            alt="Play Store"
          ></ion-img>
        </a>
        <a [href]="returnStoreUrl('ios')" target="_blank" rel="noopener noreferrer">
          <ion-img
            src="../../../../assets/img/daykart/app-store.png"
            alt="App Store"
          ></ion-img>
        </a>
        </ion-item>
        <ion-item class="social-media-icons">
          <a href="https://www.facebook.com/Daykart"
            ><ion-icon name="logo-facebook"></ion-icon
          ></a>
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2Fdaykart"
            ><ion-icon name="logo-twitter"></ion-icon
          ></a>
          <a href="https://www.instagram.com/daykart/?utm_medium=copy_link"
            ><ion-icon name="logo-instagram"></ion-icon
          ></a>
          <a href="https://www.linkedin.com/in/daykart/"
            ><ion-icon name="logo-linkedin"></ion-icon
          ></a>
        </ion-item>
      </ion-list>

      <ion-list class="pages-links">
        <ion-item lines="none">
          <a routerLink="/privacy-policy"> Privacy Policy </a>
        </ion-item>
        <ion-item lines="none">
          <a routerLink="/terms-condition"> Terms & Condition </a>
        </ion-item>
        <ion-item lines="none">
          <a routerLink="/shipping-policy"> Shipping Policy </a>
        </ion-item>
        <ion-item lines="none">
          <a routerLink="/return-policy"> Return Policy </a>
        </ion-item>
        <ion-item lines="none">
          <a routerLink="/refund-policy"> Refund Policy </a>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="12" class="ion-text-center">
      <p class="copyright">© Daykart Retail Private Limited, 2024</p>
    </ion-col>
  </ion-row>
</ion-grid>
<!-- -----------------end---------------- -->
