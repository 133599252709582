<!-- -------------------header----------------- -->
<!-- <ion-header [translucent]="true">
  <div class="tracking-header ion-text-center">
    <ion-grid class="ion-padding-bottom">
      <ion-row>
        <ion-col size="2" routerLink="/account/order-list">
          <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
        </ion-col>
        <ion-col size="8">
          <small>{{ orderData?.store?.name }}</small>
          <p class="page-title">
            <span>{{
              orderData?.store?.storeLocation?.city +
                ", " +
                orderData?.store?.storeLocation?.state
            }}</span>
          </p>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="2"></ion-col>
        <ion-col size="8">
          <h2>{{orderData?.deliveryStatus}}</h2>
          <p class="ion-padding-bottom">{{orderData?.deliveryStatusDescription | titlecase}}</p>
          <a>Arriving in {{ orderData?.estimatedDeliveryTime | titlecase}}</a>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-header> -->

<app-title-header
  [title]="'Track Your Order'"
  [backButtonRoute]="backUrl"
  [parentScreenName]="'Order tracking'"
></app-title-header>
<!-- ----------------end-------------- -->

<!-- -----------order-tracking--------------- -->
<ion-content [fullscreen]="true" class="ion-padding-bottom">
  <!-- <div class="map-container">
    <div #map id="map"></div>
    <ion-button class="locate-button" size="small" (click)="centerMapOnPath()">
      <ion-icon name="locate"></ion-icon>
      Go to center
    </ion-button>
  </div> -->

  <div class="tracking-section">
    <ion-row class="ion-padding-bottom">
      <ion-col size="12">
        <div class="arriving-timing">
          <!-- Arriving in {{ orderData?.estimatedDeliveryTime | titlecase }} -->
          <p>{{ (orderData?.orderStatus === 'delivered' || orderData?.orderStatus === 'cancelled') 
          ? 'Order ' + (orderData?.orderStatus | titlecase) 
          : 'Arriving in ' + (orderData?.estimatedDeliveryTime | titlecase) }}
          </p>
          <small>{{orderData?.deliveryStatusDescription}}</small>
        </div>
       
      </ion-col>
    </ion-row>

    <!-- <ion-row
      class="tracking-details"
      size="2"
      (click)="callDriver(orderData?.driver?.mobile)"
      *ngIf="isPickedByDriver"
    >
      <ion-col size="1">
        <ion-icon name="call"></ion-icon>
      </ion-col>
      <ion-col size="11">
        <p>I'm {{ orderData?.driver?.name }}, your delivery partner</p>
      </ion-col>
    </ion-row> -->

    <!-- <ion-row class="tracking-details" *ngIf="!isPickedByDriver">
      <ion-col size="1" class="ion-text-center">
        <ion-icon name="bicycle-outline" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="11">
        <p>Delivery Driver Details</p>
        <small class="text-gray">
          We will provide driver contact details as soon as we assign someone to
          your order.
        </small>
      </ion-col>
    </ion-row> -->

    <ion-row class="tracking-details">
      <ion-col size="1" class="ion-text-center">
        <ion-icon name="location-outline" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="11">
        <p>Delivery Address</p>
        <small class="text-gray"
          >{{
            orderData?.shippingAddress?.completeAddress +
              " " +
              orderData?.shippingAddress?.googleAddress
          }}
          <p>
            {{ orderData?.shippingAddress?.receiverName | titlecase }},
            {{ orderData?.shippingAddress?.receiverPhoneNumber }}
          </p></small
        >
      </ion-col>
    </ion-row>

    <ion-row class="tracking-details">
      <ion-col size="1" class="ion-text-center">
        <ion-icon name="newspaper-outline"></ion-icon>
      </ion-col>
      <ion-col size="11">
        <p>Order Summary</p>
        <small class="text-gray"
          ><span>Order ID: {{ orderData?.orderID }}</span></small
        >
      </ion-col>
    </ion-row>

    <div class="multi-slider item-image ion-margin-top">
      <swiper-container [slidesPerView]="3">
        <swiper-slide *ngFor="let item of orderData?.products">
          <ion-img
            src="{{ item?.images[0] }}"
            (ionError)="loadDefaultImg($event)"
          />
        </swiper-slide>
      </swiper-container>
    </div>

    <ion-row>
      <ion-col size="12">
        <ion-button
          (click)="
            navigateTo(orderData?._id, 'account/order-list/order-details')
          "
          expand="block"
          class="custom-outline-btn"
          >View Details</ion-button
        >
      </ion-col>
    </ion-row>

    <!-- ---------bill-details------------- -->
    <div class="card-item-list bill-details">
      <ion-row>
        <ion-col size="12">
          <h4><b>Bill Details</b></h4>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <p>Subtotal</p>
        </ion-col>
        <ion-col size="6" class="ion-text-end">
          <p>
            <span>&#8377; {{ billingData?.totalOriginalPrice }}</span>
          </p>
        </ion-col>
      </ion-row>

      <ion-row class="discount-text">
        <ion-col size="6">
          <p>Product Discount</p>
        </ion-col>
        <ion-col size="6" class="ion-text-end">
          <p>
            <span>&#8377; {{ billingData?.savings }}</span>
          </p>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6">
          <p>Delivery Charge</p>
        </ion-col>
        <ion-col size="6" class="ion-text-end">
          <p>
            <span>{{
              billingData?.deliveryCharge === 0
                ? "FREE"
                : "&#8377;" + billingData?.deliveryCharge
            }}</span>
          </p>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6">
          <p>Total savings</p>
        </ion-col>
        <ion-col size="6" class="ion-text-end">
          <p>
            <span>&#8377; {{ billingData?.totalSavings }}</span>
          </p>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="orderData?.couponDiscount > 0">
        <ion-col size="6">
          <p>Coupon Discount</p>
        </ion-col>
        <ion-col size="6" class="ion-text-end">
          <p>-&#8377; {{ orderData?.couponDiscount }}</p>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="billingData?.walletAmountDeducted > 0">
        <ion-col size="6">
          <p>Wallet Balance</p>
        </ion-col>
        <ion-col size="6" class="ion-text-end">
          <p>-&#8377; {{ billingData?.walletAmountDeducted }}</p>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6">
          <h5>Bill Total</h5>
        </ion-col>
        <ion-col size="6" class="ion-text-end">
          <h5>
            <b
              ><span>&#8377; {{ billingData?.grandTotal }}</span></b
            >
          </h5>
        </ion-col>
      </ion-row>
    </div>
    <!-- --------------end----------------- -->

    <!-- ------------need--------help------- -->
    <ion-row
      class="contact-us"
      (click)="callHelpline(orderData?.store?.mobile)"
    >
      <ion-col size="2">
        <ion-icon name="call"></ion-icon>
      </ion-col>
      <ion-col size="10" class="ion-padding-start">
        <h4>Contact Us</h4>
        <p class="text-gray">{{ orderData?.store?.mobile }}</p>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
<!-- ---------------end-------------------- -->
