<!-- ------------track-order-------------- -->
<ion-row class="track-order">
  <ion-col size="7" *ngIf="!isOrderDeliveredOrCancelled">
    <p class="order-status">{{ activeOrderData?.deliveryStatusDescription | titlecase }}</p>
    <p class="text-gray order-time">Arriving in {{ activeOrderData?.estimatedDeliveryTime | titlecase }}</p>
  </ion-col>
  <ion-col size="7" class="order-delivered" *ngIf="isOrderDeliveredOrCancelled">
    <p class="order-status">{{ activeOrderData?.deliveryStatusDescription | titlecase }}</p>
  </ion-col>
  <ion-col size="5" class="ion-text-end" *ngIf="!isOrderDeliveredOrCancelled">
    <ion-button expand="block" class="common-btn" (click)="goToOrderTracking()">Track Order</ion-button>
  </ion-col>
  <ion-col size="5" class="ion-text-end" *ngIf="isOrderDeliveredOrCancelled">
    <ion-button expand="block" class="common-btn" (click)="goToOrderDetails()">Order Info</ion-button>
  </ion-col>
</ion-row>
<!-- ----------------end-------------------- -->
