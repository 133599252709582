import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private alertController: AlertController) {}

  async showAlert(header: string, message: string, duration?: number, showButton: boolean = true,autoClose: boolean = true) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();

    if (duration && autoClose) {
      setTimeout(() => {
        alert.dismiss();
      }, duration);
    }
  }
}
