import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CategoryObject } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  section(pageType: string = 'home', page: number = 1, limit: number = 10): Observable<CategoryObject> {
    if (pageType === 'home') {
      return this.http.get<CategoryObject>(`${this.apiUrl}/section?page=${pageType}&pageNo=${page}&limit=${limit}`);
    }
    else {
      return this.http.get<CategoryObject>(`${this.apiUrl}/section?page=${pageType}`);
    }
  }

  postData(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, data);
  }

  currentVersion(version: any) {
    return this.http.get(`${environment.apiUrl}/version`, version);
  }

  sectionProductList(pageTypeId:string,sectionId:string,page:number=1,limit:number=10,pagetype:string=''){
    if(pagetype === 'tiles'){
      return this.http.get(`${this.apiUrl}/section/products?tileId=${pageTypeId}&sectionId=${sectionId}&page=${page}&limit=${limit}`);
    }
    else{
      return this.http.get(`${this.apiUrl}/section/products?bannerId=${pageTypeId}&sectionId=${sectionId}&page=${page}&limit=${limit}`);
    }
  }
}
