import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  styleUrls: ['./app-update.component.scss'],
})
export class AppUpdateComponent  implements OnInit {

  @Input() playStoreLink!:string

  modalData: any = {
    modalHeader: '',
    modalBody: ''
  };

  constructor(private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit() {
    this.modalData.modalHeader = 'App Update Required!'
    this.modalData.modalBody = 'We have added new features and fixed some bugs to make your experience seamless'
  }

  updateFromPlaystore(){
    this.modalController.dismiss();
    window.location.href = this.playStoreLink;
    (navigator as any).app.exitApp();
  }

}
