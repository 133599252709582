import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sort-modal',
  templateUrl: './sort-modal.component.html',
  styleUrls: ['./sort-modal.component.scss'],
})
export class SortModalComponent implements OnInit {

  @Input() selectedOption: any

  sortOptions = [
    { label: 'Relevance [Default]', value: JSON.stringify({ sortValue: '', sortOrder: '' })  },
    { label: 'Name( A to Z )', value: JSON.stringify({ sortValue: 'name', sortOrder: 'asc' })  },
    { label: 'Name( Z to A )', value: JSON.stringify({ sortValue: 'name', sortOrder: 'desc' })  },
    { label: 'Price( Low to High )', value: JSON.stringify({ sortValue: 'price', sortOrder: 'asc' })  },
    { label: 'Price( High to Low )', value: JSON.stringify({ sortValue: 'price', sortOrder: 'desc' })  },
    { label: 'Discount', value: JSON.stringify({ sortValue: 'discount', sortOrder: 'desc' })  },
  ];


  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  applySort(event: any) {
    this.closeModal(true,this.selectedOption)
  }

  closeModal(status: boolean = false, data: any = '') {
    data = JSON.parse(this.selectedOption)
    this.modalController.dismiss({status, data});
  }

}
