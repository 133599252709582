import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss'],
})
export class CategoryCardComponent implements OnInit {

  @Input() categoryData: any;

  constructor(private router: Router, private productService: ProductService,
    private mixpanelService:MixedPanelsService
  ) { }

  ngOnInit() {
  }

  navigateToSubCategoryProducts(subCategoryData: any) {
    // console.log('navigate to subcategory',subCategoryData)
    this.mixpanelService.track('Category Viewed', {
      'subcategory_name': subCategoryData?.categoryName,
      'subcategory_id': subCategoryData?._id
    });   
    this.router.navigate(['/category-list', subCategoryData?.slug, subCategoryData?.parentCategoryId], { queryParams: { activeSubcategoryId: subCategoryData?._id } })
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }



}
