import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-skeleton',
  templateUrl: './home-skeleton.component.html',
  styleUrls: ['./home-skeleton.component.scss'],
})
export class HomeSkeletonComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
