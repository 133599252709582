import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CelebrationAnimationService } from 'src/app/services/misc/celebration-animation.service';

@Component({
  selector: 'app-offer-alert',
  templateUrl: './offer-alert.component.html',
  styleUrls: ['./offer-alert.component.scss'],
})
export class OfferAlertComponent implements OnInit, AfterViewInit {

  @Input() title: string = '';
  @Input() description: string = '';
  @Input() logoUrl: string | null = null;
  @Input() isAnimatedLogo: boolean = false;
  @Input() showButton: boolean = true;
  @Input() buttonText: string = 'Learn More';
  @Input() navigationType: 'page' | 'modal' = 'page';
  @Input() navigationData: any = null;

  constructor(private modalController: ModalController,
    private router: Router,
    private animationService: CelebrationAnimationService
  ) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    if (this.isAnimatedLogo) {
      this.animationService.triggerConfetti();
    }
  }



  onButtonClick() {
    if (this.navigationType === 'page') {
      this.router.navigate([this.navigationData.pageUrl], {
        queryParams: this.navigationData.queryParams,
      });
    } else if (this.navigationType === 'modal') {
      // Logic to open another modal if necessary
    }
    else if (this.navigationType === 'close') {
      // do nothing if type is close
    }

    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
