import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import insightsClient from 'search-insights';
import { environment } from 'src/environments/environment';
import { StoreService } from './store.service';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private searchClient: any;

  // private client: any;
  private activeDbIndex: any;
  private inactiveDbIndex: any;
  private suggestionsIndex: any;
  private categoriesIndex: any;



  constructor(private storeService: StoreService) {
    // this.searchClient = algoliasearch(
    //   '7W8C6PHWPX',
    //   'd320ddf47400ec240f42cd83b6fa20b9' 
    // );

    // this.searchClient = algoliasearch('7W8C6PHWPX', 'd320ddf47400ec240f42cd83b6fa20b9');
    // this.index = this.searchClient.initIndex('dev_daykart');
    // insightsClient('init', {
    //   appId: '7W8C6PHWPX',
    //   apiKey: 'd320ddf47400ec240f42cd83b6fa20b9'
    // });
    // this.suggestionsIndex = this.searchClient.initIndex('dev_daykart_query_suggestions');


    this.searchClient = algoliasearch(environment.algoliaSearch.applicationId, environment.algoliaSearch.apiKey);

    this.activeDbIndex = this.searchClient.initIndex(environment.algoliaSearch.dbIndex);
    this.inactiveDbIndex = this.searchClient.initIndex(environment.algoliaSearch.inactiveProductIndex);


    insightsClient('init', {
      appId: environment.algoliaSearch.applicationId,
      apiKey: environment.algoliaSearch.apiKey
    });
    this.suggestionsIndex = this.searchClient.initIndex(environment.algoliaSearch.suggestionIndex);

    this.categoriesIndex = this.searchClient.initIndex(environment.algoliaSearch.categorySuggestionIndex);

  }

  getIndex(indexName: string): any {
    return this.searchClient.initIndex(indexName);
  }

  // search(query: string, page: number = 0, limit: number = 12): Promise<any> {
  //   const index = this.getIndex('dev_daykart');
  //   return index.search(query, {
  //     page: page-1,
  //     hitsPerPage: limit,
  //     typoTolerance: 'min'
  //   }).then((res: any) => {
  //     return res;
  //   }).catch((error: any) => {
  //     console.error('Error during search:', error);
  //     throw error;
  //   });
  // }

  // search(query: string, page: number = 0, limit: number = 12): Promise<any> {

  //   return this.activeDbIndex.search(query, {
  //     page: page - 1,
  //     hitsPerPage: limit,
  //     typoTolerance: 'min'
  //   }).then((activeRes: any) => {
  //     if (activeRes.nbHits >= 20) {
  //       // If there are at least 20 results, return them
  //       return activeRes;
  //     } else {
  //       // Otherwise, search in the inactive products index
  //       return this.inactiveDbIndex.search(query, {
  //         // hitsPerPage: limit - activeRes.nbHits,
  //         hitsPerPage: 20,
  //         typoTolerance: 'min'
  //       }).then((inactiveRes: any) => {
  //         // Combine active and inactive results
  //         const combinedHits = [...activeRes.hits, ...inactiveRes.hits];
  //         return {
  //           hits: combinedHits,
  //           nbHits: combinedHits.length,
  //           page: page,
  //           nbPages: Math.ceil(combinedHits.length / limit),
  //           hitsPerPage: limit
  //         };
  //       });
  //     }
  //   }).catch((error: any) => {
  //     console.error('Error during search:', error);
  //     throw error;
  //   });
  // }

  searchActiveProducts(query: string, page: number = 0, limit: number = 12): Promise<any> {
    // const exactQuery = `"${query}"`;  --use this in function to search for exact word matching
    const storeId = this.storeService.getStoreId();
    return this.activeDbIndex.search(query, {
      page: page - 1,
      hitsPerPage: limit,
      typoTolerance: 'min',
      filters: `storeId:${storeId}`
    }).then((res: any) => {
      return res;
    }).catch((error: any) => {
      console.error('Error during active search:', error);
      return { hits: [], nbHits: 0, nbPages: 0, page: 0 };
    });
  }

  searchInactiveProducts(query: string, page: number = 0, limit: number = 12): Promise<any> {
    const storeId = this.storeService.getStoreId();
    return this.inactiveDbIndex.search(query, {
      page: page - 1,
      hitsPerPage: limit,
      typoTolerance: 'min',
      filters: `storeId:${storeId}`

    }).then((res: any) => {
      return res;
    }).catch((error: any) => {
      console.error('Error during inactive search:', error);
      return { hits: [], nbHits: 0, nbPages: 0, page: 0 };
    });
  }

  getQuerySuggestions(query: string, limit: number = 5): Promise<any> {
    // return this.suggestionsIndex.search(query, {
    //   hitsPerPage: 6,
    // }).then((res: any) => {
    //   return res;
    // }).catch((error: any) => {
    //   console.error('Error fetching query suggestions:', error);
    //   throw error;
    // });

    const categoriesPromise = this.categoriesIndex.search(query, { hitsPerPage: 2 });
    const productsPromise = this.suggestionsIndex.search(query, { hitsPerPage: 6 });

    return Promise.all([categoriesPromise, productsPromise]).then(([categoriesResult, productsResult]) => {
      // Combine results
      const modifiedCategoriesResult = {
        ...categoriesResult,
        hits: categoriesResult.hits.map((hit: any) => ({ ...hit, type: 'category' }))
      };

      const modifiedProductsResult = {
        ...productsResult,
        hits: productsResult.hits.map((hit: any) => ({ ...hit, type: 'product' }))
      };

      // Combine the results maintaining all fields
      return {
        categories: modifiedCategoriesResult,
        products: modifiedProductsResult
      };
    }).catch((error: any) => {
      console.error('Error during query suggestions:', error);
      throw error;
    });
  }

  // --------------------------------Send events to algolia search-------------------------------

  sendClickEvent(eventData: any): void {
    insightsClient('clickedObjectIDsAfterSearch', eventData);
  }

  sendClickEventAfterSearch(objectIDs: string[], eventName: string, queryID: string, positions: number[], indexName: string) {
    insightsClient('clickedObjectIDsAfterSearch', {
      eventName: eventName,
      index: indexName,
      queryID: queryID,
      objectIDs: objectIDs,
      positions: positions,
    });

    this.sendConversionEventAfterSearch(objectIDs,'product_clicked_after_search', queryID, indexName);
  }

  sendGenericClickEvent(objectIDs: string[], eventName: string, indexName: string) {
    insightsClient('clickedObjectIDs', {
      eventName: eventName,
      index: indexName,
      objectIDs: objectIDs,
    });

    this.sendGenericConversionEvent(objectIDs,'product_clicked', indexName)
  }

  sendAddToCartEventAfterSearch(objectIDs: string[],eventName: string,queryID: string,positions: number[],indexName: string) {
    insightsClient('addedToCartObjectIDsAfterSearch', {
      eventName: eventName,
      index: indexName,
      queryID: queryID,
      objectIDs: objectIDs,
      // positions: positions,
    });

    this.sendConversionEventAfterSearch(objectIDs,'product_added_to_cart_after_search', queryID, indexName);
  }
  
  sendGenericAddToCartEvent(objectIDs: string[],eventName: string,indexName: string) {
    insightsClient('addedToCartObjectIDs', {
      eventName: eventName,
      index: indexName,
      objectIDs: objectIDs,
    });

    this.sendGenericConversionEvent(objectIDs,'product_added_to_cart', indexName)
  }

  sendConversionEventAfterSearch(objectIDs: string[], eventName: string, queryID: string, indexName: string) {
    insightsClient('convertedObjectIDsAfterSearch', {
      eventName: eventName,
      index: indexName,
      queryID: queryID,
      objectIDs: objectIDs,
    });
  }

  sendGenericConversionEvent(objectIDs: string[], eventName: string, indexName: string) {
    insightsClient('convertedObjectIDs', {
      eventName: eventName,
      index: indexName,
      objectIDs: objectIDs,
    });
  }

  sendPurchaseEventAfterSearch(objectIDs: string[],eventName: string,queryID: string,positions: number[],indexName: string) {
    insightsClient('purchasedObjectIDsAfterSearch', {
      eventName: eventName,
      index: indexName,
      queryID: queryID,
      objectIDs: objectIDs,
    });

    this.sendConversionEventAfterSearch(objectIDs,'product_purchased_after_search', queryID, indexName);
  }
  
  sendPurchaseEvent(objectIDs: string[],eventName: string,indexName: string) {
    insightsClient('purchasedObjectIDs', {
      eventName: eventName,
      index: indexName,
      objectIDs: objectIDs,
    });

    this.sendGenericConversionEvent(objectIDs,'product_purchased', indexName)
  }

  sendViewEvents(objectIDs: string[], eventName: string, indexName: string) {
    insightsClient('viewedObjectIDs', {
      eventName: eventName,
      index: indexName,
      objectIDs: objectIDs,
    });
  }
}
