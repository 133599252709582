import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IonicModule}                                                             from '@ionic/angular';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';

@Component({
	           selector: 'daykart-block-button',
	           templateUrl: './block-button.component.html',
	           styleUrls: ['../../../shared.scss', './block-button.component.scss'],
	           standalone: true,
	           imports: [
		           IonicModule,
	           ],
	           changeDetection: ChangeDetectionStrategy.OnPush,
           })
export class BlockButtonComponent implements OnInit {
	@Input('block') block: boolean = true;
  @Input('width-style') width_style: string = 'w-auto';
  @Input('big-button') big_button: boolean = true;
	@Input('text') text: string = 'Confirm';
	@Output('button-clicked') buttonClicked: EventEmitter<any> = new EventEmitter<any>;
	constructor(private mixpanelService: MixedPanelsService) {
	}

  ngOnInit() {
	}

  onClick() {
		this.sendMixpanelEvent(this.text)
		this.buttonClicked.emit();
	}

	// This function is used to send data for mixpanel events related to subscription 
	sendMixpanelEvent(eventType:string=''){
		switch(eventType){
		  case 'Add More Products': {
			this.mixpanelService.track("b_add_more_products", { "screenName" : "My subscriptions"});
			break;
		  }
		}
	}

}
