import { Injectable } from '@angular/core';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Geolocation, GeolocationOptions } from '@awesome-cordova-plugins/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { ToastService } from '.././toast.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationPermissionService {

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private toastService: ToastService,
    private geolocation: Geolocation,
    private diagnostic: Diagnostic,
    private nativeGeocoder: NativeGeocoder,
    private locationAccuracy: LocationAccuracy
  ) { }

  async askForLocationPermission() {
    const isCordova = localStorage.getItem('isCordova');

    if (isCordova === 'native') {
      return new Promise((resolve, reject) => {
        this.diagnostic.requestLocationAuthorization()
         .then((res) => {
            console.log('permission allowed in native', res);
            let returnValue = false;
            switch (res) {
              case this.diagnostic.permissionStatus.NOT_REQUESTED:
                console.log("Permission not requested");
                returnValue = false;
                break;
              case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                console.log("Permission denied");
                returnValue = false;
                break;
              case this.diagnostic.permissionStatus.GRANTED:
                console.log("Permission granted always");
                returnValue = true;
                break;
              case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                console.log("Permission granted only when in use");
                returnValue = true;
                break;
            }
            resolve(returnValue);
          })
         .catch((error) => {
            console.log('permission denied in native', error);
            resolve(false);
          });
      });
      // this.diagnostic.requestLocationAuthorization().then((res) => {
      //   console.log('permission allowed in native', res)
      //   // return true;

      //   switch(res){
      //     case this.diagnostic.permissionStatus.NOT_REQUESTED:
      //         console.log("Permission not requested");
      //         return false;
      //     case this.diagnostic.permissionStatus.DENIED_ALWAYS:
      //       console.log("Permission denied");
      //       return false;
      //     case this.diagnostic.permissionStatus.GRANTED:
      //         console.log("Permission granted always");
      //         return true
      //     case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
      //         console.log("Permission granted only when in use");
      //         return true
      // }
      // return false;
      // },
      //   (error) => {
      //     console.log('permission denied in native', error)
      //     return false;
      //   })
    }
    else {
      // try {
      //   const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
      //   if (permissionStatus.state === 'granted') {
      //     console.log('Browser permission allowed');
      //     return true
      //   } else {
      //     console.log('Browser permission denied');
      //     navigator.geolocation.getCurrentPosition((res:any)=>{
      //       console.log(res)
      //     })

      //     return false;
      //     // prompt the user to enable geolocation manually
      //   }
      // } catch (error) {
      //   console.error('Error querying browser permission:', error);
      //   return null;
      // }
      return new Promise((resolve, reject) => {
        try {
          navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
            if (permissionStatus.state === 'granted') {
              console.log('Browser permission allowed');
              resolve(true);
            } else {
              console.log('Browser permission denied');
              navigator.geolocation.getCurrentPosition((res) => {
                console.log(res);
                resolve(true); // Assuming successful retrieval indicates permission is granted
              }, (error) => {
                console.log('Failed to get current position', error);
                resolve(false); // Indicate failure to retrieve position as permission denial
              });
            }
          }).catch(error => {
            console.error('Error querying browser permission:', error);
            resolve(false); // Resolve with false if there's an error querying permission
          });
        } catch (error) {
          console.error('Error in else block:', error);
          reject(error); // Reject the promise if there's an unexpected error
        }
      });
    }
    return null;
  }

  async checkIfLocationAllowed() {
    const isCordova = localStorage.getItem('isCordova');
    if (isCordova === 'native') {
      try {
        const result = await this.diagnostic.isLocationAvailable();
        console.log('location check result', result)
        if (result) {
          console.log('Native location permission allowed');
          return true;
        } else {
          console.log('Native location permission denied');
          return false;
        }
      } catch (error) {
        console.error('Error checking native location permission:', error);
        return false
      }
    }
    else{
      //checking browser permission
      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

      if (permissionStatus.state === 'granted') {
        console.log('Browser permission allowed');
        return true
      } else {
        console.log('Browser permission denied');
        return false;
      }

    }
  }

  async checkForLocationPermission() {
    try {
      const isCordova = localStorage.getItem('isCordova');
      if (isCordova === 'native') {
        const isHighAccuracyRequested = await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);

        console.log(isHighAccuracyRequested);
        if (isHighAccuracyRequested) {
          return true
          // this.getCurrentCoordinate();
        } else {
          return false
          // await this.askPermission();
        }
      }
      else {
        // when user uses browser
        if ('geolocation' in navigator) {
          return true
          // this.handlePermission()
        } else {
          return false
        }
      }
    } catch (error) {
      console.log(error);
      return error
    }
  }

  async findMyCurrentLocation() {
    const isCordova = localStorage.getItem('isCordova');

    if (isCordova === 'native') {
      const locationData = await this.currentLocationFromNativeApp();
      console.log('my current location', locationData)
      return locationData;
    }
    else {
      const locationData = await this.currentLocationFromBrowser();
      console.log('my current location', locationData)
      return locationData;
    }
  }

  async currentLocationFromBrowser() {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        // Define options for geolocation
        const options = {
          enableHighAccuracy: true,  // Enable high accuracy
          timeout: 5000,  // Maximum time (in milliseconds) to wait for location acquisition
          maximumAge: 0  // Maximum age (in milliseconds) of a possible cached location that is acceptable to return
        };
  
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coordinate = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
            };
            resolve(coordinate); // Resolve the promise with the coordinates
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.error("User denied the request for Geolocation.");
                reject(new Error("User denied the request for Geolocation.")); // Reject the promise with an error
                break;
              case error.POSITION_UNAVAILABLE:
                console.error("Location information is unavailable.");
                reject(new Error("Location information is unavailable.")); // Reject the promise with an error
                break;
              case error.TIMEOUT:
                console.error("The request to get user location timed out.");
                reject(new Error("The request to get user location timed out.")); // Reject the promise with an error
                break;
              default:
                console.error("An unknown error occurred.");
                reject(new Error("An unknown error occurred."));
            }
          },
          options
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        reject(new Error("Geolocation is not supported by this browser.")); // Reject the promise with an error
      }
    });
  }
  

  // async currentLocationFromBrowser() {
  //   if ("geolocation" in navigator) {
  //     // Define options for geolocation
  //     const options = {
  //       enableHighAccuracy: true,  // Enable high accuracy
  //       timeout: 5000,  // Maximum time (in milliseconds) to wait for location acquisition
  //       maximumAge: 0  // Maximum age (in milliseconds) of a possible cached location that is acceptable to return
  //     };
  //     await navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const coordinate = {
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //           accuracy: position.coords.accuracy,
  //         };
  //         console.log(coordinate)
  //         return coordinate;
  //       },
  //       (error) => {
  //         switch (error.code) {
  //           case error.PERMISSION_DENIED:
  //             console.error("User denied the request for Geolocation.");
  //             break;
  //           case error.POSITION_UNAVAILABLE:
  //             console.error("Location information is unavailable.");
  //             break;
  //           case error.TIMEOUT:
  //             console.error("The request to get user location timed out.");
  //             break;
  //         }
  //       },
  //       options
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // }

  async currentLocationFromNativeApp() {
    try {
      // Define options for geolocation
      const options: GeolocationOptions = {
        enableHighAccuracy: true,  // Enable high accuracy
        timeout: 5000,  // Maximum time (in milliseconds) to wait for location acquisition
        maximumAge: 0  // Maximum age (in milliseconds) of a possible cached location that is acceptable to return
      };
      const data = await this.geolocation.getCurrentPosition(options);

      const coordinate = {
        latitude: data.coords.latitude,
        longitude: data.coords.longitude,
        accuracy: data.coords.accuracy,
      };

      return coordinate;

    } catch (error) {
      console.error('Error getting current position:', error);
      return false;
    }
  }

  async turnOnLocationServices() {
    const isCordova = localStorage.getItem('isCordova');
  
    if (isCordova === 'native') {
      try {
        const isLocationEnabled = await this.diagnostic.isLocationEnabled();
        console.log('Location services enabled:', isLocationEnabled);
  
        if (!isLocationEnabled) {
          console.log('Location services are disabled, requesting to enable...');
          const canRequest = await this.locationAccuracy.canRequest();
  
          if (canRequest) {
            try {
              await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);
              const isLocationEnabledAfterRequest = await this.diagnostic.isLocationEnabled();
              console.log('Location services enabled after request:', isLocationEnabledAfterRequest);
              return isLocationEnabledAfterRequest;
            } catch (error) {
              console.error('Error requesting high accuracy location services:', error);
              return false;
            }
          } else {
            console.error('Cannot request location accuracy');
            return false;
          }
        } else {
          console.log('Location services are already enabled');
          return true;
        }
      } catch (error) {
        console.error('Error checking location services status:', error);
        return false;
      }
    } else {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
        console.log('Browser location permission status:', permissionStatus.state);
  
        if (permissionStatus.state === 'granted') {
          console.log('Browser permission already allowed');
          return true;
        } else {
          console.log('Browser permission not granted, requesting location...');
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                console.log('Location access granted:', position);
                resolve(true);
              },
              (error) => {
                console.error('Error getting location in browser:', error);
                resolve(false);
              }
            );
          });
        }
      } catch (error) {
        console.error('Error checking browser location permission:', error);
        return false;
      }
    }
  }

  // ----------------------------Send descriptive message for denied permission----------------

  async checkLocationPermissionStatus() {
    const isCordova = localStorage.getItem('isCordova');
  
    if (isCordova === 'native') {
      return new Promise((resolve, reject) => {
        this.diagnostic.getLocationAuthorizationStatus()
          .then((status) => {
            console.log('Current location permission status:', status);
            let returnValue;
            switch (status) {
              case this.diagnostic.permissionStatus.NOT_REQUESTED:
                console.log("Permission not requested");
                returnValue = 'not_requested';
                break;
              case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                console.log("Permission denied always");
                returnValue = 'denied_always';
                break;
              case this.diagnostic.permissionStatus.DENIED_ONCE:
                console.log("Permission denied once");
                returnValue = 'denied_once';
                break;
              case this.diagnostic.permissionStatus.GRANTED:
                console.log("Permission granted always");
                returnValue = 'granted';
                break;
              case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                console.log("Permission granted only when in use");
                returnValue = 'granted';
                break;
              default:
                console.log("Unknown permission status");
                returnValue = 'error';
            }
            resolve(returnValue);
          })
          .catch((error) => {
            console.log('Error getting location permission status', error);
            resolve('error');
          });
      });
    } else {
      // For browsers
      return new Promise((resolve, reject) => {
        try {
          navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
            let returnValue;
            switch (permissionStatus.state) {
              case 'granted':
                console.log('Browser permission allowed');
                returnValue = 'granted';
                break;
              case 'denied':
                console.log('Browser permission denied');
                returnValue = 'denied_always'; // Browsers typically do not differentiate between 'denied_once' and 'denied_always'
                break;
              case 'prompt':
                console.log('Browser permission prompt');
                returnValue = 'not_requested';
                break;
              default:
                console.log('Unknown browser permission status');
                returnValue = 'error';
            }
            resolve(returnValue);
          }).catch(error => {
            console.error('Error querying browser permission:', error);
            resolve('error');
          });
        } catch (error) {
          console.error('Error in else block:', error);
          reject(error); // Reject the promise if there's an unexpected error
        }
      });
    }
  }

  openLocationSettings(){
    this.diagnostic.switchToLocationSettings();
  }
  
}
