import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonInfiniteScroll, ModalController, NavController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import {
  Subcategory,
  Product,
  ProductObject,
  SubcategoryObject,
} from 'src/app/interface';
import { CartService } from 'src/app/services/cart.service';
import { CategoryService } from 'src/app/services/category.service';
import Swiper from 'swiper';
import { LoadingSpinnerComponent } from 'src/app/extra/loading-spinner/loading-spinner.component';
import { CartComponent } from '../../cart/cart.component';
import { ProductDetailComponent } from 'src/app/layout/product-detail/product-detail.component';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from 'src/app/services/auth.service';
import { SortModalComponent } from 'src/app/extra/modals/sort-modal/sort-modal.component';
import { FilterModalComponent } from 'src/app/extra/modals/filter-modal/filter-modal.component';
import { ToastsService } from 'src/app/services/helpers/toasts.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit, ViewWillEnter, ViewWillLeave {

  page: number = 1;
  limit: number = 10;
  totalPages: number = 0;
  loadingText: string = 'loading products...'
  @ViewChild(IonInfiniteScroll) infiniteScroll!: IonInfiniteScroll;

  @ViewChild(IonContent) content!: IonContent;
  @Input() backButtonRoute: any = '/';


  swiperSlideChanged($event: Event) {
  }
  selectedCategorySlug: string = '';
  selectedCategoryId: string = '';
  subCategories: Subcategory[] = [];
  selectedSubCategory!: Subcategory;
  products: any[] = [];
  addProduct: any;

  activeSubCategoryId: string = '';

  categoryLoaded: number = 0;
  productLoaded: number = 0;

  categoryGroup: string = '';

  selectedSortOption: any = {
    sortValue: 'name',
    sortOrder: 'asc'
  };

  sortValue: string = ''
  sortOrder: string = '';
  selectedFilterOption: any = [];
  searchInput: any = '';

  constructor(private route: ActivatedRoute, private categoryService: CategoryService, private cartService: CartService,
    private modalController: ModalController, private productService: ProductService, public authService: AuthService,
    private toastService: ToastsService,
    public router: Router,
    private navController: NavController
  ) {
    this.route.params.subscribe(params => {
      this.selectedCategorySlug = params['categorySlug'];
      this.selectedCategoryId = params['categoryId'];
    });
  }


  ionViewWillEnter() {
    // this.resetPagination();

  }

  ionViewWillLeave() {
    this.resetPagination();
    this.resetFilter();
    this.resetSort();
  }

  ngOnInit() {
    this.sortValue = '';
    this.sortOrder = '';
    this.selectedFilterOption = [];
    // this.selectedSortOption = JSON.stringify({sortValue:'name',sortOrder:'asc'})
    this.route.queryParams.subscribe((params: any) => {
      const id = params['activeSubcategoryId'];
      if (id) {
        this.activeSubCategoryId = id;
        this.updateUrlWithActiveSubcategory();
      }
    })
    this.resetPagination();
    this.getSubCategories(this.selectedCategoryId);
  }

  getSubCategories(selectedCategoryId: string) {
    this.categoryService.subCategories(selectedCategoryId).subscribe((res: any) => {

      if (res?.success) {
        this.categoryLoaded = 2;
        this.subCategories = res.subcategories;
        this.categoryGroup = res?.group;
        if (this.subCategories && this.subCategories.length > 0) {
          if (this.activeSubCategoryId != '') {
            const filteredCategory: any = this.subCategories.find((subCategory: Subcategory) => subCategory._id === this.activeSubCategoryId);
            this.selectedSubCategory = filteredCategory;
            this.activeSubCategoryId = this.selectedSubCategory?._id;
            this.getSubCategoryProducts(this.selectedSubCategory._id);
          }
          else {
            this.selectedSubCategory = this.subCategories[0];
            this.activeSubCategoryId = this.selectedSubCategory?._id;
            this.getSubCategoryProducts(this.selectedSubCategory._id);
          }

        }
      }
      else {
        this.categoryLoaded = 1;
        this.productLoaded = 1;
      }

    });
  }

  changeSubCategory(selectedSubCategoryId: string) {
    this.content.scrollToTop(400);
    this.searchInput = '';
    this.resetSort();
    this.resetFilter();
    this.resetPagination();
    this.getSubCategoryProducts(selectedSubCategoryId);
  }

  getSubCategoryProducts(selectedSubCategoryId: string) {
    // this.resetPagination();
    // this.content.scrollToTop(400);

    const payload = { brandId: this.selectedFilterOption }

    this.categoryService.categoryProducts(selectedSubCategoryId, this.page, this.limit, this.sortValue, this.sortOrder, this.searchInput, payload).subscribe((res: ProductObject) => {
      if (res?.success) {
        this.productLoaded = 2;
        this.activeSubCategoryId = selectedSubCategoryId;
        this.updateUrlWithActiveSubcategory();
        this.products = res.products;
        this.totalPages = res?.pages;
      }
      else {
        this.productLoaded = 1;
        this.activeSubCategoryId = selectedSubCategoryId;
        this.updateUrlWithActiveSubcategory();
        this.products = [];
      }
    })
  }

  updateUrlWithActiveSubcategory() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { activeSubcategoryId: this.activeSubCategoryId },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  navigateToSearch() {
    const currentUrl = this.router.url;
    const currentUrlPath = currentUrl.split('?')[0];

    this.router.navigate(['search'], {
      queryParams: {
        returnUrl: currentUrlPath, q: '', activeSubcategoryId: this.activeSubCategoryId
      }
    })
  }


  async openSortModal() {
    let modal = await this.modalController.create({
      component: SortModalComponent,
      cssClass: 'sort-modal',
      backdropDismiss: true,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      componentProps: {
        selectedOption: JSON.stringify({
          sortValue: this.sortValue,
          sortOrder: this.sortOrder
        })
      },
    })

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (data.status) {
        this.products = [];
        this.productLoaded = 0;
        this.sortValue = data?.data?.sortValue;
        this.sortOrder = data?.data?.sortOrder;
        console.log(this.sortValue, "  ", this.sortOrder)
        this.resetPagination();
        this.getSubCategoryProducts(this.activeSubCategoryId)
      }
    }
  }

  async openFilterModal() {
    let modal = await this.modalController.create({
      component: FilterModalComponent,
      cssClass: 'filter-modal',
      backdropDismiss: true,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      componentProps: {
        id: this.activeSubCategoryId,
        appliedFilters: this.selectedFilterOption
      },
    })

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (data.status) {
        this.products = [];
        this.productLoaded = 0;
        this.selectedFilterOption = data?.data;
        this.resetPagination()
        this.getSubCategoryProducts(this.activeSubCategoryId)
      }
    }
  }

  loadMoreProducts(event: any) {
    const payload = { brandId: this.selectedFilterOption }

    if (this.page < this.totalPages) {
      this.page = this.page + 1;
      this.categoryService.categoryProducts(this.activeSubCategoryId, this.page, this.limit, this.sortValue, this.sortOrder, this.searchInput, payload).subscribe(
        (newData: any) => {
          this.products = this.products.concat(newData.products);
          event.target.complete();
        },
        (error) => {
          console.error('Error loading data:', error);
          event.target.complete();
        }
      );
    }
    else if (this.page === this.totalPages) {
      this.loadingText = 'No more content...'
      setTimeout(() => {
        event.target.disabled = true;
      }, 600);
    }
  }


  // openProductDetails(){
  //   let modal = this.modalController.create({
  //     component:ProductDetailComponent
  //   })
  // }
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  swiperSlideChangeded(e: any) {
  }

  swiperReady() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  async openModal(pId: string) {
    let modal = await this.modalController.create({
      component: ProductDetailComponent,
      cssClass: 'product-detail',
      backdropDismiss: true,
      initialBreakpoint: 0.95,
      componentProps: {
        productId: pId
      },
    })

    modal.onDidDismiss()
      .then((data: any) => {
        if (data?.check) {
        }
      });

    return await modal.present();
  }

  refreshCategoryList(message: boolean) {
    // const receivedMessage = message;
    // if (receivedMessage) {
    //   this.getSubCategoryProducts(this.selectedSubCategory._id);
    // }
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  resetPagination() {
    this.page = 1;
    this.limit = 10;
    this.totalPages = 0;
  }

  resetSort() {
    this.sortValue = '';
    this.sortOrder = '';
  }

  resetFilter() {
    this.selectedFilterOption = [];
  }

  navigateToComponent() {
    this.navController.setDirection('back');
    this.router.navigate([this.backButtonRoute])
  }

  // -------------------------------------------Search--------------------------------------------
  onSearchInputChange() {
    this.resetPagination();
  }

  searchInCategoryProducts() {
    this.resetPagination();
    if (this.searchInput === '') {
      this.content.scrollToTop(400);
      this.resetSort();
      this.resetFilter();
      this.getSubCategoryProducts(this.selectedSubCategory?._id);
      return;
    }
    else if (this.searchInput.length < 3) {
      return
    }
    else {
      this.getSubCategoryProducts(this.selectedSubCategory?._id);
    }
  }

  reset() {
    this.resetPagination();
    this.searchInput = '';
    // this.isLoaded = false;
    // this.dataFound = 0;
  }

  getIconName(): string {
    switch (this.sortValue) {
      case 'name':
        return this.sortOrder === 'asc' ? 'fas fa-sort-alpha-down' : 'fas fa-sort-alpha-up';
      case 'price':
        return this.sortOrder === 'asc' ? 'fas fa-sort-amount-up' : 'fas fa-sort-amount-down';
      case 'discount':
        return 'fas fa-tag';
      default:
        return 'fas fa-sync-alt'; // Default icon
    }
  }
}
