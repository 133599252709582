import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productdetails-skeleton',
  templateUrl: './productdetails-skeleton.component.html',
  styleUrls: ['./productdetails-skeleton.component.scss'],
})
export class ProductdetailsSkeletonComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
