import { Injectable } from '@angular/core';
import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private platform: Platform, private alertController: AlertController,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private authService: AuthService
  ) {
  }

  checkPlatform() {
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        return 'android'
      } else if (this.platform.is('ios')) {
        return 'ios'
      } else if (this.platform.is('mobileweb')) {
        return 'mobileweb'
      } else {
        return 'browser'
      }
    });
  }

  disableConsoleInProduction(): void {
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
    }
  }

  // Code for android only
  // openWhatsAppChat(mobile: string, message: string = 'hi') {
  //   const countryCode = '+91';
  //   const phoneNumber = `${countryCode}${mobile}`;

  //   const encodedMessage = encodeURIComponent(message.replace(/\+/g, '%2B').replace(/ /g, '%20'));
  //   const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
  //   window.open(url, '_blank');
  // }

  // Common code for both android and iOS
  openWhatsAppChat(mobile: string, message: string = 'hi') {
    const countryCode = '+91';
    const phoneNumber = `${countryCode}${mobile}`;

    const encodedMessage = encodeURIComponent(message.replace(/\+/g, '%2B').replace(/ /g, '%20'));
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank'; // Use '_blank' for opening in a new tab or window
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // Code for android only
  // openGoogleMaps(latitude: number, longitude: number) {
  //   const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&zoom=15`;
  //   window.open(url, '_system');
  // }

  // Common code for both android and iOS
  openGoogleMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&zoom=15`;

    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank'; // Use '_blank' for opening in a new tab or window
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // Code for android only
  // callToMobileNumber(mobile:any) {
  //   window.open('tel:' + mobile, '_system');
  // }

  // Common code for both android and iOS
  callToMobileNumber(mobile: any) {
    const url = 'tel:' + mobile;

    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  splitUrlAndQueryParams(url: string): { path: string[], queryParams: { [key: string]: string } } {
    const [path, queryParamsString] = url.split('?');
    const pathSegments = path.split('/').filter(segment => segment);

    let queryParams: { [key: string]: string } = {};
    if (queryParamsString) {
      queryParams = queryParamsString
        .split('&')
        .reduce((acc, param) => {
          const [key, value] = param.split('=');
          acc[key] = value;
          return acc;
        }, {} as { [key: string]: string });
    }

    return {
      path: pathSegments,
      queryParams: queryParams,
    };
  }

  async closePopups(...types: ('alert' | 'modal' | 'popover')[]) {
    for (const type of types) {
      switch (type) {
        case 'alert':
          const alert = await this.alertController.getTop();
          if (alert) {
            alert.dismiss();
          }
          break;

        case 'modal':
          const modal = await this.modalController.getTop();
          if (modal) {
            modal.dismiss();
          }
          break;

        case 'popover':
          const popover = await this.popoverController.getTop();
          if (popover) {
            popover.dismiss();
          }
          break;

        default:
          console.warn(`Unknown popup type: ${type}`);
      }
    }
  }

  isUserLoggedIn() {
    const isLoggedIn = this.authService.getToken()
    if (isLoggedIn) {
      return true;
    }
    else {
      return false;
    }
  }
}
