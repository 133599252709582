import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-daykart-refund-policy',
  templateUrl: './daykart-refund-policy.component.html',
  styleUrls: ['./daykart-refund-policy.component.scss'],
})
export class DaykartRefundPolicyComponent implements OnInit {

  returnUrl: string = '';

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.returnUrl = this.authService.navigateBackFromPublicPages();
  }

  // Code for browser only
  callStore(contactNo: string) {
    window.open('tel:' + contactNo, '_system');
  }
}
