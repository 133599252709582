import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent implements OnInit {

  cartProduct: any;
  cartCount: number = 0;

  imageForDisplay:any
  
  constructor(private cartService: CartService,
    private router: Router,
    private modalController:ModalController,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.cartList();
  }

  cartList() {
    this.cartService.cartList().subscribe((res: any) => {
      if(res?.success){
        this.cartService.updateCartData(res);
        this.cartProduct = res?.cartProducts;
        this.cartCount = this.cartProduct.length;
        this.imageForDisplay = this.cartProduct[0]?.images[0]
      }
      else{
        this.cartProduct = [];
        this.cartService.updateCartData([]);
        this.closeModal()
      }
    })
  }

  refreshCartModalList(message: boolean) {
    const receivedMessage = message;
    if (receivedMessage) {
      this.cartList();
      // this.cartService.cartProductList();
    }
  }

  closeModal(){
    this.modalController.dismiss()
  }

  navigateToCart(){
    this.modalController.dismiss();
    this.router.navigate(['cart'])
  }


  navigateToAddAddress(){
    this.modalController.dismiss()
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }
}
