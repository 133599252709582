import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-store-helpline-hours',
  templateUrl: './store-helpline-hours.component.html',
  styleUrls: ['./store-helpline-hours.component.scss'],
})
export class StoreHelplineHoursComponent  implements OnInit {

  @Input() modalData: any;

  constructor(private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit() { }

  closeModal(){
    this.modalController.dismiss({status:true})
  }

}
