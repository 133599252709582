<!-- ----header--section------ -->
<ion-header [translucent]="true">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col [routerLink]="['/locate-address']">
          <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <h4 class="location">
            {{ addressPlace }}
            <span class="distance">{{ distanceInKm }} km away</span>
          </h4>
          <p class="text-gray location-address">
            {{ currentAddress }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-header>
<!-- -------end---------- -->
<ion-content [fullscreen]="true">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-segment
          [value]="addressType"
          (ionChange)="changeAddressType($event)"
        >
          <ion-segment-button value="home">
            <ion-label>House</ion-label>
          </ion-segment-button>
          <ion-segment-button value="office">
            <ion-label>office</ion-label>
          </ion-segment-button>
          <ion-segment-button value="other">
            <ion-label>Other</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid>
    <ion-row>
      <ion-col>
        <span class="text-gray">Enter Complete Address</span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid>
    <ion-row>
      <ion-col size="12" class="search-form">
        <div [formGroup]="customerAddressForm">
          <div class="input-container">
            <ion-input
              placeholder="House No./office No."
              formControlName="completeAddress"
            ></ion-input>

            <ng-container
              *ngFor="let validation of validations.completeAddress"
            >
              <div
                *ngIf="
                  customerAddressForm
                    .get('completeAddress')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('completeAddress')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>

          <div class="input-container">
            <ion-input
              placeholder="Floor(Optional)"
              formControlName="floor"
            ></ion-input>
          </div>
          <div class="input-container">
            <ion-input
              placeholder="Nearby Landmark(Optional)"
              formControlName="nearbyLandmark"
            ></ion-input>
          </div>

          <div class="input-container">
            <ion-input
              placeholder="Receiver's Name"
              formControlName="receiverName"
            ></ion-input>
            <ng-container *ngFor="let validation of validations.receiverName">
              <div
                *ngIf="
                  customerAddressForm
                    .get('receiverName')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('receiverName')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>
          <div class="input-container">
            <ion-input
              placeholder="Receiver's Phone Number"
              formControlName="receiverPhoneNumber"
              type="tel"
              minlength="10"
              maxlength="10"
            ></ion-input>
            <ng-container
              *ngFor="let validation of validations.receiverPhoneNumber"
            >
              <div
                *ngIf="
                  customerAddressForm
                    .get('receiverPhoneNumber')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('receiverPhoneNumber')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ion-col>
      <ion-col size="12" class="search-form">
        <div [formGroup]="customerAddressForm">
          <div class="input-container">
            <ion-input
              placeholder="House No./office No."
              formControlName="completeAddress"
            ></ion-input>

            <ng-container
              *ngFor="let validation of validations.completeAddress"
            >
              <div
                *ngIf="
                  customerAddressForm
                    .get('completeAddress')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('completeAddress')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>

          <div class="input-container">
            <ion-input
              placeholder="Floor(Optional)"
              formControlName="floor"
            ></ion-input>
          </div>
          <div class="input-container">
            <ion-input
              placeholder="Nearby Landmark(Optional)"
              formControlName="nearbyLandmark"
            ></ion-input>
          </div>

          <div class="input-container">
            <ion-input
              placeholder="Receiver's Name"
              formControlName="receiverName"
            ></ion-input>
            <ng-container *ngFor="let validation of validations.receiverName">
              <div
                *ngIf="
                  customerAddressForm
                    .get('receiverName')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('receiverName')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>
          <div class="input-container">
            <ion-input
              placeholder="Receiver's Phone Number"
              formControlName="receiverPhoneNumber"
              type="tel"
              minlength="10"
              maxlength="10"
            ></ion-input>
            <ng-container
              *ngFor="let validation of validations.receiverPhoneNumber"
            >
              <div
                *ngIf="
                  customerAddressForm
                    .get('receiverPhoneNumber')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('receiverPhoneNumber')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ion-col>
      
    </ion-row>
  </ion-grid>

 
</ion-content>
<!-- -------------------end----------------- -->
<ion-footer>
  <ion-row>
    <ion-col size="12">
      <ion-button
        class="common-btn"
        expand="block"
        (click)="checkAddressForm()"
      >
        {{ isEdit ? "Update" : "Save" }} Address
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>