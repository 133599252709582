import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CategoryModalComponent } from './category-modal/category-modal.component';

@Component({
  selector: 'app-floating-category',
  templateUrl: './floating-category.component.html',
  styleUrls: ['./floating-category.component.scss'],
})
export class FloatingCategoryComponent  implements OnInit {

  constructor(private modalController: ModalController,) { }

  ngOnInit() {}

  async categoryModal() {
    let modal = await this.modalController.create({
      component: CategoryModalComponent,
      cssClass: 'help-modal',
      backdropDismiss: true,
    })

    return await modal.present();
  }

}
