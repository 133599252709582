import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastMsg:any;

  constructor(public toast : ToastController) { }

  async showToast(message:string) {
    this.toastMsg = await this.toast.create({
      message: message,
      duration: 4000
    }).then((toastData) => {      
      toastData.present();
    });
  }

  hideToast() {
    this.toastMsg = this.toast.dismiss();
  }
}
