import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { SwitchLanguageComponent } from 'src/app/layout/switch-language/switch-language.component';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent  implements OnInit, ViewWillEnter {

  userMobileNumber:any;
  appVersion: any;

  environmentType:any='';

  constructor(private router:Router,
    private authService: AuthService,
    private modalController: ModalController
    ) { }

  ionViewWillEnter() {
    this.userMobileNumber = this.authService.mobileNumber;
    this.appVersion = environment.appVersion;

    this.environmentType = environment.production;
  }

  ngOnInit() {}

  async changeLanguage(){
      let modal = await this.modalController.create({
        component: SwitchLanguageComponent,
        cssClass: 'language-modal',
        backdropDismiss: true,
      })
  
      modal.onDidDismiss().then((data) => {        
      })  
      return await modal.present();  
  }

  navigateTo(url:string){
    // this.router.navigate(['account'])
    this.router.navigate([url], { queryParams: { returnUrl: 'account' } });
  }

  logout(){
    this.authService.logoutConfirmation()
  }



}
