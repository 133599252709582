<div class="popup-wrapper">
	<div class="container position-relative">
		<div class="close-button-container">
			<ion-button fill="clear" (click)="closeThis()"><ion-icon slot="icon-only"
					name="close-outline"></ion-icon>
				</ion-button>
		</div>
		<ion-row *ngIf="data.icon">
			<ion-col size="12" class="ion-text-center">
				<img [src]="'assets/img/daykart/subscription/'+data.icon" style="max-height:65px">
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="12" [class.ion-text-center]="data.heading.center_align">
				<ion-text class="fw-semibold fs-6">{{ data.heading.text }}</ion-text>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="12" [class.ion-text-center]="data.message.center_align">
				<ion-text class="fw-normal fs-7">{{ data.message.text }}</ion-text>
			</ion-col>
		</ion-row>
		<ion-row class="ion-margin-top">
			<ion-col size="6" class="ion-text-start">
				<daykart-outline-button [no-shadow]="true" [text]="data.buttons.cancel.text" [block]="true"
						[big-button]="true"
						(button-clicked)="onButtonClicked(data.buttons.cancel.action)"></daykart-outline-button>
			</ion-col>
			<ion-col size="6" class="ion-text-end">
				<daykart-block-button [text]="data.buttons.ok.text" [block]="true"
						(button-clicked)="onButtonClicked(data.buttons.ok.action)"></daykart-block-button>
			</ion-col>
		</ion-row>
	</div>
</div>