import { Component, OnInit } from '@angular/core';
import { InterceptRequestService } from 'src/app/services/helpers/intercept-request.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent  implements OnInit {

  constructor(public interceptService: InterceptRequestService) { }

  ngOnInit() {}

}
