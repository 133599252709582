<ion-header [translucent]="true">
  <ion-toolbar class="custom-toolbar">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col size="1" [routerLink]="'/' + returnUrl">
          <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <h4 class="location">
            {{ isEdit ? ("update" | translate) : ("add" | translate) }}
            {{ "address" | translate }}
          </h4>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

    <!-- <ion-grid *ngIf="!isLocationConfirmed">
    <ion-row>
      <ion-col size="12">
        <ion-searchbar class="search-bar" placeholder="Search here" [(ngModel)]="searchInput"
          (ionInput)="searchLocation()" (ionFocus)="onSearchFocus()" (ionBlur)="onSearchBlur()" (ionClear)="resetSearchField()" [debounce]="800"></ion-searchbar>
      </ion-col>
    </ion-row>
    
  </ion-grid>

  <div class="search-address-list">
    <div class="address-list" *ngIf="isSearchBarFocused && searchSuggestions.length > 0" (mousedown)="onSuggestionBoxClick()">
      <div *ngFor="let prediction of searchSuggestions">
        <p (click)="selectAddress(prediction)">{{ prediction?.description }}</p>
      </div>
    </div>
    <p *ngIf="dataFound === 0 && searchInput != ''" class="no-data-found">
      No Match Found...
    </p>
  </div> -->
  </ion-toolbar>
</ion-header>
<ion-progress-bar
  type="indeterminate"
  color="secondary"
  *ngIf="showProgressBar"
></ion-progress-bar>

<ion-content>
  <!-- Dimming overlay when search bar is focused -->
  <div class="overlay" *ngIf="isSearchBarFocused"></div>

  <div class="map-container" #mapSection>
    <div #map id="map"></div>
    <ion-img
      class="location-icon"
      src="../../../../../assets/img/daykart/location.png"
    ></ion-img>
    <ion-button
      class="locate-button"
      size="small"
      [disabled]="isLocationConfirmed"
      (click)="moveToCurrentLocation()"
    >
      <ion-icon name="locate"></ion-icon>
      {{ "locate_me" | translate }}
    </ion-button>

    <div class="search-result-section">
      <ion-grid *ngIf="!isLocationConfirmed">
        <ion-row>
          <ion-col size="12">
            <ion-searchbar
              class="search-bar"
              placeholder="Search here"
              [(ngModel)]="searchInput"
              [class.search-bar-focus]="isSearchBarFocused"
              (ionInput)="searchLocation()"
              (ionFocus)="onSearchFocus()"
              (ionBlur)="onSearchBlur()"
              (ionClear)="resetSearchField()"
              [debounce]="800"
            ></ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div class="search-address-list">
        <div
          class="address-list"
          *ngIf="
            searchSuggestions &&
            searchSuggestions.length > 0 &&
            isSearchBarFocused
          "
        >
          <div
            *ngFor="let prediction of searchSuggestions"
            class="address-suggestion"
            (mousedown)="onSuggestionBoxClick()"
            (click)="selectAddress(prediction)"
          >
            <h6>{{ prediction?.structured_formatting?.main_text }}</h6>
            <p class="text-gray">{{ prediction?.description }}</p>
          </div>
        </div>
        <!-- <div class="address-min-height">
      <div class="address-suggestion" *ngFor="let prediction of searchSuggestions" (click)="selectAddress(prediction)">
        <h6>{{ prediction?.structured_formatting?.main_text }}</h6>
        <p class="text-gray">{{ prediction?.description }}</p>
      </div>
    </div> -->
        <p *ngIf="dataFound === 0 && searchInput != ''" class="no-data-found">
          No Match Found...
        </p>
      </div>
    </div>
  </div>

  <ion-grid class="ion-padding">
    <ion-row>
      <ion-col size="12" class="select-location">
        <h5>{{ "select_your_location" | translate }}</h5>
        <p>{{ "your_location" | translate }}</p>
      </ion-col>
    </ion-row>

    <ion-row class="border-section">
      <ion-col size="1" class="check-mark">
        <ion-icon name="checkmark-circle"></ion-icon>
      </ion-col>
      <ion-col size="9">
        <p class="user-address overflow-control">
          {{ addressVal }}
        </p>
      </ion-col>
      <ion-col size="2" class="address-update" *ngIf="isLocationConfirmed">
        <small (click)="changeLocationOnMap()">{{
          "change" | translate
        }}</small>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- -----------------------------------------User-Address-Form-------------------------------------------------- -->

  <ion-grid *ngIf="isLocationConfirmed" class="ion-padding">
    <ion-row class="search-form">
      <ion-col size="12">
        <div [formGroup]="customerAddressForm" #formSection>
          <div class="input-container">
            <ion-label>{{ "house_no_office_no" | translate }}</ion-label>
            <ion-input #autofocus formControlName="completeAddress"></ion-input>

            <ng-container
              *ngFor="let validation of validations.completeAddress"
            >
              <div
                *ngIf="
                  customerAddressForm
                    .get('completeAddress')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('completeAddress')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>

          <div class="input-container">
            <ion-label>{{ "floor_optional" | translate }}</ion-label>
            <ion-input formControlName="floor"></ion-input>
          </div>
          <div class="input-container">
            <ion-label>
              {{ "nearby_landmark_optional" | translate }}
            </ion-label>
            <ion-input formControlName="nearbyLandmark"></ion-input>
          </div>

          <div class="input-container">
            <ion-label> {{ "receivers_name" | translate }} </ion-label>
            <ion-input formControlName="receiverName"></ion-input>
            <ng-container *ngFor="let validation of validations.receiverName">
              <div
                *ngIf="
                  customerAddressForm
                    .get('receiverName')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('receiverName')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>
          <div class="input-container">
            <ion-label>{{ "receivers_phone_number" | translate }}</ion-label>
            <ion-input
              formControlName="receiverPhoneNumber"
              type="tel"
              minlength="10"
              maxlength="10"
            ></ion-input>
            <ng-container
              *ngFor="let validation of validations.receiverPhoneNumber"
            >
              <div
                *ngIf="
                  customerAddressForm
                    .get('receiverPhoneNumber')
                    ?.hasError(validation.type) &&
                  customerAddressForm.get('receiverPhoneNumber')?.touched
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </div>
          <div class="input-container">
            <!-- <ion-col size="12"> -->
            <h6>{{ "address_type" | translate }}</h6>
            <ion-button
              [ngClass]="addressType === 'home' ? 'select-btn' : 'unselect-btn'"
              (click)="changeAddressType('home')"
              >{{ "home" | translate }}</ion-button
            >
            <ion-button
              [ngClass]="
                addressType === 'office' ? 'select-btn' : 'unselect-btn'
              "
              (click)="changeAddressType('office')"
              class="ion-margin-horizontal"
              >{{ "office" | translate }}</ion-button
            >
            <ion-button
              [ngClass]="
                addressType === 'other' ? 'select-btn' : 'unselect-btn'
              "
              (click)="changeAddressType('other')"
              >{{ "other" | translate }}</ion-button
            >
            <!-- <div class="ion-margin-top">
                <ion-checkbox formControlName="isDefault" labelPlacement="end">Set as default address</ion-checkbox>
              </div> -->
            <!-- </ion-col> -->
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div *ngIf="!isLocationConfirmed">
    <ion-row class="common-margin">
      <ion-col size="12">
        <ion-button
          class="common-btn"
          expand="block"
          [disabled]="!isStoreAvailable"
          (click)="fillAddressDetails()"
        >
          {{ "confirm_location_proceed" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>

<!-- ----------footer-------------- -->
<ion-footer *ngIf="isLocationConfirmed">
  <div>
    <ion-row class="common-margin">
      <ion-col size="12">
        <ion-button
          class="common-btn"
          expand="block"
          (click)="checkAddressForm()"
        >
          {{ isEdit ? ("update" | translate) : ("save" | translate) }}
          {{ "address" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-footer>

<!-- <ion-footer>
  <ion-row class="common-margin">
    <ion-col size="12">
      <ion-button *ngIf="!isLocationConfirmed" class="common-btn" expand="block" [disabled]="!isStoreAvailable"
        (click)="fillAddressDetails()">
        {{'confirm_location_proceed' | translate}}
      </ion-button>
      <ion-button *ngIf="isLocationConfirmed" class="common-btn" expand="block" (click)="checkAddressForm()">
        {{ isEdit ? ("update" | translate) : ("save"  | translate)}} {{'address' | translate}}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer> -->
