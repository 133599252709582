<ion-content>
  <ion-row>
    <ion-col size="12" class="ion-padding-vertical">
      <ion-skeleton-text
        [animated]="true"
        style="width: 60%; height: 20vh; margin: auto;"
      ></ion-skeleton-text>
    </ion-col>

    <ion-col size="12">
      <ion-skeleton-text
        [animated]="true"
        style="width: 70%; height: 13px; margin-bottom: .5rem;"
      ></ion-skeleton-text>
      <ion-skeleton-text
        [animated]="true"
        style="width: 50%;"
      ></ion-skeleton-text>
    </ion-col>
  </ion-row>
 
  <!-- --------product-card---- -->
  <ion-row>
    <ion-col size="4" *ngFor="let item of [0, 1, 2, 3, 4, 5]">
      <ion-skeleton-text
        [animated]="true"
        style="width: 100%; height: 10vh; border-radius: 10px"
      ></ion-skeleton-text>
      <ion-skeleton-text
        [animated]="true"
        style="width: 100%"
      ></ion-skeleton-text>
      <ion-skeleton-text
        [animated]="true"
        style="width: 75%; margin-top: 0.3rem"
      ></ion-skeleton-text>
      <ion-row>
        <ion-col size="6">
          <ion-skeleton-text
            [animated]="true"
            style="width: 100%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            [animated]="true"
            style="width: 75%; margin-top: 0.3rem"
          ></ion-skeleton-text>
        </ion-col>
        <ion-col size="6">
          <ion-skeleton-text
            [animated]="true"
            style="width: 100%; height: 3.5vh; border-radius: 5px"
          ></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
  <!-- --------------end----------- -->
</ion-content>
