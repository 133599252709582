<!-- ----------store--not--found------------ -->
<!-- <ion-content class="common-bg-color" (swipeToClose)="(false)">
  <ion-row class="current-location" *ngIf="sectionType === 'search'">
    <ion-col size="1">
      <ion-icon name="location-outline"></ion-icon>
    </ion-col>
    <ion-col size="11">
      <h6>Your Current Address</h6>
      <p class="text-gray-color">
        {{ currentAddress | titlecase }}
      </p>
    </ion-col>
  </ion-row>
  <ion-progress-bar type="indeterminate" color="secondary" *ngIf="showProgressBar"></ion-progress-bar>

  <div *ngIf="sectionType === 'search'">
    <h4 class="ion-padding-start">Select location</h4>
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-searchbar class="search-bar" placeholder="Search here" [(ngModel)]="searchInput"
            (ionInput)="searchLocation()" (ionClear)="resetSearchField()" [debounce]="800"></ion-searchbar>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-start current-location" (click)="useCurrentLocation()">
        <ion-col size="1">
          <ion-icon name="locate"></ion-icon>
        </ion-col>
        <ion-col size="11"> Use current location </ion-col>
      </ion-row>
    </ion-grid>
    <div class="address-list">
      <div *ngFor="let prediction of searchSuggestions">
        <p (click)="selectAddress(prediction)">{{ prediction?.description }}</p>
      </div>
    </div>

    <p *ngIf="dataFound === 0 && searchInput != ''" class="no-data-found">
      No Match Found...
    </p>

    <div *ngIf="isStoreAvailable === 0 && searchInput === ''">
      <div>
        <p class="ion-text-center ion-padding-top">
          <span class="title-text"> No store available </span>
        </p>
      </div>

      <div class="ion-text-center">
        <ion-img src="../../../../assets/img/daykart/store-not-found.png" alt="Store Not Found Image" />
        <p>
          <span class="msg-body">
            DayKart services is not available at
            <b>{{ placeName ? placeName : "your location" }}</b>. Please select a different location.
          </span>
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="sectionType === 'message'" class="ion-padding">
    <p class="ion-text-center ion-padding-top">
      <span class="title-text"> No store available </span>
    </p>

    <div class="ion-text-center" *ngIf="isStoreAvailable === 0">
      <ion-img src="../../../../assets/img/daykart/store-not-found.png" alt="Store Not Found Image" />
      <p>
        <span class="msg-body">
          DayKart is not available at
          <b>{{ placeName ? placeName : "your location" }}</b>. Please select a different location.
        </span>
      </p>
      <div>
        <ion-button expand="block" class="common-btn" (click)="dismiss()">Select New Location
        </ion-button>
      </div>
    </div>
  </div>
</ion-content> -->

<!-- ----------store--not--found------------ -->
<ion-content (swipeToClose)="(false)">
  <ion-row class="user-location" *ngIf="sectionType === 'search'">
    <ion-col size="1">
      <ion-icon name="location-outline"></ion-icon>
    </ion-col>
    <ion-col size="11">
      <h6>Your Current Address</h6>
      <p class="text-gray-color">
        {{ currentAddress | titlecase }}
      </p>
    </ion-col>
  </ion-row>

  <div class="ion-padding" *ngIf="sectionType === 'search'">
    <h4 class="ion-no-margin ion-padding-bottom">Select Delivery Location</h4>
    
      <ion-row>
        <ion-col size="12">
          <ion-searchbar class="search-bar" placeholder="Search here" [(ngModel)]="searchInput"
            (ionInput)="searchLocation()" (ionClear)="resetSearchField()" [debounce]="800"></ion-searchbar>
        </ion-col>
      </ion-row>

      <ion-progress-bar class="ion-margin-vertical" type="indeterminate" color="secondary" *ngIf="showProgressBar"></ion-progress-bar>


      <ion-row class="current-location ion-margin-vertical" (click)="useCurrentLocation()">
        <ion-col size="1">
          <i class="fa-solid fa-location-crosshairs"></i>
        </ion-col>
        <ion-col size="10"> Use current location </ion-col>
        <ion-col size="1">
          <i class="fa-solid fa-angle-right"></i>
        </ion-col>
      </ion-row>

      <ion-row class="your-location ion-margin-vertical" *ngIf="isStoreAvailable === 0 && searchInput === ''">
        <ion-col size="12">
          <p><span>Your location:</span> {{placeName}}</p>
        </ion-col>
      </ion-row>

    
   
      <!-- <div class="address-list" *ngIf="searchSuggestions && searchSuggestions.length > 0">
        <p *ngFor="let prediction of searchSuggestions" (click)="selectAddress(prediction)">{{ prediction?.description }}</p>
      </div>
    -->

    <div class="address-list" *ngIf="searchSuggestions && searchSuggestions.length > 0">
      <h6 class="text-gray search-result">SEARCH RESULTS</h6>
      <div class="address-min-height">
      <div class="address-suggestion" *ngFor="let prediction of searchSuggestions" (click)="selectAddress(prediction)">
        <h6>{{ prediction?.structured_formatting?.main_text }}</h6>
        <p class="text-gray">{{ prediction?.description }}</p>
      </div>
    </div>
    </div>

    <p *ngIf="dataFound === 0 && searchInput != ''" class="no-data-found ion-text-center">
      No Match Found...
    </p>

    <div *ngIf="isStoreAvailable === 0 && searchInput === ''">

      <div class="ion-text-center delivery-not-available">
        <ion-img src="../../../../assets/img/daykart/no-delivery.png" alt="Store Not Found Image" />
       <p>Delivery Not available for Your Location</p>
       <div class="not-delivery-content">
         <small>We deliver within a 3 km radius of our stores. Unfortunately, your location is outside our delivery area.</small>
       </div>
      </div>
      <div class="ion-margin-vertical"><ion-button expand="block" class="common-btn" (click)="openStoresModal()">Visit Our Stores</ion-button></div>

    </div>

  </div>

  <div class="ion-padding" *ngIf="sectionType === 'message'" class="ion-padding">

      <ion-row class="your-location ion-margin-vertical" *ngIf="isStoreAvailable === 0">
        <ion-col size="12">
          <p><span>Your location:</span> {{ placeName ? placeName : "your location" }}</p>
        </ion-col>
      </ion-row>

    <div *ngIf="isStoreAvailable === 0">

      <div class="ion-text-center delivery-not-available">
        <ion-img src="../../../../assets/img/daykart/no-delivery.png" alt="Store Not Found Image" />
       <p>Delivery Not available for Your Location</p>
       <div class="not-delivery-content">
         <small>We deliver within a 3 km radius of our stores. Unfortunately, your location is outside our delivery area.</small>
       </div>
      </div>
      <div class="ion-margin-vertical"><ion-button expand="block" class="common-btn" (click)="openStoresModal()">Visit Our Stores</ion-button></div>

    </div>
  </div>
</ion-content>
<!-- -------------------end------------------ -->