<ion-header>
  <!-- ------------header--skeleton------------ -->
  <ion-row>
    <ion-col size="8" class="ion-padding-top">
      <ion-skeleton-text
        [animated]="true"
        style="width: 30%; margin-bottom: 0.5rem"
      ></ion-skeleton-text>
      <ion-skeleton-text
        [animated]="true"
        style="width: 65%"
      ></ion-skeleton-text>
    </ion-col>
    <ion-col size="4" style="text-align: -webkit-center">
      <ion-skeleton-text
        [animated]="true"
        style="width: 50px; height: 50px; border-radius: 50%"
      ></ion-skeleton-text>
    </ion-col>
  </ion-row>
  <!-- -----------------end----------------- -->
</ion-header>