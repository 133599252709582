<ion-content class="offer-popup">
  <div class="popup-content">
    <div>
      <ion-img class="offer-logo" [src]="logoUrl" alt="Offer Logo" />
    </div>

    <h4><b>{{ title }}</b></h4>
    <p>{{ description }}</p>
    <ion-button class="common-btn" *ngIf="showButton" (click)="onButtonClick()">{{ buttonText }}</ion-button>
  </div>
</ion-content>