<!-- ---------header----------- -->
<app-title-header
  [title]="'terms & conditions'"
  [backButtonRoute]="'/account/about-us'"
  [parentScreenName]="'Terms and conditions'"
></app-title-header>
<!-- --------------end---------------- -->
<!-- -------------terms--------------- -->
<ion-content class="ion-padding-horizontal">
  <ion-row class="terms">
    <ion-col size="12">
      <h4><b>Terms & Conditions</b></h4>
      <p>
        Daykart Retail Pvt. Ltd. is the licensed owner of the brand DayKart and
        the website daykart.com (”The Site”). As a visitor to the Site/ Customer
        you are advised to please read the Terms & Conditions carefully. By
        accessing the services provided by the Site you agree to the terms
        provided in this Terms & Conditions document.
      </p>
    </ion-col>
    <ion-col size="12">
      <h4><b>Personal Information</b></h4>
      <p>
        As part of the registration process on the Site, DRPL may collect the
        following personally identifiable information about you: Name including
        first and last name, alternate email address, mobile phone number and
        contact details, Postal code, Demographic profile (like your age,
        gender, occupation, education, address etc.) and information about the
        pages on the site you visit/access, the links you click on the site, the
        number of times you access the page and any such browsing information.
      </p>
    </ion-col>

    <ion-col size="12">
      <h4><b>Eligibility</b></h4>
      <p>
        Services of the Site would be available to only select geographies in
        India. Persons who are "incompetent to contract" within the meaning of
        the Indian Contract Act, 1872 including un-discharged insolvents etc.
        are not eligible to use the Site. If you are a minor i.e. under the age
        of 18 years but at least 13 years of age you may use the Site only under
        the supervision of a parent or legal guardian who agrees to be bound by
        these Terms of Use. If your age is below 18 years your parents or legal
        guardians can transact on behalf of you if they are registered users.
        You are prohibited from purchasing any material which is for adult
        consumption and the sale of which to minors is prohibited
      </p>
    </ion-col>

    <ion-col size="12">
      <h4><b>License & Site access </b></h4>

      <p>
        DRPL grants you a limited sub-license to access and make personal use of
        this site and not to download (other than page caching) or modify it, or
        any portion of it, except with express written consent of DRPL. This
        license does not include any resale or commercial use of this site or
        its contents; any collection and use of any product listings,
        descriptions, or prices; any derivative use of this site or its
        contents; any downloading or copying of account information for the
        benefit of another merchant; or any use of data mining, robots, or
        similar data gathering and extraction tools. This site or any portion of
        this site may not be reproduced, duplicated, copied, sold, resold,
        visited, or otherwise exploited for any commercial purpose without
        express written consent of DRPL. You may not frame or utilize framing
        techniques to enclose any trademark, logo, or other proprietary
        information (including images, text, page layout, or form) of the Site
        or of DRPL and its affiliates without express written consent. You may
        not use any meta tags or any other "hidden text" utilizing the Site’s or
        DRPL’s name or trademarks without the express written consent of DRPL.
        Any unauthorized use terminates the permission or license granted by
        DRPL
      </p>
    </ion-col>

    <ion-col size="12">
      <h4><b>Account & Registration Obligations </b></h4>

      <p>
        All shoppers have to register and login for placing orders on the Site.
        You have to keep your account and registration details current and
        correct for communications related to your purchases from the site. By
        agreeing to the terms and conditions, the shopper agrees to receive
        promotional communication and newsletters upon registration. The
        customer can opt out either by unsubscribing in "My Account" or by
        contacting the customer service.
      </p>
    </ion-col>

    <ion-col size="12">
      <h4><b>Pricing </b></h4>
      <p>
        All the products listed on the Site will be sold at MRP unless otherwise
        specified. The prices mentioned at the time of ordering will be the
        prices charged on the date of the delivery. Although prices of most of
        the products do not fluctuate on a daily basis but some of the
        commodities and fresh food prices do change on a daily basis. In case
        the prices are higher or lower on the date of delivery no additional
        charges will be collected or refunded as the case may be at the time of
        the delivery of the order.
      </p>
    </ion-col>

    <ion-col size="12">
      <h4>
        <b>You may not use the Site for any of the following purposes: </b>
      </h4>
      <ol>
        <li>
          Disseminating any unlawful, harassing, libellous, abusive,
          threatening, harmful, vulgar, obscene, or otherwise objectionable
          material.
        </li>
        <li>
          Transmitting material that encourages conduct that constitutes a
          criminal offence or results in civil liability or otherwise breaches
          any relevant laws, regulations or code of practice.
        </li>
        <li>Gaining unauthorized access to other computer systems.</li>
        <li>
          Interfering with any other person's use or enjoyment of the Site.
        </li>
        <li>Breaching any applicable laws;</li>
        <li>
          Interfering or disrupting networks or web sites connected to the Site.
        </li>
        <li>
          Making, transmitting or storing electronic copies of materials
          protected by copyright without the permission of the owner.
        </li>
      </ol>
    </ion-col>

    <ion-col size="12">
      <h4><b>Colours </b></h4>
      <p>
        We have made every effort to display the colours of our products that
        appear on the Website as accurately as possible. However, as the actual
        colours you see will depend on your monitor, we cannot guarantee that
        your monitor's display of any colour will be accurate.
      </p>
    </ion-col>
    <ion-col size="12">
      <h4><b>Modification of Terms & Conditions of Service </b></h4>
      <p>
        DRPL may at any time modify the Terms & Conditions of Use of the Website
        without any prior notification to you. You can access the latest version
        of these Terms & Conditions at any given time on the Site. You should
        regularly review the Terms & Conditions on the Site. In the event the
        modified Terms & Conditions is not acceptable to you, you should
        discontinue using the Service. However, if you continue to use the
        Service you shall be deemed to have agreed to accept and abide by the
        modified Terms & Conditions of Use of this Site.
      </p>
    </ion-col>
    <ion-col size="12">
      <h4><b>Governing Law and Jurisdiction </b></h4>
      <p>
        This User Agreement shall be construed in accordance with the applicable
        laws of India. The Courts at Ghaziabad shall have exclusive jurisdiction
        in any proceedings arising out of this agreement. Any dispute or
        difference either in interpretation or otherwise, of any terms of this
        User Agreement between the parties hereto, the same shall be referred to
        an independent arbitrator who will be appointed by DRPL and his decision
        shall be final and binding on the parties hereto. The above arbitration
        shall be in accordance with the Arbitration and Conciliation Act, 1996
        as amended from time to time. The arbitration shall be held in Ghaziabad
        or any other place decided by the party. The High Court of judicature at
        Lucknow alone shall have the jurisdiction and the Laws of India shall
        apply.
      </p>
    </ion-col>
    <ion-col size="12">
      <h4><b>Reviews, Feedback, Submissions </b></h4>
      <p>
        All reviews, comments, feedback, postcards, suggestions, ideas, and
        other submissions disclosed, submitted or offered to the Site on or by
        this Site or otherwise disclosed, submitted or offered in connection
        with your use of this Site (collectively, the "Comments") shall be and
        remain the property of DRPL. Such disclosure, submission or offer of any
        Comments shall constitute an assignment to DRPL of all worldwide rights,
        titles and interests in all copyrights and other intellectual properties
        in the Comments. Thus, DRPL owns exclusively all such rights, titles and
        interests and shall not be limited in any way in its use, commercial or
        otherwise, of any Comments. DRPL will be entitled to use, reproduce,
        disclose, modify, adapt, create derivative works from, publish, display
        and distribute any Comments you submit for any purpose whatsoever,
        without restriction and without compensating you in any way. DRPL is and
        shall be under no obligation (1) to maintain any Comments in confidence;
        (2) to pay you any compensation for any Comments; or (3) to respond to
        any Comments. You agree that any Comments submitted by you to the Site
        will not violate this policy or any right of any third party, including
        copyright, trademark, privacy or other personal or proprietary right(s),
        and will not cause injury to any person or entity. You further agree
        that no Comments submitted by you to the Website will be or contain
        libelous or otherwise unlawful, threatening, abusive or obscene
        material, or contain software viruses, political campaigning, commercial
        solicitation, chain letters, mass mailings or any form of "spam". Please
        note all reviews submitted by customers are subject to checks and
        moderation by our content Team. DRPL reserves the rights to moderate as
        well as publish/not publish the reviews. DRPL reserves the rights to
        solicit or withhold reviews and ratings with no liability. DRPL reserve
        the right to accept, reject, moderate, monitor & edit or remove any
        comment. You grant DRPL the right to use the name that you submit in
        connection with any Comments. You agree not to use a false email
        address, impersonate any person or entity, or otherwise mislead as to
        the origin of any Comments you submit. You are and shall remain solely
        responsible for the content of any Comments you make, and you agree to
        indemnify DRPL and its affiliates for all claims resulting from any
        Comments you submit. DRPL and its affiliates take no responsibility and
        assume no liability for any Comments submitted by you or any third
        party.
      </p>
    </ion-col>
    <ion-col size="12">
      <h4><b>Copyright & Trademark </b></h4>
      <p>
        DRPL, its suppliers and licensors expressly reserve all intellectual
        property rights in all text, programs, products, processes, technology,
        content and other materials, which appear on this Site. Access to this
        Website does not confer and shall not be considered as conferring upon
        anyone any license under any of DRPL or any third party's intellectual
        property rights. All rights, including copyright, in this website are
        owned by or licensed to DRPL. Any use of this website or its contents,
        including copying or storing it or them in whole or part, other than for
        your own personal, non-commercial use is prohibited without the
        permission of DRPL. You may not modify, distribute or re-post anything
        on this website for any purpose. The names and logos and all related
        product and service names, design marks and slogans are the trademarks
        or service marks of DRPL, its affiliates, its partners or its suppliers.
        All other marks are the property of their respective companies. No
        trademark or service mark license is granted in connection with the
        materials contained on this Site. Access to this Site does not authorize
        anyone to use any name, logo or mark in any manner. References on this
        Site to any names, marks, products or services of third parties or
        hypertext links to third party sites or information are provided solely
        as a convenience to you and do not in any way constitute or imply DRPL
        endorsement, sponsorship or recommendation of the third party,
        information, product or service. DRPL is not responsible for the content
        of any third party sites and does not make any representations regarding
        the content or accuracy of material on such sites. If you decide to link
        to any such third party websites, you do so entirely at your own risk.
        All materials, including images, text, illustrations, designs, icons,
        photographs, programs, music clips or downloads, video clips and written
        and other materials that are part of this Website (collectively, the
        "Contents") are intended solely for personal, non-commercial use. You
        may download or copy the Contents and other downloadable materials
        displayed on the Website for your personal use only. No right, title or
        interest in any downloaded materials or software is transferred to you
        as a result of any such downloading or copying. You may not reproduce
        (except as noted above), publish, transmit, distribute, display, modify,
        create derivative works from, sell or participate in any sale of or
        exploit in any way, in whole or in part, any of the Contents, the
        Website or any related software. All software used on this Website is
        the property of DRPL or its licensees and suppliers and protected by
        Indian and international copyright laws. The Contents and software on
        this Website may be used only as a shopping resource. Any other use,
        including the reproduction, modification, distribution, transmission,
        republication, display, or performance, of the Contents on this Website
        is strictly prohibited. Unless otherwise noted, all Contents are
        copyrights, trademarks, trade dress and/or other intellectual property
        owned, controlled or licensed by DRPL, one of its affiliates or by third
        parties who have licensed their materials to DRPL and are protected by
        Indian and international copyright laws. The compilation (meaning the
        collection, arrangement, and assembly) of all Contents on this Website
        is the exclusive property of DRPL and is also protected by Indian and
        international copyright laws.
      </p>
    </ion-col>
    <ion-col size="12">
      <h4><b>Objectionable Material </b></h4>
      <p>
        You understand that by using this Site or any services provided on the
        Site, you may encounter Content that may be deemed by some to be
        offensive, indecent, or objectionable, which Content may or may not be
        identified as such. You agree to use the Site and any service at your
        sole risk and that to the fullest extent permitted under applicable law,
        DRPL and its affiliates shall have no liability to you for Content that
        may be deemed offensive, indecent, or objectionable to you.
      </p>
    </ion-col>

    <ion-col size="12">
      <h4><b>Indemnity </b></h4>
      <p>
        You agree to defend, indemnify and hold harmless DRPL, its employees,
        directors, officers, agents and their successors and assigns from and
        against any and all claims, liabilities, damages, losses, costs and
        expenses, including attorney's fees, caused by or arising out of claims
        based upon your actions or inactions, which may result in any loss or
        liability to DRPL or any third party including but not limited to breach
        of any warranties, representations or undertakings or in relation to the
        non-fulfilment of any of your obligations under this User Agreement or
        arising out of the your violation of any applicable laws, regulations
        including but not limited to Intellectual Property Rights, payment of
        statutory dues and taxes, claim of libel, defamation, violation of
        rights of privacy or publicity, loss of service by other subscribers and
        infringement of intellectual property or other rights. This clause shall
        survive the expiry or termination of this User Agreement.
      </p>
    </ion-col>

    <ion-col size="12">
      <h4><b>Termination </b></h4>
      <p>
        This User Agreement is effective unless and until terminated by either
        you or DRPL. You may terminate this User Agreement at any time, provided
        that you discontinue any further use of this Site. DRPL may terminate
        this User Agreement at any time and may do so immediately without
        notice, and accordingly deny you access to the Site, such termination
        will be without any liability to DRPL. Upon any termination of the User
        Agreement by either you or DRPL, you must promptly destroy all materials
        downloaded or otherwise obtained from this Site, as well as all copies
        of such materials, whether made under the User Agreement or otherwise.
        DRPL's right to any Comments shall survive any termination of this User
        Agreement. Any such termination of the User Agreement shall not cancel
        your obligation to pay for the product already ordered from the Website
        or affect any liability that may have arisen under the User Agreement.
      </p>
    </ion-col>
  </ion-row>
</ion-content>
<!-- --------------end--------------- -->
