import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { StoreBusinessHoursComponent } from 'src/app/extra/modals/store-business-hours/store-business-hours.component';
import { CartService } from 'src/app/services/cart.service';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { OutOfStockPopupComponent } from 'src/app/extra/modals/out-of-stock-popup/out-of-stock-popup.component';
import { LoadingControllerService } from 'src/app/services/helpers/loading-controller.service';
import { ModalManagerService } from 'src/app/services/misc/modal-manager.service';

@Component({
  selector: 'app-address-footer',
  templateUrl: './address-footer.component.html',
  styleUrls: ['./address-footer.component.scss'],
})
export class AddressFooterComponent implements OnInit {

  @Input() addressSelected: boolean = false;
  @Input() addressData: any;
  @Input() billingData: any;
  @Input() storeTimingData: any;


  @Output() addressMessageEvent = new EventEmitter<boolean>();

  @ViewChild('infoSpan', { static: false }) infoSpan!: ElementRef; // Reference to span element

  constructor(private modalController: ModalController,
    private router: Router,
    private locationService: LocationService,
    private cartService: CartService,
    private mixpanelService: MixedPanelsService,
    private toastService: ToastsService,
    private loadingService: LoadingControllerService,
    private modalManagerService: ModalManagerService

  ) { }

  get storeDistance() {
    return this.locationService.storeDistance;
  }

  ngOnInit() {

  }

  checkProductAvailabilityBeforeCheckout() {
    this.loadingService.showLoading("Checking availability... Please wait.", 'crescent');

    this.cartService.verifyCartItemsBeforePayment(this.addressData?._id).subscribe(async (res: any) => {
      if (res?.success) {
        this.loadingService.dismissLoading();
        this.proceedToPlaceOrder();
      }
      else {
        await this.loadingService.dismissLoading();
        this.openOutOfStockProductModal(res?.outOfStockProducts);
      }
    },
      (error) => {
        this.loadingService.dismissLoading();
        this.toastService.showToast(error?.message);
      }
    )
  }

  proceedToPlaceOrder() {
    const showModal = this.cartService.isModalOpenedRecently();
    if ((!this.storeTimingData?.showStoreHoursModal && showModal)) {
      this.openWorkingHoursModal()
    }
    else {
      this.mixpanelService.track('Order checkout started', {
        'total_savings': this.billingData?.totalSavings,
        'total_price': this.billingData?.grandTotal
      });
      this.navigateToPayment();
    }
  }


  async openAddressModal() {
    let modal = await this.modalController.create({
      component: AddressModalComponent,
      // initialBreakpoint:80,
      // breakpoints:[.2,.8]
      cssClass: 'address-modal',
      backdropDismiss: true,
      initialBreakpoint: 0.95,
      componentProps: {
        'sendData': ((childModalData: any) => this.handleModalData(childModalData)).bind(this),
        selectedAddressId: this.addressData?._id,
        selectedAddressData: this.addressData
      }
    })

    modal.onDidDismiss().then((data) => {
      if (data?.data?.addressChanged) {
        this.addressSelected = true;
        this.addressData = data?.data?.addressData;
        // if(this.addressData)
      }
    })
    return await modal.present();
  }

  handleModalData(data: any) {
    if (data.refreshData) {
      this.addressMessageEvent.emit(true)
    }
  }

  checkAddressType(type: string) {
    switch (type) {
      case 'home':
        return '../../assets/img/daykart/Home Icon.svg'
      case 'office':
        return '../../assets/img/daykart/Office Icon.svg'
      case 'hotel':
        return '../../assets/img/daykart/hotel.png'
      default:
        return '../../assets/img/daykart/other-location.png'
    }
  }

  async openOutOfStockProductModal(productsData: any) {
    let modal = await this.modalController.create({
      component: OutOfStockPopupComponent,
      cssClass: 'out-of-stock-modal',
      backdropDismiss: true,
      componentProps: {
        cartOutOfStockProduct: productsData
      },
    })

    await modal.present();

    const { data } = await modal.onDidDismiss();
    console.log("received modal data", data)
    if (data) {
      if (data.status) {
        this.addressMessageEvent.emit(true);
      }
    }
  }

  async openWorkingHoursModal() {
    let modal = await this.modalController.create({
      component: StoreBusinessHoursComponent,
      cssClass: 'working-hours-modal',
      backdropDismiss: true,
      componentProps: {
        modalData: this.storeTimingData
      },
    })

    localStorage.setItem('storeTimingModalDisplayTime', new Date().getTime().toString());
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (data.status) {
        this.navigateToPayment();
      }
    }
  }

  navigateToPayment() {
    const navigationExtras = {
      state: {
        data: {
          selectedAddress: this.addressData,
          billData: this.billingData
        }
      }
    };
    this.router.navigate(['cart/payment'], navigationExtras)
  }


  async promptAddressSelection(ev: any) {
    const spanRect = this.infoSpan.nativeElement.getBoundingClientRect(); // Get span position
    const popoverEvent = {
      target: {
        getBoundingClientRect: () => spanRect
      }
    };

    const dynamicContent = 'Please select an address before proceeding';
    await this.modalManagerService.presentPopover(popoverEvent, dynamicContent);
  }


}
