<app-title-header [isPrimaryHeader]="true" [title]="productsTitle | titlecase" [parentScreenName]="'Product detail'"></app-title-header>
<!-- -----------------end------------- -->
<ion-content>
  <ion-row class="ion-padding-horizontal ion-margin-top" *ngIf="isLoaded">
    <ion-col
      class="product-box"
      size="4"
      size-sm="3"
      size-md="3"
      size-lg="2"
      *ngFor="let product of productsData"
    >
      <app-product-card
        [cardType]="'list'" [subscription-check]="true"
        [productData]="product"
        [parentScreenName]="bannerId !== ''? 'Banners from home page': 'Product list page from home page'"
      ></app-product-card>
    </ion-col>
  </ion-row>

  <div
    *ngIf="isLoaded && dataFound === 1"
    class="no-product-img ion-text-center ion-padding-horizontal"
  >
    <app-product-not-found
      [searchedValue]="searchInput"
      (messageEvent)="refreshSearch($event)"
    ></app-product-not-found>
  </div>

  <app-searchproduct-skeleton
    *ngIf="!isLoaded && dataFound === 0"
  ></app-searchproduct-skeleton>

  <ion-infinite-scroll (ionInfinite)="paginate($event)">
    <ion-infinite-scroll-content
      *ngIf="totalPages > page"
      [loadingText]="loadingText"
      loadingSpinner="bubbles"
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer>
  <app-footer></app-footer>
</ion-footer>
