import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, MenuController, ModalController, PopoverController, ViewWillEnter } from '@ionic/angular';
import { AddressService } from 'src/app/services/address.service';
import { PopoverMenuComponent } from '../popover-menu/popover-menu.component';
import { ToastsService } from 'src/app/services/helpers/toasts.service';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit, ViewWillEnter {

  addressFound: boolean = false;
  addressData: any;

  @Input() selectedAddressId: string | null = null;
  @Input() selectedAddressData: any;

  @Input() sendData!: (childData: any) => void;


  constructor(private modalController: ModalController,
    private router: Router,
    private addressService: AddressService,
    private menu: MenuController,
    public popoverController: PopoverController,
    private alertController: AlertController,
    private toastService: ToastsService,
  ) { }


  ionViewWillEnter() {
  }

  ngOnInit() {
    this.addressList();
  }

  addressList() {
    this.addressService.addressList().subscribe((res: any) => {
      if (res?.success) {
        this.addressFound = true;
        this.addressData = res?.addressList;
      }
      else {
        this.addressFound = false;
        this.addressData = [];
      }
    })
  }

  checkAddressType(type: string) {
    switch (type) {
      case 'home':
        return '../../assets/img/daykart/Home Icon.svg'
      case 'office':
        return '../../assets/img/daykart/Office Icon.svg'
      case 'hotel':
        return '../../assets/img/daykart/hotel.png'
      default:
        return '../../assets/img/daykart/other-location.png'
    }
  }

  setAddress(data: any) {
    this.modalController.dismiss(
      {
        addressChanged: true,
        addressData: data
      }
    );
  }

  navigateToAddAddress() {
    this.modalController.dismiss()
    this.router.navigate(['/locate-address'], { queryParams: { returnUrl: 'cart' } })
  }

  async presentPopover(e: Event, id: any, addressData: any) {
    const popover = await this.popoverController.create({
      component: PopoverMenuComponent,
      event: e,
      componentProps: {
        addressId: id,
        data: addressData
      }
    });

    await popover.present();

    const { role, data } = await popover.onDidDismiss();
    if (data?.data?.status && data?.data?.actionType === 'deleteAddress') {
      this.addressList();
      this.sendData({
        refreshData: true
      });
    }
    else if (data?.data?.status && data?.data?.actionType === 'defaultAddressChange') {
      this.modalController.dismiss(
        {
          addressChanged: true,
          addressData: data?.data?.addressData
        }
      );
    }
  }

  // -----------------------------------New Ui--------------------------------------------

  onRowClick(selectedId: string, selectedAddressData: any): void {
    this.selectedAddressId = selectedId;
    this.selectedAddressData = selectedAddressData;
  }

  editAddress(addressId: string) {
    this.modalController.dismiss();
    this.router.navigate(['locate-address'], { queryParams: { addressId: addressId, returnUrl: 'cart' } })
  }

  async openDeleteAddressAlert(addressId: string) {

    const alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: 'Are you sure you want to delete this address?',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'primary',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            this.addressService.deleteAddress(addressId).subscribe((res: any) => {
              if (res?.success) {
                this.toastService.showToast(res?.message);
                this.addressList();
                this.sendData({
                  refreshData: true
                });
                alert.dismiss();
              }
              else {
                this.toastService.showToast(res?.message)
                alert.dismiss();
              }
            })
          },
        },
      ],
    });
    await alert.present();
  }

  useThisAddress() {
    // console.log(this.selectedAddressData)
    // this.modalController.dismiss(
    //   {
    //     addressChanged: true,
    //     addressData: this.selectedAddressData
    //   }
    // );

    console.log(this.selectedAddressData)
    if (this.selectedAddressData?.isDefault) {
      this.dismissModal();
    }
    else {
      this.addressService.setDefaultAddress({ addressId: this.selectedAddressData?._id }).subscribe((res: any) => {
        if (res?.success) {
          this.toastService.showToast(res?.message);
          this.dismissModal();
        }
        else {
          this.toastService.showToast(res?.message);
        }
      })
    }

  }

  dismissModal(){
    //dismiss the modal after setting address as default
    this.modalController.dismiss(
      {
        addressChanged: true,
        addressData: this.selectedAddressData
      }
    );
  }

}
