import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CartService } from 'src/app/services/cart.service';
import { LoadingControllerService } from 'src/app/services/helpers/loading-controller.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-out-of-stock-popup',
  templateUrl: './out-of-stock-popup.component.html',
  styleUrls: ['./out-of-stock-popup.component.scss'],
})
export class OutOfStockPopupComponent implements OnInit {

  cartOutOfStockProduct: any;
  cartCount: number = 0;

  imageForDisplay: any

  constructor(
    private cartService: CartService,
    private router: Router,
    private modalController: ModalController,
    private toastService: ToastsService,
    private loadingService: LoadingControllerService,
  ) { }

  ngOnInit() {
    
  }

  removeAllOutOfStockProducts() {
    this.loadingService.showLoading("Removing all out-of-stock products from your cart...", 'crescent');
    
    this.cartService.removeOutOfStockProductsBeforePayment({}).subscribe((res: any) => {
      if (res?.success) {
        this.loadingService.dismissLoading();
        this.closeModal(true);
      }
      else {
        this.loadingService.dismissLoading();
        this.toastService.showToast(res?.message);
      }
    },
      (error) => {
        this.loadingService.dismissLoading();
        this.toastService.showToast(error?.message);
      }
    )
  }

  refreshCartModalList(message: boolean) {
    const receivedMessage = message;
    if (receivedMessage) {
      // this.cartList();
      // this.cartService.cartProductList();
    }
  }

  closeModal(data: any) {
    console.log('modal data', data)
    this.modalController.dismiss({ status: data })
  }

}
