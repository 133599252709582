import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ModalController } from '@ionic/angular';
import { ProductDetailComponent } from 'src/app/layout/product-detail/product-detail.component';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private modalController: ModalController) { }

  productDetail(id:string){
    return this.http.get(`${environment.apiUrl}/product/info?productId=${id}`)
  }
  loadDefaultImg(event: any) {
    event.target.src = '../../../assets/img/daykart/default-product-img.png'
  }

  productSearch(key:any,page:number=1,limit:number=10){
    return this.http.get(`${environment.apiUrl}/product/search?name=${key}&page=${page}&limit=${limit}`)
  }

  productRequest(body:any){
    return this.http.post(`${environment.apiUrl}/request`,body);
  }

  async showProductDetails(productId: string) {
    let modal = await this.modalController.create({
      component: ProductDetailComponent,
      cssClass: 'product-detail',
      backdropDismiss: true,
      componentProps: {
        productId: productId
      },
    })

    modal.onDidDismiss()
      .then((data: any) => {
        if (data?.check) {
        }
      });

    return await modal.present();

  }
  
}
