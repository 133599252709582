<ion-row>
  <ion-col size="12">
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%; height: 40px; border-radius: 10px"
    ></ion-skeleton-text>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12">
    <ion-skeleton-text
      [animated]="true"
      style="width: 60%; height: 15px"
    ></ion-skeleton-text>
  </ion-col>
</ion-row>
<!-- --------category-card---- -->
<ion-row>
  <ion-col
    size="3"
    size-sm="2"
    size-md="3"
    size-lg="3"
    *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
  >
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%; height: 10vh; border-radius: 10px"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 75%; margin-top: 0.3rem"
    ></ion-skeleton-text>
  </ion-col>
</ion-row>
<!-- --------------end----------- -->
<ion-row>
  <ion-col size="12">
    <ion-skeleton-text
      [animated]="true"
      style="width: 60%; height: 15px"
    ></ion-skeleton-text>
  </ion-col>
</ion-row>
<!-- --------category-card---- -->
<ion-row>
  <ion-col size="3" *ngFor="let item of [0, 1, 2, 3, 4, 5]">
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%; height: 10vh; border-radius: 10px"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 75%; margin-top: 0.3rem"
    ></ion-skeleton-text>
  </ion-col>
</ion-row>
<!-- --------------end----------- -->

<!-- --------product-card---- -->
<ion-row>
  <ion-col size="12">
    <ion-skeleton-text
      [animated]="true"
      style="width: 60%; height: 15px"
    ></ion-skeleton-text>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="4" *ngFor="let item of [0, 1, 2]">
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%; height: 10vh; border-radius: 10px"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 75%; margin-top: 0.3rem"
    ></ion-skeleton-text>
    <ion-row>
      <ion-col size="6">
        <ion-skeleton-text
          [animated]="true"
          style="width: 100%"
        ></ion-skeleton-text>
        <ion-skeleton-text
          [animated]="true"
          style="width: 75%; margin-top: 0.3rem"
        ></ion-skeleton-text>
      </ion-col>
      <ion-col size="6">
        <ion-skeleton-text
          [animated]="true"
          style="width: 100%; height: 3.5vh; border-radius: 5px"
        ></ion-skeleton-text>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
<!-- --------------end----------- -->

<!-- --------product-card---- -->
<ion-row>
  <ion-col size="12">
    <ion-skeleton-text
      [animated]="true"
      style="width: 60%; height: 15px"
    ></ion-skeleton-text>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="4" *ngFor="let item of [0, 1, 2]">
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%; height: 10vh; border-radius: 10px"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 100%"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 75%; margin-top: 0.3rem"
    ></ion-skeleton-text>
    <ion-row>
      <ion-col size="6">
        <ion-skeleton-text
          [animated]="true"
          style="width: 100%"
        ></ion-skeleton-text>
        <ion-skeleton-text
          [animated]="true"
          style="width: 75%; margin-top: 0.3rem"
        ></ion-skeleton-text>
      </ion-col>
      <ion-col size="6">
        <ion-skeleton-text
          [animated]="true"
          style="width: 100%; height: 3.5vh; border-radius: 5px"
        ></ion-skeleton-text>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
<!-- --------------end----------- -->
