import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInput, ModalController, PopoverController, ViewWillEnter } from '@ionic/angular';
import { InfoPopoverComponent } from 'src/app/extra/info-popover/info-popover.component';
import { CategoryObject } from 'src/app/interface';
import { AddressModalComponent } from 'src/app/layout/address-modal/address-modal.component';
import { CartService } from 'src/app/services/cart.service';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { HomeService } from 'src/app/services/home.service';
import { ModalManagerService } from 'src/app/services/misc/modal-manager.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, ViewWillEnter, OnDestroy {

  isAddressSelected: boolean = false;
  isLoaded: boolean = false;

  cartProductFound: number = 0;
  returnUrl: string = '';

  cartProduct: any;
  sponsoredproducts: any;
  sections: any;
  addProduct: any;

  billingData: any;

  selectedAddress: any;

  storeTimingData: any;

  cashbackFound: boolean = false;
  cashbackData: any;

  isCouponAddedorRemoved: boolean = false;

  isCartAlertRecentlyOpened: boolean = false;


  constructor(private cartService: CartService, private homeService: HomeService,
    private router: Router, private toastService: ToastsService,
    private modalController: ModalController, private activatedRoute: ActivatedRoute,
    private popoverController: PopoverController,
    private cdRef: ChangeDetectorRef,
    private mixpanelService: MixedPanelsService,
    private modalManagerService: ModalManagerService
  ) { }


  ionViewWillEnter(): void {
    // this.isAddressSelected = false;
    // this.selectedAddress = '';
    this.cartList();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';

      if (this.router.getCurrentNavigation()?.extras.state) {
        this.isAddressSelected = true;
        this.selectedAddress = this.router.getCurrentNavigation()?.extras.state?.['addressData'];

      }
      else {
        this.isAddressSelected = false;
        this.selectedAddress = '';
      }
    });
    this.mixpanelService.track("s_your_cart", {})
  }

  cartList() {
    this.cartService.cartList().subscribe((res: any) => {
      if (res?.success) {
        this.cartService.updateCartData(res)
        this.isLoaded = true;
        this.cartProductFound = 1;
        this.cartProduct = res?.cartProducts;
        this.billingData = res?.billDetails;
        this.isAddressSelected = res?.defaultAddressAvailable;

        this.storeTimingData = res?.storeOperatingHours;

        this.cashbackFound = res?.isCashbackAvailable;
        this.cashbackData = res?.cashback;

        this.isCouponApplied = res?.isCouponApplied;
        if (res?.isCouponApplied) {
          this.couponInput = res?.promocodeName;
        }

        if (this.isAddressSelected) {
          this.selectedAddress = res?.userAddress
        }

        const showCartPopup: boolean = res?.cartAlert?.showCartAlert;
        if (showCartPopup && !this.isCartAlertRecentlyOpened) {
          this.showCashbackPopup(res?.cartAlert?.cartAlertData);
        }

        // this.cartService.cartSubject$.subscribe(data => {
        //   console.log(data)
        // });

      }
      else {
        this.isLoaded = true;
        this.cartProductFound = -1;
        this.cartProduct = [];
        this.cartService.updateCartData([])
        // this.toastService.showToast('No product in cart');
        // this.router.navigate(['home'])
      }
    })


  }

  showCashbackPopup(cartPopupData: any) {
    this.isCartAlertRecentlyOpened = true;
    this.modalManagerService.openOfferPopUpModal(cartPopupData);
  }

  getSections() {
    this.homeService.section().subscribe((res: CategoryObject) => {
      this.sections = res.data;
      this.sponsoredproducts = res.data[5].productList;
    })
  }

  addToCart(id: string) {
    let payload = {
      productId: id,
      quantity: 1
    }
    this.cartService.addtocart(payload).subscribe((res: any) => {
      this.addProduct = res;
    })
  }

  async presentBalancePopover(ev: any) {
    const balance = `Current Balance: ${this.billingData?.walletCurrentBalance}`
    // const popover = await this.popoverController.create({
    //   component: InfoPopoverComponent,
    //   cssClass: 'info-popover',
    //   backdropDismiss: true,
    //   event: ev,
    //   dismissOnSelect: true,
    //   componentProps: {
    //     infoMessage: balance
    //   },
    //   mode: 'ios',
    // });

    // await popover.present();


    // setTimeout(async () => {
    //   if (await this.popoverController.getTop() === popover) {
    //     popover.dismiss();
    //   }
    // }, 2000);
    await this.modalManagerService.presentPopover(ev, balance);
  }

  refreshCartList(message: boolean) {
    const receivedMessage = message;
    if (receivedMessage) {
      this.cartList();
      // this.cartService.cartProductList();
      // this.cartService.cartSubject$.subscribe(data => {
      //   console.log(data)
      // });

    }
  }

  // -----------------------------------Coupon--------------------------------------

  isCouponApplied: any = null;
  couponInput: string = '';

  onCouponInputChange(event: any) {
    // Get the current value from the input
    const inputValue = event.target.value;

    // Remove any blank spaces and non-alpha-numeric characters
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

    this.cdRef.detectChanges()
    this.couponInput = sanitizedValue;
  }

  applyCoupon() {
    if (this.couponInput.length < 3) {
      this.toastService.showToast("Enter valid coupon code")
    }
    else {
      this.isCouponAddedorRemoved = true;

      const payload = {
        promocodeName: this.couponInput
      }
      this.cartService.addCoupon(payload).subscribe((res: any) => {
        if (res?.success) {
          this.isCouponApplied = true;
          this.toastService.showToast(res?.message)
          this.mixpanelService.track('Coupon Code Applied', {
            'code_used': this.couponInput,
          });
          this.cartList();
          this.isCouponAddedorRemoved = false;

        }
        else {
          this.isCouponAddedorRemoved = false;
          this.toastService.showToast(res?.message, 3000)

        }
      },
        (error) => {
          this.isCouponAddedorRemoved = false;
          this.toastService.showToast(error?.error?.message, 3000)
        }
      )
    }
  }

  removeCoupon() {
    this.isCouponAddedorRemoved = true;

    this.cartService.removeCoupon().subscribe((res: any) => {
      if (res?.success) {
        this.isCouponApplied = false;
        this.couponInput = '';
        this.toastService.showToast(res?.message);
        this.cartList();
        this.isCouponAddedorRemoved = false;
      }
      else {
        this.isCouponAddedorRemoved = false;
        this.toastService.showToast(res?.message);
      }
    },
      (error) => {
        this.isCouponAddedorRemoved = false;
        this.toastService.showToast(error?.error?.message, 3000)
      })
  }

  ngOnDestroy() {
    console.log("cart on destroy called");
    this.isCartAlertRecentlyOpened = false;
  }




}
