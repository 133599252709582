<app-modal-header [title]="'Discover Our Nearby Stores!'"></app-modal-header>
<ion-content>
  <ion-row class="store-address" *ngFor="let store of storeData; let i = index">
    <ion-col size="12">
      <h6>
        <span>{{ "Store " + (i + 1) }}</span>
      </h6>
      <p>
        {{ store.storeAddress }}
      </p>
      <p><span>Phone No.:</span>{{ store.mobile }}</p>
    </ion-col>
    <ion-col size="6">
      <ion-button
        class="custom-outline-btn"
        expand="block"
        (click)="contactStore(store?.mobile)"
      >
        Call Us
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button
        expand="block"
        class="common-btn"
        (click)="
          showNearbyStoreOnMap(
            store?.location?.coordinates[1],
            store?.location?.coordinates[0]
          )
        "
        >Get Directions
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
