import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastsService {

  constructor(private toastController: ToastController) { }

  async showToast(message: string, duration: number = 2000, marginTopValue: string = '-5rem',position: 'top' | 'bottom' | 'middle' = 'bottom', className: string = 'toast-background-primary') {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      cssClass: className,
    });

    // Set dynamic margin-top using the passed value
    document.documentElement.style.setProperty('--toast-margin-top', marginTopValue);

    toast.present();
  }
}
