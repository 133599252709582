import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, CanDeactivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreventNavigationGuard implements CanDeactivate<any> {  
  constructor(private network:Network){}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.network.type !== 'none') {
      return true;
    } else {
      // Prevent navigation
      return false;
    }
 }
  
}
