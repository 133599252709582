<!-- ---------request-----product------------ -->
<app-modal-header [title]="'Request for Unavailable Product'"></app-modal-header>
<ion-content class="ion-padding request-product">
  <div [formGroup]="requestProductForm">
    <div class="input-section">
      <ion-label>Product Name</ion-label>
      <ion-input formControlName="itemName"></ion-input>
      <ng-container *ngFor="let validation of validations.itemName">
        <div
          class="error-message"
          *ngIf="
            requestProductForm.get('itemName')?.hasError(validation.type) &&
            requestProductForm.get('itemName')?.touched
          "
        >
          <span class="error-msg"
            ><i class="fa-solid fa-circle-info"></i>
            {{ validation.message }}</span
          >
        </div>
      </ng-container>
    </div>

    <div class="input-section">
      <ion-label>Contact Number</ion-label>
      <ion-input type="tel" label="+91" formControlName="mobile" maxlength="10">
      </ion-input>
      <ng-container *ngFor="let validation of validations.mobile">
        <div
          class="error-message"
          *ngIf="
            requestProductForm.get('mobile')?.hasError(validation.type) &&
            requestProductForm.get('mobile')?.touched
          "
        >
          <span class="error-msg"
            ><i class="fa-solid fa-circle-info"></i>
            {{ validation.message }}</span
          >
        </div>
      </ng-container>
    </div>

    <div>
      <ion-label>Address</ion-label>
      <ion-textarea formControlName="address"></ion-textarea>
    </div>
    <div>
      <ion-label>Product Description</ion-label>
      <ion-textarea formControlName="description"></ion-textarea>
    </div>

    <div class="ion-text-center">
      <ion-button size="small" class="request-btn" (click)="checkAddressForm()"
        >Submit</ion-button
      >
    </div>
  </div>
</ion-content>
<!-- -------------------------end-------------------------------- -->
