<div class="no-data-found">
  <ion-img
    src="../../../../assets/img/daykart/product-not-found-1.png"
    alt=""
  />
  <h6>Sorry, no matches found</h6>
  <small>
    Tell us what you need, and we'll try to stock it for you
  </small>

  <div class="ion-margin-top">
    <ion-button size="small" class="request-btn" (click)="requestProduct()"
      >Send Request</ion-button
    >
  </div>
</div>
