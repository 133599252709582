import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-categorylist-skeleton',
  templateUrl: './categorylist-skeleton.component.html',
  styleUrls: ['./categorylist-skeleton.component.scss'],
})
export class CategorylistSkeletonComponent  implements OnInit {


  constructor() { }

  ngOnInit() {}

}
