import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-request-product',
  templateUrl: './request-product.component.html',
  styleUrls: ['./request-product.component.scss'],
})
export class RequestProductComponent implements OnInit {

  @Input() productName:any;

  constructor(private modalController: ModalController,
    private productService: ProductService,
    private toastService: ToastService,
    private mixpanelService: MixedPanelsService
  ) { }

  requestProductForm = new FormGroup({
    itemName: new FormControl('', Validators.compose([Validators.required])),
    mobile: new FormControl('', Validators.compose([Validators.required, Validators.pattern('(^[6-9][0-9]{9}$)')])),
    address: new FormControl(''),
    description: new FormControl(''),
  })

  validations = {
    'mobile': [
      { type: 'required', message: 'Please enter mobile no.' },
      { type: 'pattern', message: 'Please enter valid 10-digit mobile number' },
    ],
    'itemName': [
      { type: 'required', message: 'Please enter name of requested product' },
    ]
  };

  get formControlValue(){
    return this.requestProductForm.controls
  }

  ngOnInit() { 
    this.formControlValue.itemName.patchValue(this.productName);
    const mobileNo = localStorage.getItem('mobile')
    if(mobileNo){
      this.formControlValue.mobile.patchValue(mobileNo)
    }
    this.mixpanelService.track("s_request_unavailable_product", {});
  }

  checkAddressForm() {
    if (this.requestProductForm.invalid) {
      this.requestProductForm.markAllAsTouched();
    }
    else {
      this.submitRequest()
    }
  }

  submitRequest(){
    const payload = this.requestProductForm.value;

    this.productService.productRequest(payload).subscribe((res:any)=>{
      if(res?.success){
        this.dismiss(true);
        this.toastService.showToast(res?.message);
        this.mixpanelService.track("b_submit", { "screenName" : "Search Page", "searchedTerm":this.requestProductForm.controls.itemName.value});

      }
      else{
        this.toastService.showToast(res?.message)

      }
    })
  }

  dismiss(status:boolean){
    this.modalController.dismiss({
      status: status
    })
  }

  
  closeModal(){
    this.modalController.dismiss()
  }
}
