<!-- -------about--us------ -->
<!-- ----header--section------ -->
<app-title-header [title]="'aboutUs'" [backButtonRoute]="'/account'" [parentScreenName]="'About us'"></app-title-header>
<!-- -------end------------>
<ion-content [fullscreen]="true" class="ion-padding-horizontal">
  <ion-grid>
    <ion-row>
      <ion-col class="address-section">
        <h5>Version<span class="distance"> v{{appVersion}}</span></h5>
      </ion-col>
    </ion-row>
    <!-- ------about--details----section--- -->
    <ion-row>
      <ion-col>
        <p class="text-gray">
          DayKart is an Offline to Online supermarket catering to all your
          grocery, household and other essential needs. We operate from our
          offline stores giving you a platform to shop online without visiting
          the store physically and get the same quality and trust delivered at
          your doorstep. We have a wide range of more than 10,000 products from
          multiple brands and multiple categories to choose from. We provide you
          a platform to buy from wide range of products, from fresh Fruits and
          Vegetables, Rice, Dals, Spices, Pickles, Seasonings, Beverages to
          Electrical Products, Beauty Products, Kitchenware, First Aid
          necessities, etc. to satisfy all your basic to luxury lifestyle needs
          at affordable and attractive prices. You can choose from wide range of
          options in every category and sub-category having exclusively crafted
          product range of supreme quality at affordable & competitive prices.
          We offer you quickest delivery with our next hour delivery offering,
          which enables our customers to get products delivered at their
          doorstep within 3 hours of placing their order. We offer you
          convenience of multiple payment methods, from COD (the most popular
          offering) to online payment gateways and wallets. Our motto is to
          serve our customers with the best services in the industry with
          comfort of reaching out to us directly through our extensive customer
          support and satisfaction team available online as well as offline at
          our experience stores.
        </p>
        <p class="text-gray">
          DayKart provides you with best quality products at not just best
          prices but with the quality unmatched which is a struggle to find in
          today’s scenario. We allow you with simple and comfortable interface
          to browse and shop your monthly groceries using our wish list creation
          tool and our versatile & personalised ordering platform which stores
          your order history and suggests you with your next grocery list basis
          your buying pattern. We keep adding thousands of new products daily to
          meet our customer needs. Not only this, in case you don’t find the
          desired product, all you need to do is to drop us an email with
          product of your choice and we assure you to deliver it in your next
          order. No more getting stuck in traffic jams, paying for parking ,
          standing in long queues and carrying heavy bags – get everything you
          need, when you need, right at your doorstep. This helps you stay safe
          at your home without any hassle of going to a local grocery store. The
          protocols followed at our stores are of top most quality ensuring
          hygiene and safety of our customers & employees. We are one of the
          most customer centric company, we are striving to provide you with
          best service and quality. We want us and our customers to boast about
          our service & quality at an effective price rather than being the one
          offering maximum discounts to dupe customers. We hereby take the
          pleasure of welcoming you to join our family of happy and satisfied
          customers. Thankyou for choosing us as your preferred partner. You’re
          just a few clicks away from getting the best shopping experience so
          far. What are you waiting for, head on to our ordering page – Click
          here to start building your order.
        </p>
      </ion-col>
    </ion-row>
    <!-- ---------end--------- -->
    <!-- -----about--policy------ -->
    <ion-row class="product-summary" (click)="navigateTo('/account/about-us/privacy')">
      <ion-col size="1">
        <ion-icon name="lock-closed-outline" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h4>Privacy Policy</h4>
      </ion-col>
      <ion-col size="1" class="right-arrow">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row class="product-summary" (click)="navigateTo('/account/about-us/terms')">
      <ion-col size="1">
        <ion-icon name="cog-outline" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h4>Terms & Conditions</h4>
      </ion-col>
      <ion-col size="1" class="right-arrow">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row class="product-summary" (click)="navigateTo('/account/about-us/shipping-policy')">
      <ion-col size="1">
        <ion-icon name="cube-outline" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h4>Shipping Policy</h4>
      </ion-col>
      <ion-col size="1" class="right-arrow">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row class="product-summary" (click)="navigateTo('/account/about-us/return-policy')">
      <ion-col size="1">
        <ion-icon name="sync-outline" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h4>Return Policy</h4>
      </ion-col>
      <ion-col size="1" class="right-arrow">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row class="product-summary" (click)="navigateTo('/account/about-us/refund-policy')">
      <ion-col size="1">
        <ion-icon name="cash-outline" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h4>Refund Policy</h4>
      </ion-col>
      <ion-col size="1" class="right-arrow">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>
    <!-- ----end------ -->
  </ion-grid>
</ion-content>
<!-- ------------end------------ -->
