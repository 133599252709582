import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ProductObject, SubcategoryObject } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  subCategories(categoryId: string = ''): Observable<SubcategoryObject> {
    return this.http.get<any>(`${this.apiUrl}/category/getsubcategories?categoryId=${categoryId}`);
  }

  categoryProducts(categoryId: string = '',page:number=1,limit:number=10,sortValue:string='',sortOrder:string='',name:string='',body:any = {brandId:[]}): Observable<ProductObject> {
    return this.http.post<any>(`${this.apiUrl}/products/getproductsbysubcatname?categoryId=${categoryId}&page=${page}&limit=${limit}&sortValue=${sortValue}&sortOrder=${sortOrder}&name=${name}`,body);

    // return this.http.get<any>(`${this.apiUrl}/products/getproductsbysubcatname?categoryId=${categoryId}&page=${page}&limit=${limit}&sortValue=${sortValue}&sortOrder=${sortOrder}`);
  }

  productDetail(productId:string):Observable<any>{
    return this.http.get<any>(environment.apiUrl + `/product/info?productId=${productId}`)
  }

  brandListByCategory(id:string){
    return this.http.get<any>(`${environment.apiUrl}/category/brands?subCategoryId=${id}`)
  }

}
