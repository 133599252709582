<!-- -----------header---------- -->
<ion-header [translucent]="true" class="ion-no-border">
  <ion-toolbar class="custom-toolbar">
    <div class="header-title">
      <ion-grid>
        <ion-row>
          <ion-col
            class="back-btn ion-activatable ripple-parent rounded-rectangle"
            size="1"
            (click)="navigateToComponent()"
          >
            <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-col>
          <ion-col size="11" class="ion-padding-start">
            <!-- (ngModelChange)="onSearchInputChange()" -->
            <ion-searchbar
              placeholder="Search in products"
              class="search-bar custom"
              (ionFocus)="showSuggestions()"
              (ionBlur)="hideSuggestions()"
              #searchBar
              [(ngModel)]="searchInput"
              (ionInput)="searchInProducts()"
              (ionClear)="reset()"
              [debounce]="800"
            ></ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-toolbar>
</ion-header>
<!-- -----------------end------------- -->
<ion-content class="common-bg-color" (touchstart)="handleTouch($event)">
  <div
    class="search-suggestion"
    *ngIf="searchSuggestions.length > 0"
  >
    <ion-row
      class="suggestion-list"
      *ngFor="let prediction of searchSuggestions; let i = index"
      (click)="selectSuggestion(prediction, i)"
    >
      <ion-col size="1">
        <ion-img
          src="{{ prediction?.imageUrl }}"
          (ionError)="loadDefaultImg($event)"
        />
      </ion-col>
      <ion-col size="11" class="ion-padding-start query-name">
        <p [innerHTML]="highlightMatch(searchInput, prediction?.query)"></p>
        <span class="category-tag" *ngIf="prediction.type === 'category'">
          (in Categories)</span
        >
      </ion-col>
    </ion-row>
  </div>
  
  <ion-row class="ion-padding-horizontal" *ngIf="isLoaded">
    <ion-col size="12" *ngIf="productData.length > 0">
      <h6 class="result-title">Showing results for “{{ searchInput }}”</h6>
    </ion-col>
    <ion-col
      class="product-box"
      size="4"
      size-sm="3"
      size-md="3"
      size-lg="2"
      *ngFor="let product of productData; let i = index"
    >
      <app-product-card
        [cardType]="'list'"
        [productData]="product"
        [parentScreenName]="'Search Page'"
        [eventData]="{
          position: i,
          queryId:
            product?.stock > 0
              ? currentActiveSearchQueryId
              : currentInactiveSearchQueryId
        }"
      ></app-product-card>
    </ion-col>
  </ion-row>

  <div
    *ngIf="isLoaded && dataFound === 1"
    class="no-product-img ion-text-center ion-padding-horizontal ion-margin-top"
  >
    <app-product-not-found
      [searchedValue]="searchInput"
      (messageEvent)="refreshSearch($event)"
    ></app-product-not-found>
  </div>

  <app-searchproduct-skeleton
    *ngIf="!isLoaded && dataFound < 0"
  ></app-searchproduct-skeleton>

  <!-- <ion-infinite-scroll (ionInfinite)="loadMoreProducts($event)">
    <ion-infinite-scroll-content
      *ngIf="totalPages > page"
      [loadingText]="loadingText"
      loadingSpinner="bubbles"
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll> -->
  <ion-infinite-scroll
    threshold="100px"
    (ionInfinite)="loadMoreProducts($event)"
  >
    <ion-infinite-scroll-content
      *ngIf="activePage < activeTotalPages || inactivePage < inactiveTotalPages"
      [loadingText]="loadingText"
      loadingSpinner="bubbles"
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer>
  <app-footer></app-footer>
</ion-footer>
