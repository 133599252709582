<!-- --- celebration-section--tiles----------->
<div class="celebration-section"
  [ngStyle]="{'background': tileData?.type === 'gradient' ? 'linear-gradient(' + tileData?.color1 + ', ' + tileData?.color2 + ')' : tileData?.color1}">
  <ion-row>
    <ion-col size="12">
      <h5 [ngStyle]="{'color': tileData?.titleTextColor}">
        {{ tileData?.title }}
      </h5>
      <p [ngStyle]="{'color': tileData?.subTitleTextColor}">
        {{ tileData?.subTitle }}
      </p>
    </ion-col>
  </ion-row>

  <!-- <ion-row>
    <ion-col *ngFor="let tile of sectionData?.tiles" size="4">
      <div class="celebration-box" [ngStyle]="{'background-color': tile?.backgroundColor}">
        <div class="image-container">
          <ion-img [src]="tile?.imageUrl" alt="" (click)="handleTileClick(tile?.navigationLink)"></ion-img>
          <p *ngIf="tile?.text" class="overlay-text" [ngStyle]="{'color': tile?.textColor, 'background-color': tile?.textBackgroundColor}">
            {{ tile?.text }}
          </p>
        </div>
      </div>
    </ion-col>
  </ion-row> -->

  <ion-row>
    <ion-col *ngFor="let tile of sectionData?.tileData?.tileRow" size="4">
      <div class="celebration-box">
        <div class="image-container">
          <ion-img [src]="tile?.imageUrl" alt="" (click)="handleTileClick(tile?.tileType,tile)"></ion-img>
        </div>
      </div>
    </ion-col>
  </ion-row>
  
</div>