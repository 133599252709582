import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';

@Component({
  selector: 'app-order-completion-status',
  templateUrl: './order-completion-status.component.html',
  styleUrls: ['./order-completion-status.component.scss'],
})
export class OrderCompletionStatusComponent implements OnInit {

  orderStatus!: string;
  orderTime!: string;
  orderId!: string;
  userOrderId!:string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private toastService: ToastsService,private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.orderStatus = params['orderStatus'] || 'failure';
      this.orderTime = params['orderTime'] || new Date().toLocaleString();
      this.orderId = params['orderId'];
      this.userOrderId = params['userOrderId'];

      if (!this.orderId) {
        this.toastService.showToast('Order ID not found. Redirecting to home page...');
        this.redirectToHome();
      }
    });

    if(this.orderStatus === "success"){
      this.mixpanelService.track("s_order_placed_successfully", {})
    }
    else if(this.orderStatus === "failure"){
      this.mixpanelService.track("s_order_not_placed", {})
    }
  }

  trackOrder() {
    this.mixpanelService.track("b_track_order", { "screenName" : "Order Processing", "orderID": this.userOrderId});

    this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: this.orderId } })
    history.replaceState({}, '', '/');
  }

  returnToCart() {
    this.mixpanelService.track("b_back_to_cart", { "screenName" : "Order Processing", "orderID": this.userOrderId });

    this.router.navigate(['cart']);
    history.replaceState({}, '', '/');
  }

  redirectToHome() {
    this.mixpanelService.track("b_go_to_homepage", { "screenName" : "Order Processing", "orderID": this.userOrderId });

    this.router.navigate(['']);
    history.replaceState({}, '', '/');
  }

}
