<!-- ----header--section------ -->
<ion-header [translucent]="true">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col size="1">
          <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <small>Express Store</small>
          <p>Ghaziabad Shastri Nagar</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <h2>Order is on the way</h2>
        <p>Arriving in 8 minutes</p>
      </ion-row>
    </ion-grid>
  </div>
</ion-header>
<!-- -------end------------>
<ion-content [fullscreen]="true"> </ion-content>
