import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var gtag: any;
declare let window: any;
// declare var dataLayer: any[];

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

interface CustomParam {
  key: string;
  value: string | number | undefined;
}
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  loadGoogleAnalytics() {
    const trackingId = environment.googleAnalyticsTrackingId;
    // const script = document.createElement('script');
    // script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    // script.async = true;

    // document.head.appendChild(script);

    // window['dataLayer'] = window['dataLayer'] || [];
    // function gtag(){window['dataLayer'].push(arguments);}

    // gtag('js', new Date());

    // gtag('config', trackingId);

    // Initialize dataLayer array if not already defined
    window.dataLayer = window.dataLayer || [];

    // Define gtag() function
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    // Load Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(script);

    // Call gtag() to initialize Google Analytics
    window.gtag("js", new Date());

    // Call gtag() to configure Google Analytics with the tracking ID
    window.gtag("config", trackingId);
  }

  startTrackerWithId(id: string) {
    gtag('config', id);
  }

  trackView(pageUrl: string, screenName: string) {
    gtag('event', 'page_view', {
      page_location: pageUrl,
      page_path: pageUrl,
      page_title: screenName
    });
  }

  // trackEvent(action: string, category: string, label?: string, value?: any) {
  //   gtag('event', action, {
  //     event_category: category,
  //     event_label: label,
  //     value: value
  //   });
  // }

  trackEvent(
    action: string,
    category: string,
    label?: string,
    value?: any,
    ...customParams: CustomParam[]
  ) {
    const eventData: Record<string, string | number | undefined> = {
      event_category: category,
      event_label: label,
      value: value,
    };


    customParams.forEach(({ key, value }) => {
      eventData[key] = value;
    });


    gtag('event', action, eventData);
  }

}
