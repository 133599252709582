<ion-content>
  <ion-row class="update-alert">
    <ion-col size="12">
      <div>
        <ion-img src="../../../../assets/img/daykart/app-update.png" />
      </div>
      <h2>{{modalData?.modalHeader}}</h2>
      <p class="description-text">{{modalData?.modalBody}}</p>
      <ion-button class="common-btn" (click)="updateFromPlaystore()">Update Now</ion-button>
    </ion-col>
  </ion-row>
</ion-content>
