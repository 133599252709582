<ion-content>
  <ion-row>
    <ion-col size="12" class="no-internet-img">
      <div class="ion-padding">
        <div class="wifi-img">
          <ion-img
            src="../../../assets/img/daykart/no-internet-WtlevUz89B.png"
          ></ion-img>
        </div>
        <div class="oops-img">
          <ion-img
            src="../../../assets/img/daykart/Oops!.png"
          ></ion-img>
        </div>
        <h4>No internet connection</h4>
        <p>
          Something went wrong. Please checking your
          internet connection. We’ll see you in a moment!
        </p>
        <ion-button
          class="common-btn"
          expand="block"
          (click)="retryConnection()"
          >Try Again</ion-button
        >
      </div>
    </ion-col>
  </ion-row>
</ion-content>
