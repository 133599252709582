import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CelebrationAnimationService {

  private confettiLoaded: boolean = false;

  constructor() {
    this.loadConfettiScript();
  }

  private loadConfettiScript() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js';
    script.onload = () => {
      this.confettiLoaded = true;
    };
    document.body.appendChild(script);
  }

  public triggerConfetti(
    particleCount: number = 100,
    spread: number = 70,
    originY: number = 0.6
  ) {
    if (this.confettiLoaded && (window as any).confetti) {
      (window as any).confetti({
        particleCount: particleCount,
        spread: spread,
        origin: { y: originY }
      });
    } else {
      console.error('Confetti script is not loaded yet.');
    }
  }

}
