<!-- ----header--section------ -->
<app-title-header [title]="'wallet'" [backButtonRoute]="returnUrl" [parentScreenName]="'My Wallet'"></app-title-header>
<!-- ------------end------------>
<ion-content class="ion-padding-horizontal">
  <ion-row class="wallet-balance">
    <ion-col size="1">
      <ion-icon name="wallet-outline"></ion-icon>
    </ion-col>
    <ion-col size="7" class="wallet-value">
      <h6>Available Balance:</h6>
    </ion-col>
    <ion-col size="4" class="wallet-value">
      <h2>&#8377; {{ walletBalance }}</h2>
    </ion-col>
  </ion-row>

  <ion-row class="wallet-recharge">
    <ion-col size="12">
      <h6>Recharge Wallet</h6>
      <ion-row class="wallet-input">
        <ion-col size="1" class="rupee-icon">
          <span>&#8377;</span>
        </ion-col>
        <ion-col size="11">
          <ion-input type="tel" placeholder="Enter Amount" [(ngModel)]="rechargeAmount" maxlength="6"></ion-input>
        </ion-col>
      </ion-row>
    </ion-col>
    <ion-col size="12">
      <div class="btn-section">
        <div>
          <ion-button size="small" [ngClass]="rechargeAmount === '100' ? 'active-btn' : 'inactive-btn'"
            (click)="setRechargeAmount('100')">&#8377; 100</ion-button>
        </div>
        <div>
          <ion-button size="small" [ngClass]="rechargeAmount === '200' ? 'active-btn' : 'inactive-btn'"
            (click)="setRechargeAmount('200')">&#8377; 200</ion-button>
        </div>
        <div>
          <ion-button size="small" [ngClass]="rechargeAmount === '500' ? 'active-btn' : 'inactive-btn'"
            (click)="setRechargeAmount('500')">&#8377; 500</ion-button>
        </div>
        <div>
          <ion-button size="small" [ngClass]="
              rechargeAmount === '1000' ? 'active-btn' : 'inactive-btn'
            " (click)="setRechargeAmount('1000')">&#8377; 1000</ion-button>
        </div>
      </div>
    </ion-col>
    <ion-col size="12">
      <ion-button [disabled]="rechargeAmount === ''" expand="block" class="common-btn" (click)="rechargeWallet()">Add
        Money
      </ion-button>
    </ion-col>
  </ion-row>

  <!-- ---------------------------------Wallet transaction list--------------------------------------- -->
   
  <ion-row *ngIf="transactionData?.length > 0">
    <ion-col size="12">
      <h5 class="wallet-title">Wallet Transactions</h5>
    </ion-col>
  </ion-row>

  <ng-container *ngIf="dataFound > 0">
    <ion-row [ngClass]="transaction?.transferType === 'credit' ? 'credit' : 'debit'"
      *ngFor="let transaction of transactionData">
      <ion-col size="2" class="icon-section">
        <ion-icon [name]="
            transaction?.transferType === 'credit'
              ? 'add-outline'
              : 'remove-outline'
          "></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h5>
          &#8377; {{ transaction?.amount }}
          {{ transaction?.transactionDescription }}
          <!-- {{ transaction?.transferType === "credit" ? (transaction?.creditType === 'wallet-recharge' ? 'Wallet Recharge' : 'Cashback Earned') : "Spent" }} -->
        </h5>
        <p>
          <!-- {{ transaction?.orderDetails?.items }} items -->
          <span *ngIf="transaction?.creditType !== 'wallet-recharge'">{{transaction?.orderDetails?.items + (transaction?.orderDetails?.items > 1 ? ' items ' : ' item ') + '&middot;'}} </span>

          <span *ngIf="transaction?.creditType !== 'wallet-recharge'"><span class="small-rupees-icon">&#8377;</span>
            {{ transaction?.orderDetails?.orderAmount  + '&middot;' }}
          </span>

          <span>{{ transaction?.createdAt | date }}</span>
        </p>
      </ion-col>
    </ion-row>
  </ng-container>

  <!-- <ng-container *ngIf="dataFound < 0">
    <ion-row class="no-balance">
      <ion-col size="12">
        <ion-img src="../../../../assets/img/daykart/wallet.png" alt="wallet" />
        <h5><b>Uh oh! It seems your wallet is empty</b></h5>
        <p>Time to Shop and Earn Cashback to fill it up!</p>
      </ion-col>
    </ion-row>
  </ng-container> -->

  <ion-infinite-scroll (ionInfinite)="loadNextTransactions($event)">
    <ion-infinite-scroll-content *ngIf="totalPages > page" [loadingText]="loadingText" loadingSpinner="bubbles">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<!-- <ion-footer *ngIf="dataFound < 0">
  <ion-row>
    <ion-col size="12">
      <ion-button expand="block" class="common-btn" routerLink="/"
        >Start Shopping Now
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer> -->