import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable}                                                              from '@angular/core';
import {catchError, map, Observable, throwError}                                 from 'rxjs';
import {AuthService}                                                             from '../auth.service';
import {ToastsService}                                                           from './toasts.service';
import {StoreService}                                                            from '../store.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private toastService: ToastsService,
    private storeService: StoreService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    // const storeId = this.storeService.storeId;
    const storeId = this.storeService.getStoreId();

    if (token && storeId) {
      const headers = {
        'authkey': token,
        'storeid': storeId,
        // Add this to work with ngRok so that no need to convert get request to post 
        // 'ngrok-skip-browser-warning': 'false'
      };

      req = req.clone({
        setHeaders: headers
      })
    }
    // else if (token) {
    //   const headers = {
    //     'authkey': token,
    //     // 'ngrok-skip-browser-warning': 'false' 
    //   };

    //   req = req.clone({
    //     setHeaders: headers
    //   })
    // }
    else if (token || storeId) {
      // Either token or storeId is available, send whichever is available
      const headers: any = {};
    
      if (token) {
        headers['authkey'] = token;
      }
    
      if (storeId) {
        headers['storeid'] = storeId;
      }
    
      // Optional ngrok header
      // headers['ngrok-skip-browser-warning'] = 'false';
    
      req = req.clone({
        setHeaders: headers
      });
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.error.tokenAuthorization === false) {
          this.authService.logout();
          this.toastService.showToast(error.error.message);
        }
        else if (error.status === 403 || error.status === 400) {
          this.authService.logout();
          this.toastService.showToast(error.error.message);
        }
        else if (error.status >= 500 && error.status < 600) {
          this.toastService.showToast('Server error occurred. Please try again later.');
        } else {
          this.toastService.showToast(error.error.message || 'An unexpected error occurred.');
        }

        return throwError(() => error)
      })
    );
  }
}