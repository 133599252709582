<!-- ----header--section------ -->
<!-- <app-header [showBackButton]="true" [backButtonRoute]="returnUrl" [showSearch]="false" [showCart]="false" [showNotification]="true"></app-header> -->
<app-title-header [subTitle]="cartProduct?.length > 0 ? '(' + cartProduct?.length + ')' : ''" [backButtonRoute]="returnUrl"
  [title]="'yourCart'" [parentScreenName]="'Cart page'"></app-title-header>
<!-- -----------end----------- -->
<ion-content [fullscreen]="true" class="common-bg-color ion-padding-horizontal">
  <div *ngIf="isLoaded && cartProductFound === 1">
    <ion-row>
      <ion-col size-xs="12" size-md="6" size-lg="6">
        <app-product-card *ngFor="let product of cartProduct" (messageEvent)="refreshCartList($event)"
          [cardType]="'cart'" [productData]="product" [showCartButtons]="true" [parentScreenName]="'Cart Page'"></app-product-card>
      </ion-col>
    </ion-row>

    <ion-row class="benefits-title">
      <ion-col size="12">
        <h5>Offers & Benefits</h5>
      </ion-col>
    </ion-row>

    <ion-row class="coupon-section">
      <ion-col size="2" class="mid-center">
        <ion-icon name="ticket-outline"></ion-icon>
      </ion-col>
      <ion-col size="7">
        <ion-input [(ngModel)]="couponInput" (ionInput)="onCouponInputChange($event)"
          placeholder="Enter Coupon Code" [readonly]="isCouponApplied" maxlength="15"></ion-input>
      </ion-col>
      <ion-col size="3" class="mid-center">

        <!-- <span *ngIf="!isCouponApplied; else couponApplied" class="apply-coupon" (click)="applyCoupon()">APPLY</span>
        <ng-template #couponApplied>
          <span class="remove-coupon" (click)="removeCoupon()">Remove</span>
        </ng-template> -->

        <ng-container *ngIf="!isCouponApplied; else couponApplied">
          <ion-spinner *ngIf="isCouponAddedorRemoved" name="crescent"></ion-spinner>
          <span *ngIf="!isCouponAddedorRemoved" class="apply-coupon" (click)="applyCoupon()">APPLY</span>
        </ng-container>
        <ng-template #couponApplied>
          <ion-spinner *ngIf="isCouponAddedorRemoved" name="crescent"></ion-spinner>
          <span *ngIf="!isCouponAddedorRemoved" class="remove-coupon" (click)="removeCoupon()">Remove</span>
        </ng-template>

      </ion-col>
    </ion-row>

    <ion-row class="offer-section">
      <ion-col size="2">
        <ion-img src="../../../assets/img/daykart/cash-back-icon.png" />
      </ion-col>
      <ion-col class="cashback-text" [size]="cashbackFound ? 7 : 10">
        <p [innerHTML]="cashbackData?.message"></p>
        <!-- <small> Shop more to earn more! </small> -->
      </ion-col>
      <ion-col size="3" class="cash-back-price" *ngIf="cashbackFound">
        <h5>&#8377; {{cashbackData?.cashbackAmount}}</h5>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-xs="12" size-md="6" size-lg="6">
        <div class="bill-details title-list">
          <ion-row>
            <ion-col size="12">
              <h3><b>Bill details</b></h3>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4">
              <p class="text-gray-color">Subtotal</p>
            </ion-col>
            <ion-col size="8" class="ion-text-end">
              <p>
                <span><del>&#8377; {{ billingData?.totalOriginalPrice }}</del></span>
                <span> &#8377; {{ billingData?.totalDiscountedPrice }}</span>
              </p>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="8">
              <p class="text-gray-color">Instant Delivery fee<span></span></p>
            </ion-col>
            <ion-col size="4" class="ion-text-end">
              <p>
                <del>&#8377; 50</del>
                <span [ngStyle]="{
                    color: billingData?.deliveryCharge === 0 ? '#40A2E3' : ''
                  }">{{
                  billingData?.deliveryCharge === 0
                  ? " FREE"
                  : " &#8377;" + billingData?.deliveryCharge
                  }}</span>
              </p>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="isCouponApplied">
            <ion-col size="8" class="ion-align-items-center">
              <p class="text-gray-color">
                Coupon Discount
            </ion-col>
            <ion-col size="4" class="ion-text-end">
              <p>-&#8377; {{ billingData?.couponDiscount }}</p>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="8" class="ion-align-items-center">
              <p class="text-gray-color">
                Wallet Amount<ion-icon class="info-icon" name="information-circle-outline"
                  (click)="presentBalancePopover($event)"></ion-icon><span></span>
              </p>
            </ion-col>
            <ion-col size="4" class="ion-text-end">
              <p>-&#8377; {{ billingData?.walletAmountDeducted }}</p>
            </ion-col>
          </ion-row>          

          <!-- <ion-row>
            <ion-col size="7">
              <h5>Your total savings</h5>
            </ion-col>
            <ion-col size="5" class="ion-text-end">
              <h5>&#8377; {{ billingData?.totalSavings }}</h5>
            </ion-col>
          </ion-row> -->

          <ion-row>
            <ion-col size="6">
              <h5>Total</h5>
            </ion-col>
            <ion-col size="6" class="ion-text-end">
              <h5>
                <b>&#8377; {{ billingData?.grandTotal }}</b>
              </h5>
            </ion-col>
          </ion-row>
        </div>
      </ion-col>
    </ion-row>

    <!-- <ion-row class="user-address-section">
      <ion-col size="2">
        <ion-icon name="location-outline"></ion-icon>
      </ion-col>
      <ion-col size="8">
        <p>Delivery Address</p>
        <small> Plot No. 1, Kaushambi, Ghaziabad, Uttar Pradesh </small>
      </ion-col>
      <ion-col size="2" class="address-change">
        <small color="danger">change</small>
      </ion-col>
    </ion-row> -->

    <!-- ---common--title---list----code-- -->
    <!-- <div class="title-list bill-details">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4>Cancellation Policy</h4>
          </ion-col>

          <ion-col size="12">
            <p>
              Order cannot cancelled once packed for delivery.In case of
              unexpected delays,a refund will be provided,if applicable.
            </p>
          </ion-col>
          <ion-col size="12" class="ion-text-end">
            <a href="#">Learn More</a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div> -->
  </div>

  <!-- ------------------------Empty cart-------------------------------------- -->
  <ion-row class="empty-cart ion-margin-top ion-padding" *ngIf="isLoaded && cartProductFound < 0">
    <ion-col size="12" class="ion-margin-top">
      <ion-img src="../../../assets/img/daykart/cart-img.png" alt="cart" />
      <h4><b>Your Kart is Empty!</b></h4>
      <p>
        Empty cart, endless possibilities! Start shopping now to create your
        perfect order.
      </p>
    </ion-col>
    <ion-col size="12" class="ion-margin-top">
      <ion-button expand="block" class="common-btn" routerLink="/">Back to Home
      </ion-button>
    </ion-col>
  </ion-row>

  <app-cart-skeleton *ngIf="!isLoaded && cartProductFound === 0"></app-cart-skeleton>
  <!-- ------end--------- -->
  <!-- ---------------end---------------- -->
</ion-content>
<div class="items-bottom" *ngIf="isLoaded && cartProductFound === 1">
  <app-address-footer [addressSelected]="isAddressSelected" [addressData]="selectedAddress" [billingData]="billingData"
    [storeTimingData]="storeTimingData" (addressMessageEvent)="refreshCartList($event)"></app-address-footer>
</div>