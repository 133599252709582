import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CardListComponent } from '../card-list/card-list.component';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  cartCount: number = 0;
  imageForDisplay: any;

  constructor(private modalController: ModalController,
    private cartService: CartService,
    private productService:ProductService,
    public authService:AuthService,
    private router: Router
  ) { }
  
  ngOnInit() {
    this.cartService.cartSubject$.subscribe((cartData: any) => {
      if (cartData.length === 0) {
        this.cartCount = 0;
      }
      else {
        this.cartCount = cartData.cartProducts.length;
        this.imageForDisplay = cartData.cartProducts[0]?.images[0];
      }
    })
  }

  async openCartModal() {
    let modal = await this.modalController.create({
      component: CardListComponent,
      breakpoints:[0,1],
      initialBreakpoint:1,
      cssClass: 'card-item-modal',
      backdropDismiss: true,
    })

    return await modal.present();
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  async navigateToCart(){
    const modal = await this.modalController.getTop()
    if (modal) {
      modal.dismiss()
    }
    const currentUrl = this.router.url;
    this.router.navigate(['/cart'], {queryParams:{returnUrl:currentUrl}})
  }
}
