import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-daykart-policy',
  templateUrl: './daykart-policy.component.html',
  styleUrls: ['./daykart-policy.component.scss'],
})
export class DaykartPolicyComponent  implements OnInit {

  returnUrl:string = '';

  constructor(private authService:AuthService) { }

  ngOnInit() {
    this.returnUrl =  this.authService.navigateBackFromPublicPages();
  }

}
