import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RequestProductComponent } from 'src/app/layout/request-product/request-product.component';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';

@Component({
  selector: 'app-product-not-found',
  templateUrl: './product-not-found.component.html',
  styleUrls: ['./product-not-found.component.scss'],
})
export class ProductNotFoundComponent  implements OnInit {

  @Input() searchedValue!:string;
  @Output() messageEvent = new EventEmitter<boolean>();


  constructor(private modalController:ModalController,private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {
  }

  async requestProduct() {
    this.mixpanelService.track("b_send_request", { "screenName" : "Search Page"});

    let modal = await this.modalController.create({
      component: RequestProductComponent,
      cssClass: 'request-product-modal',
      componentProps: {
        productName: this.searchedValue
      },
      backdropDismiss: true,
    })

    modal.onDidDismiss().then((data) => {
      if (data?.data?.status) {
        this.messageEvent.emit(true)
      }
    })

    return await modal.present();
  }

}
