import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreNotFoundComponent } from '../shared/home/store-not-found/store-not-found.component';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  storeSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private modalController:ModalController) {
    if (!this.storeId) {
      this.storeId = localStorage.getItem('storeId');
    }
    this.storeSubject.subscribe(storeId => {
      if (storeId) {
        localStorage.setItem('storeId', storeId);
      }
    })
  }

  get isStore() {
    return this.storeId ? true : false;
  }

  get storeId() {
    return this.storeSubject.value;
  }

  set storeId(storeId: string | null) {
    this.storeSubject.next(storeId);
    // return localStorage.getItem('storeId');
  }

  getStoreId() {
    return localStorage.getItem('storeId');
  }

  storeList() {
    return this.http.get(`${environment.apiUrl}/store/list`);
  }

  nearestStore(body:any){
    return this.http.post(`${environment.apiUrl}/store/near`,body);
  }

  storeHelplineHours(){
    return this.http.get(`${environment.apiUrl}/helpline`);
  }

  saveStoreIdAndDistance(storeId:string,distance:string){
    localStorage.setItem('storeId', storeId);
    localStorage.setItem('storeDistance', distance)
  }

  getStoreIdAndDistance(){
    const storeId = localStorage.getItem('storeId')
    const storeDistance = localStorage.getItem('storeDistance')

    const data = {
      storeId : storeId,
      distance : storeDistance
    }

    return data;

  }

  walletTransactions(page:number=1,limit:number=10){
    return this.http.get(`${environment.apiUrl}/wallet?page=${page}&limit=${limit}`);
  }

  get StoreContactNo(){
    return localStorage.getItem('storeContactNo')
  }
  

  

}
