import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { BuyAtStoreComponent } from 'src/app/extra/modals/buy-at-store/buy-at-store.component';
import { LocationPermissionService } from 'src/app/services/extensions/location-permission.service';
import { LocationService } from 'src/app/services/location.service';
import { StoreService } from 'src/app/services/store.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-store-not-found',
  templateUrl: './store-not-found.component.html',
  styleUrls: ['./store-not-found.component.scss'],
})
export class StoreNotFoundComponent implements OnInit, ViewWillEnter {

  @Input() placeName: string = '';
  @Input() sectionType: string = 'search'

  selectedAddress: string = '';
  showProgressBar: boolean = false;

  @Input() isStoreAvailable: number = 2;

  constructor(private router: Router,
    private modalController: ModalController,
    private toastService: ToastService,
    private locationService: LocationService,
    private storeService: StoreService,
    private permissionService: LocationPermissionService
  ) { }

  ngOnInit() { }

  ionViewWillEnter() {
    this.selectedAddress = 'Noida'
  }

  get currentAddress(){
    return localStorage.getItem('currentAddress')
  }
  
  async useCurrentLocation() {
    this.showProgressBar = true;
    this.resetSearchField();
    // this.locationService.requestPermissions();
    // this.checkStoreAvailabilityOnCurrentLocation();


    const isLocationAllowed = await this.permissionService.checkIfLocationAllowed();

    console.log('is location permission allowed', isLocationAllowed)

    if(isLocationAllowed){
      const currentAddress = await this.permissionService.findMyCurrentLocation();
      this.findCurrentAddress(currentAddress)
      // console.log(currentAddress)
    }
    else{
      this.showProgressBar = false;
        const permissionGranted = await this.permissionService.askForLocationPermission();
        console.log(permissionGranted)
        if(permissionGranted){
          this.useCurrentLocation();
        }
        
    }

  }

  checkStoreAvailabilityOnCurrentLocation() {
    this.locationService.coordinate.subscribe((location: any) => {
      if (location) {
        this.findCurrentAddress(location);
      }
      else {
        this.locationService.requestPermissions();
      }
    });
  }

  findCurrentAddress(location: any) {
    const point = { lat: location?.latitude, lng: location?.longitude };
    this.locationService.pointLocationAddress(point).subscribe(async (res: any) => {

      if (!res?.isStoreAvailable) {
        this.showProgressBar = false;
        this.isStoreAvailable = 0;
        this.placeName = res?.address;
        // this.selectNewLocation(res?.isStoreAvailable, res?.address)
        // this.toastService.showToast('Daykart services is not available at your location')
      }
      else {
        this.showProgressBar = false;
        const isModal = await this.modalController.getTop();
        if (isModal) {
          isModal.dismiss('', 'close')
        }
        this.locationService.setLocation({ latitude: location?.latitude, longitude: location?.longitude, accuracy: 100 })

        this.isStoreAvailable = 1;

        localStorage.setItem('storeDistance', res?.distance);
        localStorage.setItem('storeId', res?.nearestStore?.storeId);
        localStorage.setItem('storeContactNo', res?.nearestStore?.mobile);
        localStorage.setItem('currentAddress', res?.address);
        localStorage.setItem('estimatedDeliveryTime', res?.time)
      }

    });
  }

  dismiss() {
    this.modalController.dismiss('', 'close');
  }

  // ------------------Location search----------------------------------

  searchInput: any = '';
  dataFound: number = 2;
  searchSuggestions: any;


  searchLocation() {
    if (this.searchInput === '') {
      this.searchSuggestions = [];
      return;
    }
    this.locationService.searchPlace({ input: this.searchInput }).subscribe((res: any) => {
      if (res.success) {
        this.searchSuggestions = res.data.predictions;
        if (this.searchSuggestions.length === 0) {
          this.dataFound = 0;
        }
        else {
          this.dataFound = 1;
        }
      }
    },
      (error) => {
        this.toastService.showToast(error?.message)
      })

  }

  selectAddress(prediction: any) {
    this.resetSearchField();
    this.showProgressBar = true;
    this.newLocation(prediction.place_id);
  }

  newLocation(placeId: string) {
    this.locationService.placeDetails({ placeId: placeId }).subscribe((res: any) => {
      if (res.success) {
        this.showProgressBar = false;
        this.isStoreAvailable = 1;
        // this.storeService.saveStoreIdAndDistance(res?.nearestStore?.storeId,res?.distance);
        localStorage.setItem('storeDistance', res?.distance);
        localStorage.setItem('storeId', res?.nearestStore?.storeId);
        localStorage.setItem('currentAddress', res?.address);
        localStorage.setItem('estimatedDeliveryTime', res?.time);
        localStorage.setItem('storeContactNo', res?.nearestStore?.mobile);
        this.locationService.setLocation({ latitude: res?.location?.lat, longitude: res?.location?.lng, accuracy: 100 })


        this.dismiss();
      }
      else {
        this.showProgressBar = false;
        this.isStoreAvailable = 0;
        this.placeName = res?.address;
      }
    },
      (error) => {
        this.toastService.showToast(error?.message);
        this.showProgressBar = false;
      })
  }

  resetSearchField() {
    this.searchInput = '';
    this.searchSuggestions = [];
    this.dataFound = 2;
  }

  async openStoresModal() {
    let modal = await this.modalController.create({
      component: BuyAtStoreComponent,
      cssClass: 'buyatStore-modal',
      backdropDismiss: true,
      componentProps: {
        parentScreenName: this.sectionType === 'message' ? "Add address page": "Home page location select popup"
      }
    })

    return await modal.present();
  }

}
