import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, MenuController, PopoverController, AlertController } from '@ionic/angular';
import { PopoverMenuComponent } from 'src/app/layout/popover-menu/popover-menu.component';
import { AddressService } from 'src/app/services/address.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
})
export class AddressBookComponent  implements OnInit {
  defaultAddressId: string = '';
  addressFound: boolean = false;
  addressData: any;
  // @Input() sendData!: (childData: any) => void;
  
  constructor(private modalController: ModalController,
    private router: Router,
    private addressService: AddressService,
    private menu: MenuController,
    public popoverController: PopoverController,
    private alertController: AlertController,
    private toastService: ToastService
  ) { }


  ionViewWillEnter() {
    this.addressList();
  }

  ngOnInit() {
  }

  addressList() {
    this.addressService.addressList().subscribe((res: any) => {
      if (res?.success) {
        this.addressFound = true;
        this.addressData = res?.addressList;
        this.defaultAddressId = this.addressData.find((obj: any) => obj.isDefault)?._id || null;
      }
      else {
        this.addressFound = false;
        this.addressData = [];
      }
    })
  }

  checkAddressType(type: string) {
    switch (type) {
      case 'home':
        return '../../assets/img/daykart/Home Icon.svg'
      case 'office':
        return '../../assets/img/daykart/Office Icon.svg'
      case 'hotel':
        return '../../assets/img/daykart/hotel.png'
      default:
        return '../../assets/img/daykart/other-location.png'
    }
  }

  navigateToAddAddress() {
    this.router.navigate(['/locate-address'],{ queryParams: { returnUrl: '/account/address-book' } })
  }

  async presentPopover(e: Event, id: any, addressData: any) {
    const popover = await this.popoverController.create({
      component: PopoverMenuComponent,
      event: e,
      componentProps: {
        addressId: id,
        data: addressData
      }
    });

    await popover.present();

    const { role, data } = await popover.onDidDismiss();
    if (data) {
      this.addressList();
    }
  }

  goBackToAccount(){
    this.router.navigate(['account'])
  }

    // -----------------------------------New Ui--------------------------------------------


  
    editAddress(addressId: string) {
      // this.modalController.dismiss();
      this.router.navigate(['locate-address'], { queryParams: { addressId: addressId, returnUrl: '/account/address-book'} })
    }
  
    async openDeleteAddressAlert(addressId: string) {
  
      const alert = await this.alertController.create({
        header: 'Confirm Delete',
        message: 'Are you sure you want to delete this address?',
        buttons: [
          {
            text: 'Cancel',
            cssClass: 'primary',
            role: 'cancel',
            handler: () => {
            },
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: () => {
              this.addressService.deleteAddress(addressId).subscribe((res: any) => {
                if (res?.success) {
                  this.toastService.showToast(res?.message);
                  this.addressList();
                  // this.sendData({
                  //   refreshData: true
                  // });
                  alert.dismiss();
                }
                else {
                  this.toastService.showToast(res?.message)
                  alert.dismiss();
                }
              })
            },
          },
        ],
      });
      await alert.present();
    }

    makeDefaultAddress(addressId:string) {   
      if(addressId === this.defaultAddressId){
        this.toastService.showToast("Already default address")
      }
      else{
        this.addressService.setDefaultAddress({ addressId: addressId }).subscribe((res: any) => {
          if (res?.success) {
            this.addressList();
            this.toastService.showToast(res?.message);
          }
          else {
            this.toastService.showToast(res?.message);
          }
        })
      }   
      
  
    }
  }
