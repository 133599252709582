import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss'],
})
export class SwitchLanguageComponent implements OnInit {

  constructor(private modalController: ModalController,
    private translate: TranslateService,
    private authService: AuthService
  ) { }

  defaultlanguage: any;

  languageOptions = [
    {
      name: 'English',
      value: 'en',
      isChecked: false,
      icon: '../../../../assets/img/daykart/en.png'
    },
    {
      name: 'Hindi',
      value: 'hi',
      isChecked: false,
      icon: '../../../../assets/img/daykart/hi.png'
    },

  ]

  ngOnInit() {
    this.defaultlanguage = this.authService.appLanguage;
    this.setDefaultLanguage();
  }

  changeLanguage(index: any) {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if (i === index) {
        this.defaultlanguage = this.languageOptions[i].value;
        this.languageOptions[i].isChecked = true;
      }
      else {
        this.languageOptions[i].isChecked = false;
      }
    }
  }

  setDefaultLanguage() {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if (this.languageOptions[i].value === this.defaultlanguage) {
        this.languageOptions[i].isChecked = true;
        break
      }
    }
  }

  languageChanged() {
    this.modalController.dismiss();
    this.authService.appLanguage = this.defaultlanguage;
    this.translate.use(this.defaultlanguage);
  }

}
