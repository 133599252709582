import { Component, OnInit } from '@angular/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { LocationPermissionService } from 'src/app/services/extensions/location-permission.service';

@Component({
  selector: 'app-location-permission-modal',
  templateUrl: './location-permission-modal.component.html',
  styleUrls: ['./location-permission-modal.component.scss'],
})
export class LocationPermissionModalComponent  implements OnInit, ViewWillEnter {

  title:string = '';
  message:string = '';

  permissionDenialType:any='';
  isCordova!:any;

  constructor(private modalController:ModalController,
    private locationPermissionService: LocationPermissionService,
    private diagnostic: Diagnostic
  ) { }
  
  ionViewWillEnter() {
    // this.checkForPermissionDenial();
  }

  ngOnInit() {
    this.isCordova = localStorage.getItem('isCordova');
    this.title = "Location Access Needed";
    // this.message = "Location access is permanently denied. Please go to settings and enable location permissions for the app to work correctly"

    this.checkForPermissionDenial()
  }

  async checkForPermissionDenial(){
    this.permissionDenialType = await this.locationPermissionService.checkLocationPermissionStatus();
    if(this.permissionDenialType === "denied_once"){
      this.message = "To provide you with the best delivery experience and accurate delivery times, we need access to your location."
      // alert(this.permissionDenialType)
    }
    else if(this.permissionDenialType === "denied_always"){
      this.message = "Location access is needed for accurate delivery options. Please go to settings and enable location access."
      // alert(this.permissionDenialType)

    }
    else{
      this.message = "If you have allowed location permission from phone settings. Click on try again"
      await this.modalController.dismiss('granted');
    }
  }

  requestPermission() {
    // Close the modal and trigger permission request again
    this.modalController.dismiss('requestPermission');
  }

  async openSettings() {
    // Close the modal and prompt user to open app settings
      try {
        if (this.isCordova === 'native') {
          await this.modalController.dismiss('openSettings');
          await this.diagnostic.switchToSettings();
        } else {
          console.log('This feature is not available on the browser.');
        }
      } catch (error) {
        console.error('Error opening app settings:', error);
      }
  }

  openLocationSettings(){
    this.locationPermissionService.openLocationSettings()

  }

  checkPermissionAgain(){
    this.modalController.dismiss('granted');
  }

}
