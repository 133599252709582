import {Injectable}              from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, timeout} from 'rxjs';
import {catchError}              from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
	            providedIn: 'root',
            })
/**
 * Using params instead of query string
 * @author Gaurav Chandra
 *
 */
export class ApiService {
  public readonly sub_api_url: string = environment.apiUrl + '/subscription/';//this has trailing slash
	//add a trailing slash for all the api calls
	public apiEndpoint = {
		ping: this.sub_api_url + 'ping/',
		getProducts: this.sub_api_url + 'products/',
		getSubscribed: this.sub_api_url + 'subscribed/',//add mobile number
		getProductById: this.sub_api_url + 'product/',
		startSubscription: this.sub_api_url + 'subscribe/',
		stopSubscription: this.sub_api_url + 'subscribe/',//delete with _id
		pauseRestartSubscription: this.sub_api_url + 'subscribe/',//put with _id
		editSubscription: this.sub_api_url + 'subscribe/',//put with _id
		getWalletBalance: this.sub_api_url + 'wallet/',//get with mobile number
	};
	constructor(private http: HttpClient) {
	}

  /* Token interceptor adds token and store id to headers */
	public get(url: string, headers?: {
		[key: string]: string
	}): Observable<any> {
		let requestHeaders: HttpHeaders = new HttpHeaders();
		for (const key in headers) {
			if (headers.hasOwnProperty(key)) {
				requestHeaders = requestHeaders.set(key, headers[key]);
			}
		}
		return this.http.get<any>(url, {
			headers: requestHeaders,
		}).pipe(timeout(10000),
		        /* tap(_ => console.log(`fetched url=${url}`)), */
		        catchError(this.handleError<any>('get: url', [])),
		);
	}

  public post(url: string, body: any, headers?: {
		[key: string]: string
	}): Observable<any> {
		let requestHeaders = new HttpHeaders();
		for (const key in headers) {
			if (headers.hasOwnProperty(key)) {
				requestHeaders = requestHeaders.set(key, headers[key]);
			}
		}
		return this.http.post<any>(url, body, {
			headers: requestHeaders,
		}).pipe(timeout(10000),
		        /* tap(_ => console.log(`post url=${url}`)), */
		        catchError(this.handleError<any>('post: url', {status: false})),
		);
	}

  public put(url: string, body: any, headers?: {
		[key: string]: string
	}): Observable<any> {
		let requestHeaders = new HttpHeaders();
		for (const key in headers) {
			if (headers.hasOwnProperty(key)) {
				requestHeaders = requestHeaders.set(key, headers[key]);
			}
		}
		return this.http.put<any>(url, body, {
			headers: requestHeaders,
		}).pipe(timeout(10000),
		        /* tap(_ => console.log(`put url=${url}`)), */
		        catchError(this.handleError<any>('put: url', {status: false})),
		);
	}

  public delete(url: string, headers?: {
		[key: string]: string
	}): Observable<any> {
		let requestHeaders = new HttpHeaders();
		for (const key in headers) {
			if (headers.hasOwnProperty(key)) {
				requestHeaders = requestHeaders.set(key, headers[key]);
			}
		}
		return this.http.delete<any>(url, {
			headers: requestHeaders,
		}).pipe(timeout(10000),
		        /* tap(_ => console.log(`put url=${url}`)), */
		        catchError(this.handleError<any>('delete: url', {status: false})),
		);
	}

  /**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 *
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation: string = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.error('handleError', error); // log to console instead
			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
