<app-modal-header [title]="'Brand'"></app-modal-header>

<ion-content class="ion-padding">
  <ng-container *ngIf="brandData.length>0">
  <form [formGroup]="filterForm">
      <div class="ion-margin-bottom" *ngFor="let filter of brandData; let i = index">
        <ion-checkbox labelPlacement="end" [formControlName]="'checkbox' + i">{{ filter?.categoryName | titlecase }} <span class="product-count">{{'('+filter?.products+')'}}</span></ion-checkbox>
      </div>
  </form>
</ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar class="custom-toolbar">
    <ion-row class="bottom-btn">
      <ion-col size="6" class="clearFilter">
        <ion-button expand="block" id="clearFilterButton" disabled class="custom-outline-btn" (click)="clearFilter()">Clear Filters </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button expand="block" class="common-btn" (click)="applyFilter()">Apply </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>