<!-- -----------header------------ -->
<app-title-header [title]="'address_book'" [backButtonRoute]="'/account'" [parentScreenName]="'Address book'">
</app-title-header>
<!-- --------------end-------------- -->
<!-- --------------address-book----page--------- -->
<!-- <ion-content [fullscreen]="true" class="ion-padding-start ion-padding-end">
  <ion-grid class="ion-margin-top">
    <ion-row
      (click)="navigateToAddAddress()"
      class="product-summary-1 back-color"
    >
      <ion-col size="1">
        <ion-icon name="add" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h4>{{ "add_new_address" | translate }}</h4>
      </ion-col>
      <ion-col size="1">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>

    <div *ngIf="addressFound">
      <ion-row>
        <ion-col>
          <p class="text-gray">{{ "your_saved_addresses" | translate }}</p>
        </ion-col>
      </ion-row>

      <ion-row
        class="address-section back-color ion-margin-bottom"
        *ngFor="let address of addressData"
      >
        <ion-col size="2">
          <img src="{{ checkAddressType(address?.addressType) }}" alt="" />
        </ion-col>
        <ion-col size="9">
          <h4>
            {{ address?.receiverName | titlecase }}
            <ion-chip
              color="tertiary"
              [outline]="true"
              *ngIf="address?.isDefault"
            >
              <ion-icon name="checkmark-circle"></ion-icon>
              <ion-label>Default</ion-label>
            </ion-chip>
          </h4>
          <p class="user-address">{{ address?.completeAddress }}</p>
          <p class="text-gray google-address">
            {{ address?.googleAddress }}
          </p>
        </ion-col>
        <ion-col
          size="1"
          (click)="presentPopover($event, address?._id, address)"
        >
          <ion-icon name="ellipsis-vertical" class="text-gray"></ion-icon>
        </ion-col>
      </ion-row>
    </div>

    <div class="address-card" *ngIf="!addressFound">
      <p>{{ "no_address_saved_yet" | translate }}</p>
    </div>
  </ion-grid>
</ion-content> -->
<!-- -----------------------end----------------------- -->

<!-- <ion-content>
  <ion-row class="select-address">
    <ion-col size="12">
      <h2>
        {{ "select_address" | translate }}
      </h2>
    </ion-col>
    <ion-col size="8">
      <h5>Your Saved Addresses</h5>
    </ion-col>
    <ion-col size="4" class="ion-text-end">
      <ion-button (click)="navigateToAddAddress()" expand="block" class="add-address-btn">Add New +</ion-button>
    </ion-col>

    <ion-col size="12">
      <ion-radio-group>
        <ng-container>
          <ion-row class="border-section ion-margin-bottom">
            <ion-col size="1" class="radio">
              <ion-radio labelPlacement="end"> </ion-radio>
            </ion-col>
            <ion-col size="8">
              <p>Home</p>
              <p class="user-address">
                Plot No. 1, Kaushambi, Ghaziabad, Uttar Pradesh
              </p>
            </ion-col>
            <ion-col size="3" class="address-update">
              <span>Edit</span>
              <ion-icon name="trash-outline"></ion-icon>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-radio-group>
    </ion-col>
  </ion-row>
</ion-content> -->

<ion-content>
  <ion-row class="select-address">
    <!-- <ion-col size="12">
      <h2>
        {{ "select_address" | translate }}
      </h2>
    </ion-col> -->
    <ion-col size="8">
      <h5>{{ "your_saved_addresses" | translate }}</h5>
    </ion-col>
    <ion-col size="4" class="ion-text-end">
      <ion-button (click)="navigateToAddAddress()" expand="block" class="add-address-btn">{{ "add_new" | translate }}+</ion-button>
    </ion-col>

    <ion-col size="12">
      <!-- <ion-radio-group [value]="defaultAddressId" (ionChange)="func()"> -->
        <ng-container>
          <ion-row class="border-section ion-margin-bottom selected-address unselected-address" *ngFor="let address of addressData">
            <ion-col size="1" class="radio" (click)="makeDefaultAddress(address?._id)">
              <!-- <ion-radio labelPlacement="end" [value]="address?._id"> </ion-radio> -->
              <ion-icon [name]="address?.isDefault ? 'radio-button-on-outline':'radio-button-off-outline'"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <p>{{address?.addressType | titlecase}}</p>
              <p class="user-address">
                {{address?.completeAddress+' '+address?.googleAddress}}
              </p>
            </ion-col>
            <ion-col size="3" class="address-update">
              <span (click)="editAddress(address?._id); $event.stopPropagation()">Edit</span>
              <ion-icon name="trash-outline" (click)="openDeleteAddressAlert(address?._id);$event.stopPropagation()"></ion-icon>
            </ion-col>
          </ion-row>
        </ng-container>
      <!-- </ion-radio-group> -->
    </ion-col>
  </ion-row>
</ion-content>