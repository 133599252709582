<div class="list-category-card outline-shadow mid-auto ion-activatable ripple-parent rounded-rectangle" (click)="navigateToSubCategoryProducts(categoryData)">
  <div class="img-section pointer">
    <ion-img
      src="{{ categoryData.categoryImage[0] }}"
      alt="category-img"
      (ionError)="loadDefaultImg($event)"
    />
    <!-- <div class="item-discount"><small>10% OFF</small></div> -->
  </div>

  <div class="item-details">
    <div class="product-title">
      <h6 class="overflow-control">
        {{ categoryData?.categoryName | titlecase }}
      </h6>
      <p></p>
    </div>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</div>
