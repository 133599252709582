import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IonicModule}                                                                                       from '@ionic/angular';

@Component({
	           selector: 'daykart-outline-button',
	           templateUrl: './outline-button.component.html',
	           styleUrls: ['../../../shared.scss', './outline-button.component.scss'],
	           changeDetection: ChangeDetectionStrategy.OnPush,
	           imports: [
		           IonicModule,
	           ],
	           standalone: true,
           })
export class OutlineButtonComponent implements OnInit, OnChanges {
	@Input('block') block: boolean = false;
	@Input('big-button') big_button: boolean = false;
	@Input('click-data') click_data: any = '';
	@Input('text') text: string = 'Subscribe';
	@Output('button-clicked') button_clicked: EventEmitter<any> = new EventEmitter<any>();
	@Input('no-shadow') noShadow: boolean = false;
	@Input('red-border') redBorder: boolean = false;
  @Input('min-width') minWidth: boolean = true;
  @Input('text-class') textClass: string = 'fw-bold';
  @Input('smaller-button') smallerButton: boolean = false;
	constructor() {
	}

  ngOnInit() {
	}

  public ngOnChanges(changes: SimpleChanges): void {
		if (changes['block']) {
			this.block = changes['block'].currentValue;
		}
	}

  public buttonClicked(click_data: any): void {
		this.button_clicked.emit(click_data);
	}
}
