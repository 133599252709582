<!-- ----header--section------ -->
<ion-header [translucent]="true">
  <div class="header-title ion-padding-top">
    <ion-grid>
      <ion-row>
        <ion-col size="1" [routerLink]="['/cart']">
          <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <h4 class="location">Address</h4>
          <p class="address">My Address</p>
        </ion-col>
        
      </ion-row>
    </ion-grid>
  </div>

</ion-header>
<!-- -------end------------>
<ion-content [fullscreen]="true">
  <ion-grid>
    <ion-row class="product-summary-1 back-color" [routerLink]="['/add-address']">
      <ion-col size="1">
        <ion-icon name="add" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
       <h4>Add New Address</h4>
      </ion-col>
      <ion-col size="1">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <p class="text-gray">Your Saved Address</p>
      </ion-col>
    </ion-row>

    <ion-row class="address-section back-color">
      <ion-col size="2">      
        <img src="../../assets/img/daykart/Office Icon.svg" alt="">
      </ion-col>
      <ion-col size="9">
       <h4>Office <span class="distance">15.45 km away</span></h4>
       <p class="text-gray">i-thum tower sector-62, plot no-40, AlgoGenix Pvt.tower C-404 Noida,201301</p>
      </ion-col>
      <ion-col size="1">
        <ion-icon name="ellipsis-vertical" class="text-gray"></ion-icon>
      </ion-col>
    </ion-row>

    <!-- <ion-row class="address-section back-color ion-margin-top">
      <ion-col size="2">      
        <img src="../../assets/img/daykart/Home Icon.svg" alt="">
      </ion-col>
      <ion-col size="9">
       <h4>Home <span class="distance">10:05 km away</span></h4>
       <p class="text-gray">Amarpali Empire,Crossing Road,Abets College,Ghaziabad,201301</p>
      </ion-col>
      <ion-col size="1">
        <ion-icon name="ellipsis-vertical" class="text-gray"></ion-icon>
      </ion-col>
    </ion-row> -->
   
     </ion-grid>
</ion-content>
