<!-- ----header--section------ -->
<ion-header [translucent]="true">
  <div class="header-title ion-padding-top">
    <ion-grid>
      <ion-row>
        <ion-col size="1" [routerLink]="['/']">
          <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <h4 class="location">Add Address</h4>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-searchbar
          class="search-bar"
          placeholder="Search here"
          [(ngModel)]="searchInput"
          (ionInput)="searchLocation()"
          (ionClear)="resetSearchField()"
          [debounce]="800"
        ></ion-searchbar>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="address-list">
    <div *ngFor="let prediction of searchSuggestions">
      <p (click)="selectAddress(prediction)">{{ prediction?.description }}</p>
    </div>
  </div>
  <p *ngIf="dataFound === 0 && searchInput != ''" class="no-data-found">
    No Match Found...
  </p>
</ion-header>
<!-- -------end---------- -->
<ion-content [fullscreen]="true">
  <ion-row>
    <ion-col size="12">
      <div #map style="width: 100%; height: 55vh"></div>
    </ion-col>
  </ion-row>
  <ion-grid>
    <ion-row class="address-section back-color ion-margin-top">
      <ion-col size="1">
        <ion-img src="../../../assets/img/daykart/location.png" alt="" />
      </ion-col>
      <ion-col size="11">
        <h4 class="block-section">
          {{ addressPlace }}
          <span class="distance">{{ storeDistance }} km away</span>
        </h4>
        <p class="text-gray block-section">{{ address }}</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<!-- ----------footer-------------- -->
<ion-footer>
  <ion-row>
    <ion-col size="12" (click)="completeAddress()">
      <ion-button class="common-btn" expand="block">
        Enter Complete Address
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
<!-- ------------end----------------- -->
