
    <ion-row>
      <ion-col
        size-xs="6"
        size-sm="3"
        size-lg="3"
        size-xl="2"
        *ngFor="let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]"
      >
        <ion-skeleton-text
          [animated]="true"
          style="width: 100%; height: 14vh; border-radius: 10px"
        ></ion-skeleton-text>
        <ion-skeleton-text
          [animated]="true"
          style="width: 100%"
        ></ion-skeleton-text>
        <ion-skeleton-text
          [animated]="true"
          style="width: 75%; margin-top: 0.3rem"
        ></ion-skeleton-text>
        <ion-row>
          <ion-col size="6">
            <ion-skeleton-text
              [animated]="true"
              style="width: 100%"
            ></ion-skeleton-text>
            <ion-skeleton-text
              [animated]="true"
              style="width: 75%; margin-top: 0.3rem"
            ></ion-skeleton-text>
          </ion-col>
          <ion-col size="6">
            <ion-skeleton-text
              [animated]="true"
              style="width: 100%; height: 3.5vh; border-radius: 5px"
            ></ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  
