import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthenticationGuard } from './services/helpers/authentication.guard';
import { DaykartPolicyComponent } from './public/daykart-policy/daykart-policy.component';

import { HomeComponent } from './shared/home/home.component';
import { CategoryListComponent } from './shared/home/category-list/category-list.component';
import { CartComponent } from './shared/cart/cart.component';
import { AboutusComponent } from './shared/account/aboutus/aboutus.component';
import { OrderSummaryComponent } from './shared/order-summary/order-summary.component';
import { AddressSummaryComponent } from './shared/account/address/address-summary/address-summary.component';
// import { AuthenticationGuard } from './services/helpers/authentication.guard';
import { PaymentComponent } from './shared/cart/payment/payment.component';
import { AccountComponent } from './shared/account/account.component';
import { AddressBookComponent } from './shared/account/address-book/address-book.component';
import { OrderListComponent } from './shared/account/order-list/order-list.component';
import { SearchProductComponent } from './shared/home/search-product/search-product.component';
import { OrderDetailsComponent } from './shared/account/order-list/order-details/order-details.component';
import { OrderTrackingComponent } from './shared/account/order-list/order-tracking/order-tracking.component';
import { FloatingCategoryComponent } from './layout/floating-category/floating-category.component';
import { MapComponent } from './shared/account/address/map/map.component';
import { PrivacyPolicyComponent } from './shared/account/aboutus/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './shared/account/aboutus/terms-conditions/terms-conditions.component';
import { NoInternetPageComponent } from './extra/no-internet-page/no-internet-page.component';
import { PreventNavigationGuard } from './services/extensions/prevent-navigation.guard';
import { WalletComponent } from './shared/account/wallet/wallet.component';
import { DaykartTermsConditionComponent } from './public/daykart-terms-condition/daykart-terms-condition.component';
import { DaykartRefundPolicyComponent } from './public/daykart-refund-policy/daykart-refund-policy.component';
import { DaykartReturnPolicyComponent } from './public/daykart-return-policy/daykart-return-policy.component';
import { DaykartShippingPolicyComponent } from './public/daykart-shipping-policy/daykart-shipping-policy.component';
import { ProductListComponent } from './shared/home/product-list/product-list.component';

import {authGuard} from './subscription/guards/auth.guard';
import { OrderCompletionStatusComponent } from './layout/order-completion-status/order-completion-status.component';
import { AccountSettingComponent } from './shared/account/account-setting/account-setting.component';
import { LocationSetupComponent } from './shared/home/location-setup/location-setup.component';
import { LocationGuard } from './services/helpers/location.guard';

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'location-setup',
    component: LocationSetupComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthenticationGuard, LocationGuard]
  },

  // {
  //   path: 'order-status',
  //   component: OrderCompletionStatusComponent,
  // },
  {
    path: 'product-list/:pageType',
    component: ProductListComponent,
    canActivate: [AuthenticationGuard, LocationGuard]
  },
  {
    path: 'search',
    component: SearchProductComponent,
    canActivate: [AuthenticationGuard, LocationGuard]
  },
  {
    path: 'category-list/:categorySlug/:categoryId',
    component: CategoryListComponent,
    canActivate: [AuthenticationGuard, LocationGuard]
  },
  // -------------Cart-----------------
  {
    path: 'cart',
    component: CartComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'cart/payment',
    component: PaymentComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'cart/payment/order-status',
    component: OrderCompletionStatusComponent,
    canActivate: [AuthenticationGuard]
  },
  // -----------Account-----------------
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'account/wallet',
    component: WalletComponent,
    canActivate: [AuthenticationGuard]
  },
  // -----------About-Us---------------
  {
    path: 'account/about-us',
    component: AboutusComponent
  },
  {
    path: 'account/account-setting',
    component: AccountSettingComponent
  },
  {
    path: 'account/about-us/refund-policy',
    component: DaykartRefundPolicyComponent
  },
  {
    path: 'account/about-us/return-policy',
    component: DaykartReturnPolicyComponent
  },
  {
    path: 'account/about-us/shipping-policy',
    component: DaykartShippingPolicyComponent
  },
  {
    path: 'account/about-us/privacy',
    component: DaykartPolicyComponent
  },
  {
    path: 'account/about-us/terms',
    component: DaykartTermsConditionComponent
  },
 
  {
    path: 'privacy-policy',
    component: DaykartPolicyComponent
  },
  {
    path: 'terms-condition',
    component: DaykartTermsConditionComponent
  },
  {
    path: 'shipping-policy',
    component: DaykartShippingPolicyComponent
  },
  {
    path: 'return-policy',
    component: DaykartReturnPolicyComponent
  },
  {
    path: 'refund-policy',
    component: DaykartRefundPolicyComponent
  },
  // -----------Address--------------
  {
    path: 'account/address-book',
    component: AddressBookComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'locate-address',
    component: MapComponent,
    canActivate: [AuthenticationGuard]
  },


  // {
  //   path: 'address-summary',
  //   component: AddressSummaryComponent
  // },
  // {
  //   path: 'order-summary',
  //   component: OrderSummaryComponent
  // },

  // -----------Order-------------
  {
    path: 'account/order-list',
    component: OrderListComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'account/order-list/order-details',
    component: OrderDetailsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'account/order-list/order-track',
    component: OrderTrackingComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'floating',
    component: FloatingCategoryComponent
  },
  {
    path: 'no-internet',
    component: NoInternetPageComponent,
    canDeactivate: [PreventNavigationGuard]
  },
	{
		path: 'subscription',
		loadChildren: () => import('./subscription/pages/listing/listing.module').then(m => m.ListingPageModule),
		canActivate: [authGuard],
	},
  {
    //fallback route
    path: 'subscriptions',
    loadChildren: () => import('./subscription/pages/listing/listing.module').then(m => m.ListingPageModule),
    canActivate: [authGuard],
  },
	// {
	// 	path: '',
	// 	redirectTo: '',
	// 	pathMatch: 'full',
	// },
  {
		path: '',
		redirectTo: '',
		pathMatch: 'full',
	},
	{path: '**', redirectTo: ''},
];
@NgModule({
	          imports: [
		          RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
	          ],
	          exports: [RouterModule],
          })
export class AppRoutingModule {
}
