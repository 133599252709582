<app-title-header [title]="'refundPolicy'" [backButtonRoute]="returnUrl" [parentScreenName]="'Refund Policy Page'">
</app-title-header>

<ion-content class="ion-padding">
  <ion-row>
    <ion-col size="12">
      <p>
        At Daykart, we strive to ensure your satisfaction with every purchase.
        If you are not entirely satisfied with your order, we offer a
        hassle-free return policy for eligible items. Here's what you need to
        know:
      </p>
    </ion-col>
    <ion-col size="12">
      <ol>
        <li>
          <b>Eligibility:</b>
          <ul>
            <li>
              To be eligible for a return, items must be unused, unopened, and
              in their original packaging.
            </li>
            <li>
              Perishable items such as fresh produce, dairy, and frozen goods
              are not eligible for return due to health and safety reasons.
            </li>
          </ul>
        </li>
        <li>
          <b>Initiation:</b>
          <ul>
            <li>
              To initiate a return, please contact our customer support team
              within 24 to 48 hours of receiving your order.
            </li>
            <li>
              Provide details of the item(s) to be returned along with the
              reason for the return.
            </li>
          </ul>
        </li>
        <li>
          <b>Return Process:</b>
          <ul>
            <li>
              Once your return request is approved, you will receive
              instructions on how to proceed with the return.
            </li>
            <li>
              Please ensure that the item(s) are securely packaged for return
              shipment.
            </li>
          </ul>
        </li>
        <li>
          <b>Refunds:</b>
          <ul>
            <li>
              Refunds will be issued to the original payment method used for the
              purchase.
            </li>
            <li>
              Please allow 24 to 48 business hours for the refund to be processed
              after the returned item(s) are received and inspected.
            </li>
          </ul>
        </li>
        <li>
          <b>Exclusions:</b>
          <ul>
            <li>
              Certain items may not be eligible for return, such as perishable
              goods, gift cards, and items marked as non-returnable.
            </li>
          </ul>
        </li>
        <li>
          <b>Damaged or Defective Items:</b>
          <ul>
            <li>
              If you receive a damaged or defective item, please contact us
              immediately for assistance. We will arrange for a replacement or
              refund as applicable.
            </li>
          </ul>
        </li>
        <li>
          <b>Contact Us:</b>
          <ul>
            <li>
              For any questions or assistance regarding returns, please reach
              out to our customer support team. We are here to help!
            </li>
          </ul>
        </li>
      </ol>
    </ion-col>
    <ion-col size="12">
      Please note that our return policy may be subject to change, and any updates will be communicated to our customers accordingly.
      Thank you for choosing Daykart for your grocery needs. Your satisfaction is our priority.
    </ion-col>
  </ion-row>

  <!-- Additional sections of the refund policy can be added as needed -->
</ion-content>
