import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-no-internet-page',
  templateUrl: './no-internet-page.component.html',
  styleUrls: ['./no-internet-page.component.scss'],
})
export class NoInternetPageComponent  implements OnInit {

  constructor(private network:Network,private router:Router,private toastService:ToastService) { }

  ngOnInit() {}

  retryConnection() {
    if (this.network.type !== 'none') {
      // If the device is online, navigate back to the main page or refresh the app
      this.router.navigate(['/']);
    } else {
      // Optionally, show a message if the device is still offline
      this.toastService.showToast('Still offline. Please check your internet connection.');
    }
  }

}
