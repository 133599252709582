<!-- -------------switch--language----- -->
<ion-content class="ion-padding-horizontal ion-padding-top">
    <div class="language-title">
      <h4>{{ "choose_your_language" | translate }}</h4>
    </div>

  <ion-row
    *ngFor="let options of languageOptions; let i = index"
    class="language-options"
    (click)="changeLanguage(i)"
  >
    <ion-col size="2">
      <ion-img src="{{ options.icon }}" />
    </ion-col>
    <ion-col size="8">
      <h4>
        {{ options?.name }}
      </h4>
    </ion-col>
    <ion-col class="check-icon" size="2" class="ion-text-center">
      <p>
        <ion-icon
          color="success"
          [name]="options?.isChecked ? 'checkmark-circle' : 'radio-button-off'"
          size="small"
        ></ion-icon>
      </p>
    </ion-col>
  </ion-row>
  <ion-row class="ion-text-center">
    <ion-col size="12">
      <ion-button
        (click)="languageChanged()"
        class="ion-margin-bottom common-btn"
        >{{ "apply" | translate }}</ion-button
      >
    </ion-col>
  </ion-row>
</ion-content>
<!-- -----------------end---------------- -->
