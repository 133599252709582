<ion-content>
  <ion-row class="store-timing">
    <ion-col size="12">
      <div>
        <ion-img src="../../../../assets/img/daykart/customer-support.png" />
      </div>
      <h2>{{modalData?.modalHeader}}</h2>
      <p [innerHTML]="modalData?.modalBody">
      </p>
      <ion-button class="common-btn" (click)="closeModal()">Okay</ion-button>
    </ion-col>
  </ion-row>
</ion-content>
