
<ion-content [fullscreen]="true">
  <!-- -----------login--section------------- -->
  <div class="login-form">
    <div *ngIf="!otpSent">
      <!-- <div class="login-banner">
        <div>
          <ion-img src="../../assets/img/daykart/daykart-logo.png" />
        </div>
        <div class="driver-image">
          <ion-img src="../../assets/img/daykart/driver-image.png" />
        </div>
      </div> -->
      <div class="single-slider">
        <swiper-container
          [centeredSlides]="true"
          [spaceBetween]="10"
          [slidesPerView]="1"
          [modules]="swiperModules"
          [autoplay]="true"
          #swiper
          (afterinit)="swiperReady()"
          [pagination]="{clickable: true, dynamicBullets: true}"
        >
          <swiper-slide *ngFor="let images of carouselImages">
            <ion-img [src]="images?.imageUrl" [alt]="images?.alt" />
          </swiper-slide>
        </swiper-container>
      </div>

      <div class="ion-padding">
        <ion-row>
          <ion-col size="12" class="login-title">
            <h4>
              Everyday, the <span class="highlight-color">Daykart</span> way!
            </h4>
            <small>Login or signup start shopping your daily essentials</small>
          </ion-col>
        </ion-row>
        <form [formGroup]="mobileForm" (ngSubmit)="sendOtp()">
        <ion-row >
          <ion-col size="12">
            <small class="input-label">Enter mobile number</small>
            <ion-input
              #autofocus
              placeholder="xxxxxxxxxx"
              type="tel"
              label="+91"
              formControlName="mobile"
              maxlength="10"
            ></ion-input>
            <ng-container *ngFor="let validation of validations.mobile">
              <div
                class="error-message"
                *ngIf="
                  mobileForm.get('mobile')?.hasError(validation.type) &&
                  mobileForm.get('mobile')?.dirty
                "
              >
                <span class="error-msg"> {{ validation.message }}</span>
              </div>
            </ng-container>
          </ion-col>

          <ion-col size="12" class="ion-margin-top">
            <!-- <ion-button class="common-btn" expand="block" (click)="sendOtp()" [disabled]="otpRequestInProgress">
              Continue
            </ion-button> -->

            <ion-button class="common-btn" expand="block" type="submit" [disabled]="otpRequestInProgress">
              Continue
            </ion-button>

            <!-- <ion-button class="custom-outline-btn ion-margin-top" expand="block" (click)="skipLogin()">
              Browse as Guest
            </ion-button> -->

            <div class="privacy-text">
              <small
                >By continuing, I accept the
                <span
                  class="note-message"
                  (click)="navigateTo('terms-condition')"
                  >Terms & Conditions</span
                >
                &
                <span
                  class="note-message"
                  (click)="navigateTo('privacy-policy')"
                >
                  Privacy Policy</span
                >.</small
              >
            </div>
          </ion-col>
        </ion-row>
      </form>
      </div>
    </div>

    <!-- ---------------end----------- -->
    <!-- --------otp--section--------- -->
    <ion-grid *ngIf="otpSent" class="ion-padding">
      <div class="otp-section">
        <ion-row>
          <ion-col size="12">
            <div class="back-icon-top">
              <ion-icon name="arrow-back-outline" (click)="goBack()"></ion-icon>
            </div>
            <div><h4>Enter OTP</h4></div>
            <small
              >Enter OTP sent to
              <span class="highlight-text">{{ mobile }}</span> via sms</small
            >
          </ion-col>
        </ion-row>

        <form [formGroup]="otpForm" (ngSubmit)="signIn()">
        <ion-row>
          <ion-col size="12">
            <ion-input
              placeholder="Enter 4 digit OTP here...."
              type="tel"
              formControlName="otp"
              [maxlength]="4"
            ></ion-input>
            <ng-container *ngFor="let validation of validations.otp">
              <div
                class="error-message"
                *ngIf="
                  otpForm.get('otp')?.hasError(validation.type) &&
                  otpForm.get('otp')?.dirty
                "
              >
                <span class="error-msg-1"
                  ><i class="fa-solid fa-circle-info"></i>
                  {{ validation.message }}</span
                >
              </div>
            </ng-container>
          </ion-col>
          <ion-col size="12" class="ion-margin-top">
            <small>
              Didn’t receive OTP?
              <span *ngIf="enableResendOtp"
                ><a class="resend-btn" (click)="resendOtp()">Resend</a></span
              >
              <span *ngIf="!enableResendOtp" class="ion-float-end">{{
                otpTimer
              }}</span>
            </small>
          </ion-col>

          <ion-col size="12" class="ion-margin-top">
            <ion-button class="common-btn" expand="block" type="submit" [disabled]="otpVerifyInProgress">
              Login
            </ion-button>
          </ion-col>
        </ion-row>
      </form>

      </div>
    </ion-grid>
    <!-- ---------------end-------------------- -->
  </div>
</ion-content>
