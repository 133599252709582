import {inject, Injectable}                                            from '@angular/core';
import {AlertController, LoadingController, Platform, ToastController} from '@ionic/angular';
import {Location}                                                      from '@angular/common';
import {Subject}                                                       from 'rxjs';
import {STORAGE} from '../shared/constants';

@Injectable({
	            providedIn: 'root',
            })
export class CommonService {
	public platform: Platform = inject(Platform);
	public alertController: AlertController = inject(AlertController);
	public toastController: ToastController = inject(ToastController);
	public location: Location = inject(Location);
	private loadingCtrl: LoadingController = inject(LoadingController);
	public refreshSub$: Subject<boolean> = new Subject<boolean>();
	
	constructor() {
	}
	
	public get userMobile(): string {
		return localStorage.getItem(STORAGE.USER_MOBILE) || '';
	}
	public async showAlert(title: string, message: string): Promise<HTMLIonAlertElement> {
		const alert: HTMLIonAlertElement = await this.alertController.create({
			                                                                     header: title,
			                                                                     message: message,
			                                                                     buttons: [{
				                                                                     text: 'OK',
				                                                                     role: 'confirm',
				                                                                     handler: () => {
					                                                                     alert.dismiss();
				                                                                     },
			                                                                     }],
			                                                                     backdropDismiss: false,
		                                                                     });
		await alert.present();
		return alert;
	}
	
	public async showLoader(): Promise<HTMLIonLoadingElement> {
		const loading: HTMLIonLoadingElement = await this.loadingCtrl.create({
			                                                                     message: 'Please Wait',
			                                                                     showBackdrop: true,
			                                                                     animated: true,
			                                                                     backdropDismiss: true,
		                                                                     });
		await loading.present();
		return loading;
	}
	
	public async showToast(message: string, duration: number = 3000, position: 'bottom' | 'top' | 'middle' = 'bottom', color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' = 'success'): Promise<HTMLIonToastElement> {
		const toast: HTMLIonToastElement = await this.toastController.create({
			                                                                     message: message,
			                                                                     duration: duration,
			                                                                     position: position,
			                                                                     color: color,
			                                                                     buttons: [
				                                                                     {
					                                                                     text: 'OK',
					                                                                     role: 'cancel',
					                                                                     handler: () => {
						                                                                     toast.dismiss();
					                                                                     },
				                                                                     },
			                                                                     ],
		                                                                     });
		await toast.present();
		return toast;
	}
}