import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartProducts: any = [];
  cartListData: any = []
  cartSubject = new BehaviorSubject(this.cartProducts);

  isInCartSubject = new BehaviorSubject([])

  private cartSubjectSource = new BehaviorSubject<any[]>([]);
  cartSubject$ = this.cartSubjectSource.asObservable();

  constructor(
    private http: HttpClient
  ) {
    // this.cartProductList();
    this.loadCartData();
  }

  private loadCartData() {
    this.list().subscribe((res: any) => {
      if (res.success) {
        this.cartSubjectSource.next(res);
        this.filterCartProducts(res?.cartProducts)
      }
      else {
        this.cartSubjectSource.next([]);
        this.isInCartSubject.next([]);
      }

    })
  }

  public reloadCartData() {
    this.loadCartData();
  }

  updateCartData(newData: any) {
    // this.cartSubjectSource.next(newData);
    // this.filterCartProducts(newData?.cartProducts)  
    if(newData && newData?.cartProducts && newData?.cartProducts.length > 0){
      this.cartSubjectSource.next(newData);
      this.filterCartProducts(newData?.cartProducts)
    }
    else if(newData?.length === 0){
      this.cartSubjectSource.next([]);
      this.isInCartSubject.next([]);
    }
  }


  addtocart(body: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/cart`, body)
  }

  cartList(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/cart/list`)
  }

  updateCart(body: any): Observable<any> {
    return this.http.put<any>(environment.apiUrl + `/cart`, body)
  }

  list() {
    return this.http.get(`${environment.apiUrl}/cart/list`);
  }

  removeProductFromCart(productId: string) {
    return this.http.delete<any>(`${environment.apiUrl}/cart?productId=${productId}`)
  }

  addCoupon(body:any){
    return this.http.post(`${environment.apiUrl}/promocode/verify`, body);
  }

  removeCoupon(){
    return this.http.post(`${environment.apiUrl}/promocode/remove`,{});
  }

  cartProductList() {
    this.list().subscribe((res: any) => {
      // if (res?.cartProducts.length > 0) {
      //   this.cartSubject.next(res);
      //   this.filterCartProducts(res?.cartProducts)
      // }
      // else {
      //   this.cartSubject.next([]);
      //   this.isInCartSubject.next([]);
      // }
      if (res.success) {
        this.cartSubject.next(res);
        this.filterCartProducts(res?.cartProducts)
      }
      else {
        this.cartSubject.next([]);
        this.isInCartSubject.next([]);
      }

    })
  }

  filterCartProducts(data: any) {
    let filterData: any = data.map((obj: any) => {
      const { quantity, stock, productId } = obj;
      return { quantity, stock, productId };
    });

    this.isInCartSubject.next(filterData)
  }

  getCartItems() {
    return this.isInCartSubject.asObservable();
  }

  getProductQuantity(productId: string): number {
    const cartItems = this.isInCartSubject.getValue();
    const product: any = cartItems.find((item: any) => item.productId === productId);
    return product ? product.quantity : 0;
  }

  verifyCartItemsBeforePayment(selectedAddressId:any=''){
    return this.http.get(`${environment.apiUrl}/cart/checkout`);
  }

  removeOutOfStockProductsBeforePayment(body:any){
    return this.http.delete(`${environment.apiUrl}/cart/cleanup`, body);
  }

  // ---------------------------------Morning-Delivery-Popup-Timing-----------------------------------------

  isModalOpenedRecently(): boolean {
    const lastDisplayTime = localStorage.getItem('storeTimingModalDisplayTime');
    if (!lastDisplayTime) return true; // No record found, assume it's okay to show the modal

    // not showing modal if opened again in 10 minutes  
    const tenMinutesInMilliseconds = 10 * 60 * 1000;
    const currentTime = new Date().getTime();
    const timeSinceLastDisplay = currentTime - parseInt(lastDisplayTime, 10);
  
    return timeSinceLastDisplay >= tenMinutesInMilliseconds;
  }
}
