<app-title-header [title]="'shippingPolicy'" [backButtonRoute]="returnUrl" [parentScreenName]="'Shipping Policy Page'"
>
</app-title-header>

<ion-content class="ion-padding">
  <ion-row>
    <ion-col size="12">
      <p>
        At Daykart, we strive to provide you with a seamless and convenient
        shopping experience. Here's everything you need to know about our
        shipping policy:
      </p>
    </ion-col>
    <ion-col size="12">
      <ol>
        <li>
          <b>Delivery Zones:</b> We currently offer delivery services to select
          areas within [List of Cities or Regions]. Please check our website or
          app to confirm if we deliver to your location.
        </li>
        <li>
          <b>Delivery Times:</b> We offer flexible delivery slots to accommodate
          your schedule. You can choose from available time slots during
          checkout, and our delivery team will strive to deliver your order
          within the selected time window.
        </li>
        <li>
          <b>Same-Day Delivery:</b> Enjoy the convenience of same-day delivery
          for orders placed before [Specific Cut-off Time] on weekdays. Orders
          placed after the cut-off time will be scheduled for delivery on the
          next available delivery slot.
        </li>
        <li>
          <b>Delivery Charges:</b> We offer free delivery for orders above
          [Minimum Order Amount]. For orders below this threshold, a nominal
          delivery fee will be applied to cover shipping costs.
        </li>
        <li>
          <b>Order Tracking:</b> Stay informed about the status of your order
          with our real-time order tracking feature. Once your order is
          dispatched for delivery, you will receive updates via email or SMS,
          allowing you to track the progress of your delivery.
        </li>
        <li>
          <b>Packaging:</b> Your groceries will be carefully packed to ensure
          freshness and prevent damage during transit. We use eco-friendly
          packaging materials whenever possible to minimize our environmental
          footprint.
        </li>
        <li>
          <b>Special Instructions:</b> If you have any specific delivery
          instructions or preferences, such as gate codes or preferred delivery
          times, please provide them during checkout, and we will do our best to
          accommodate your requests.
        </li>
        <li>
          <b>Returns and Exchanges:</b> For information regarding returns,
          exchanges, or refunds, please refer to our Returns Policy or contact
          our customer support team for assistance.
        </li>
      </ol>
    </ion-col>
    <ion-col size="12">
      For any further questions or inquiries regarding our shipping policy,
      please don't hesitate to contact our customer support team. Thank you for
      choosing Daykart for your shopping needs.
    </ion-col>
  </ion-row>

  <!-- Additional sections of the shipping policy can be added as needed -->
</ion-content>
