<!-- ----------------header---------------------- -->
<!-- <ion-header [translucent]="true" *ngIf="isLoaded">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col class="mid-center-content" size="1">
          <ion-img src="../../../assets/img/daykart/default-product-img.png" />
        </ion-col>
        <ion-col size="8">
          <p class="location">
            Delivery in <span>{{ estimateTime }} minutes</span>
          </p>
        </ion-col>

        <ion-col
          size="2"
          class="mid-center-content pointer ion-text-center ion-activatable ripple-parent rounded-rectangle"
        >
          <app-support-icon [parentScreenName]="'Homepage'"></app-support-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-col>

        <ion-col
          size="1"
          class="mid-center-content user-icon pointer ion-text-end ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-icon
            (click)="checkIfUserLoggedIn('account', 'account')"
            name="person-circle-outline"
          ></ion-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-col>
      </ion-row>

      <ion-row
        class="location-address ion-activatable ripple-parent rounded-rectangle"
        (click)="changeCurrentLocation()"
      >
        <ion-col size="1" class="ion-text-center">
          <ion-icon name="location"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <div class="text-with-icon">
            <p class="truncated-text">{{ currentAddress | titlecase }}</p>
            <span class="dropdown-icon"
              ><ion-icon name="caret-down-outline"></ion-icon
            ></span>
          </div>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>

    </ion-grid>
  </div>

  <ion-grid>
    <ion-row
      class="search-field ion-activatable ripple-parent rounded-rectangle"
      (click)="goToSearch()"
    >
      <ion-col size="1" class="ion-text-center">
        <ion-icon name="search-outline"></ion-icon>
      </ion-col>
      <ion-col size="11">
        <ion-input
          readonly="true"
          type="text"
          placeholder="{{ 'search_placeholder_category' | translate }}"
        ></ion-input>
      </ion-col>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-row>
  </ion-grid>

  <ion-grid class="product-availability ion-margin-top ion-margin-bottom">
    <ion-row>
      <ion-col size="4">
        <div
          class="outline-shadow ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-row (click)="openWhatsAppChat()">
            <ion-col size="4" class="order-icon">
              <ion-icon name="logo-whatsapp"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h6 class="sub-title">Order on</h6>
              <h6><b>Whatsapp</b></h6>
            </ion-col>
          </ion-row>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </ion-col>
      <ion-col size="4">
        <div
          class="outline-shadow mid-auto ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-row (click)="contactStoreSupport()">
            <ion-col size="4" class="order-icon">
              <ion-icon name="call-outline"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h6 class="sub-title">Order on</h6>
              <h6><b>Call</b></h6>
            </ion-col>
          </ion-row>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </ion-col>
      <ion-col size="4">
        <div
          class="outline-shadow right-auto ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-row (click)="buyatstoreModal()">
            <ion-col size="4" class="order-icon">
              <ion-icon name="storefront-outline"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h6 class="sub-title">Buy at</h6>
              <h6><b>Store</b></h6>
            </ion-col>
          </ion-row>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header> -->
<!-- -----------end------------ -->
<ion-header [translucent]="true">
  <ion-toolbar class="custom-toolbar">
    <ion-row class="header-search-section">
      <ion-col size="9">
        <ion-row
          class="search-field ion-activatable ripple-parent rounded-rectangle"
          (click)="goToSearch()"
        >
          <ion-col size="1" class="ion-text-center">
            <ion-icon name="search-outline"></ion-icon>
          </ion-col>
          <ion-col size="11">
            <ion-input
              readonly="true"
              type="text"
              placeholder="{{ 'search_placeholder_category' | translate }}"
            ></ion-input>
          </ion-col>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-row>
      </ion-col>

      <ion-col class="call-icon">
        <!-- <img src="../../../assets/img/daykart/call-icon.svg" alt="" /> -->
        <app-support-icon [parentScreenName]="'Homepage'"></app-support-icon>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-col>

      <ion-col
        class="user-icon pointer ion-text-end ion-activatable ripple-parent rounded-rectangle"
      >
        <ion-icon
          (click)="checkIfUserLoggedIn('account', 'account')"
          name="person-circle-outline"
        ></ion-icon>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-col>
    </ion-row>
  </ion-toolbar>
  <ion-row class="location-address" (click)="changeCurrentLocation()">
    <ion-col>
      <p class="location">
        Delivery in <span>{{ estimateTime }} minutes</span>
      </p>
    </ion-col>
    <ion-col
      size="12"
      class="ion-activatable ripple-parent rounded-rectangle"
    >
      <div class="text-with-icon">
        <p class="truncated-text">{{ currentAddress | titlecase }}</p>
        <span class="dropdown-icon"
          ><ion-icon name="caret-down-outline"></ion-icon
        ></span>
      </div>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-col>
  </ion-row>
</ion-header>

<!-- --------skeleton---------- -->
<div *ngIf="!isLoaded">
  <app-header-skeleton></app-header-skeleton>
</div>
<!-- ----------end---------------- -->
