<ion-header [translucent]="true" class="ion-padding-vertical">
  <ion-toolbar class="custom-toolbar">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col size="1" (click)="routeTo()">
          <ion-icon name="arrow-back" class="back-arrow"></ion-icon>
        </ion-col>
        <ion-col size="11" class="page-title">
          <p>
            <span><b>Order Summary</b></span>
          </p>
          <small class="text-gray">{{
            orderData?.deliveryStatus === "delivered"
              ? "Arrived at " + (orderData?.deliveryTime | date)
              : orderData?.deliveryStatusDescription
          }}</small>
        </ion-col>
       
      </ion-row>
    </ion-grid>
  </div>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding-bottom">
  <div *ngIf="isLoaded">
  <ion-row>
    <ion-col size="12">
      <h5 class="ion-padding-start">
        <b>
          No. of Items Ordered: {{ orderedProducts.length }}
          </b>
      </h5>
    </ion-col>
  </ion-row>
  <div class="bill-details">
    <ion-row
      class="order-details ion-margin-bottom"
      *ngFor="let order of orderedProducts"
    >
      <ion-col size="2">
        <ion-img
          src="{{ order?.images[0] }}"
          (ionError)="loadDefaultImg($event)"
        />
      </ion-col>
      <ion-col size="6">
        <h6>{{ order.name | titlecase }}</h6>
        <small class="text-gray">{{order?.size}}</small>
      </ion-col>
      <ion-col size="4" class="ion-text-end">
        <div class="price-text">
          <span>&#8377;{{ order.discountedPrice }}</span>
          <small
            ><del class="text-gray"> &#8377;{{ order.mrp }}</del></small
          >
        </div>
        <div *ngIf="order?.quantity > 1" class="text-gray"><small>{{'Qty: '+order?.quantity}}</small></div>
      </ion-col>
    </ion-row>
  </div>

  <!-- ---------bill-details------------- -->
  <div class="bill-details title-list">
    <ion-row>
      <ion-col size="12">
        <h4><b>Bill details</b></h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <p>Subtotal</p>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <p>
          <span>&#8377; {{ billData?.totalOriginalPrice }}</span>
        </p>
      </ion-col>
    </ion-row>

    <ion-row class="discount-text">
      <ion-col size="6">
        <p>Product Discount</p>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <p>
          <span>- &#8377; {{ billData?.savings }}</span>
        </p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <p>Delivery Charge</p>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <p>
          <span>{{
            billData?.deliveryCharge === 0
              ? "FREE"
              : "&#8377;" + billData?.deliveryCharge
          }}</span>
        </p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <p>Total Savings</p>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <p>
          <span>&#8377; {{ billData?.totalSavings }}</span>
        </p>
      </ion-col>
    </ion-row>

    <!-- <ion-row *ngIf="isCouponApplied">
      <ion-col size="8" class="ion-align-items-center">
        <p class="text-gray-color">
          Coupon Discount
      </ion-col>
      <ion-col size="4" class="ion-text-end">
        <p>-&#8377; {{ billingData?.couponDiscount }}</p>
      </ion-col>
    </ion-row> -->

    <ion-row *ngIf="orderData?.couponDiscount>0">
      <ion-col size="6">
        <p>Coupon Discount</p>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <p>
          -&#8377; {{ orderData?.couponDiscount }}
        </p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <p>Amount Payable</p>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <p>
          <span>&#8377; {{ billData?.totalDiscountedPrice }}</span>
        </p>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="billData?.walletAmountDeducted>0">
      <ion-col size="6">
        <p>Wallet Balance</p>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <p>
          -&#8377; {{ billData?.walletAmountDeducted }}
        </p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <h5>Bill total</h5>
      </ion-col>
      <ion-col size="6" class="ion-text-end">
        <h5>
          <b
            ><span>&#8377; {{ billData?.grandTotal }}</span></b
          >
        </h5>
      </ion-col>
    </ion-row>
  </div>
  <!-- --------------end----------------- -->
  <!-- ----------order-details--------- -->
  <div class="bill-details title-list order-pricing">
    <ion-row>
      <ion-col size="12">
        <h4><b>Order details</b></h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p class="text-gray">Order ID</p>
      </ion-col>
      <ion-col size="12">
        <p>{{ orderData?.orderID }}</p>
      </ion-col>
      <ion-col size="12">
        <p class="text-gray">Payment Method</p>
      </ion-col>
      <ion-col size="12">
        <p>{{ orderData?.paymentMethod | titlecase }}</p>
      </ion-col>
      <ion-col size="12">
        <p class="text-gray">Delivery Address</p>
      </ion-col>
      <ion-col size="12">
        <p>
          {{ orderData?.shippingAddress?.completeAddress }}
          {{ orderData?.shippingAddress?.googleAddress }}
        </p>
      </ion-col>
      <ion-col size="12">
        <p class="text-gray">Order Placed On</p>
      </ion-col>

      <ion-col size="12">
        <p>
          {{
            (orderData?.createdAt | date : "mediumDate") +
              ", " +
              (orderData?.createdAt | date : "shortTime")
          }}
        </p>
      </ion-col>
    </ion-row>
  </div>
  <!-- ---------------end----------------- -->
  <!-- ------------need--------help------- -->
    <ion-row class="contact-us ion-margin-bottom" (click)="callHelpline(orderData?.store?.mobile)">
      <ion-col size="2">
        <ion-icon name="call" class="product-icon"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <h4>Contact Us</h4>
      <p class="text-gray">{{orderData?.store?.mobile}}</p>
      </ion-col>
    </ion-row>

</div>
  <app-cart-skeleton *ngIf="!isLoaded"></app-cart-skeleton>
  <!-- ---------------end--------------- -->
</ion-content>

