import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { AddressService } from 'src/app/services/address.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-address-summary',
  templateUrl: './address-summary.component.html',
  styleUrls: ['./address-summary.component.scss'],
})
export class AddressSummaryComponent implements OnInit, ViewWillEnter, ViewWillLeave {

  addressType: string = '';
  isEdit: boolean = false;

  addressId: any = '';
  googleAddressUpdate: string = ''

  currentAddress: string = '';
  distanceInKm: number = 1;

  addressLat: any;
  addressLng: any;
  formattedAddress: any;

  constructor(private locationService: LocationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private addressService: AddressService,
    private toastService: ToastsService
  ) { }


  ionViewWillEnter() {
    this.addressType = 'home';
  }

  ionViewWillLeave() {
    this.customerAddressForm.reset()
  }

  get address() {
    return this.locationService.address;
  }

  get addressPlace() {
    return this.locationService.addressPlace;
  }

  get storeDistance() {
    return this.locationService.storeDistance;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((param: any) => {
      if (param?.addressId) {
        this.isEdit = true;
        this.addressId = param?.addressId;
        this.addressDetails()
      }
      else {
        this.isEdit = false;
      }
    })

    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation()?.extras.state) {
        const location = this.router.getCurrentNavigation()?.extras.state?.['locationData'];
        this.currentAddress = location?.currentAddress;
        this.distanceInKm = location?.distance;
        this.addressLat = location?.latitude;
        this.addressLng = location?.longitude;
        this.formattedAddress = location?.formattedAddress
      }
    });
  }

  customerAddressForm = new FormGroup({
    receiverName: new FormControl('', Validators.compose([Validators.required])),
    receiverPhoneNumber: new FormControl('', Validators.compose([Validators.required, Validators.pattern('(^[6-9][0-9]{9}$)')])),
    floor: new FormControl(''),
    nearbyLandmark: new FormControl(''),
    completeAddress: new FormControl('', Validators.compose([Validators.required])),
  })

  validations = {
    'receiverPhoneNumber': [
      { type: 'required', message: 'Please enter mobile no.' },
      { type: 'pattern', message: 'Please enter valid 10-digit mobile number' },
    ],
    'receiverName': [
      { type: 'required', message: 'Please enter customer name' },
    ],
    'completeAddress': [
      { type: 'required', message: 'Please enter house/building/office no.' },
    ],
  };

  addressDetails() {
    this.addressService.addressDetails(this.addressId).subscribe((res: any) => {
      if (res?.success) {
        this.customerAddressForm.patchValue(res?.address);
        this.addressType = res?.address?.addressType;
        this.googleAddressUpdate = res?.address?.googleAddress
      }
      else {
        this.toastService.showToast(res?.message)
      }
    })
  }

  changeAddressType(event: any) {
    this.addressType = event.target.value;
  }

  checkAddressForm() {
    if (this.customerAddressForm.invalid) {
      this.customerAddressForm.markAllAsTouched();
    }
    else {
      if (this.isEdit) {
        this.updateAddress();
      }
      else {
        this.addAddress();
      }
    }

  }

  addAddress() {
    const data = {
      googleAddress: this.currentAddress,
      addressType: this.addressType,
      lat: this.addressLat,
      lng: this.addressLng,
      formattedAddress: this.formattedAddress
    }

    const payload = Object.assign(this.customerAddressForm.value, data)

    this.addressService.addAddress(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastService.showToast(res?.message);
        const navigationExtras = {
          state: {
            addressData: res?.data
          }
        };
        this.router.navigate(['cart'], navigationExtras)
      }
      else {
        this.toastService.showToast(res?.message)

      }
    })

  }

  updateAddress() {
    const data = {
      googleAddress: this.googleAddressUpdate,
      addressType: this.addressType
    }

    const payload = Object.assign(this.customerAddressForm.value, data)

    this.addressService.updateAddress(payload, this.addressId).subscribe((res: any) => {
      if (res.success) {
        this.toastService.showToast(res?.message);
        //     const navigationExtras = {
        //       state: {
        //         redirectUrl: currentUrl
        //       }
        //     };
        // this.router.navigate(['cart'],navigationExtras)
        this.router.navigate(['cart'])
      }
      else {
        this.toastService.showToast(res?.message)

      }
    })

  }

}
