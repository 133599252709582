
<!-- ----------header------------- -->
<app-title-header [title]="'privacy policy'" [backButtonRoute]="'/account/about-us'" [parentScreenName]="'Privacy policy'"></app-title-header>
<!-- ---------end------------- -->
<!-- ----------policy--------- -->
<ion-content class="ion-padding-horizontal">  
  <ion-row class="policy">
    <ion-col size="12">
      <h4><b>Privacy Policy </b></h4>
      <p>
        Team DayKart is delighted to have you here. Thank you for visiting us.
        DayKart is an online supermarket catering to all your daily grocery and
        essentials needs. Daykart Retail Private Limited is the licensed owner of
        the brand “DayKart” and the website daykart.com (“The Site”).
      </p>
      <p>
        At DayKart, privacy concerns of all our visitors and customers are of
        utmost importance. Thereby, we have documented our privacy policy with
        utmost care and considerations. This Privacy Policy provides succinct
        details about how your data is collected and used by DRPL on the Site. As
        a visitor to the Site/ Customer, you are advised to please read the
        Privacy Policy carefully. By accessing the services provided by the Site,
        you agree to the collection and use of your data by DRPL in the manner
        provided in this Privacy Policy.
      </p>
      <p>
        DRPL may amend this Privacy Policy from time to time, at its sole
        discretion. The amended terms of this policy shall be notified to our
        visitors/customers as and when required. Team DayKart assures that all the
        amendments that shall be made will be in compliance of the GDPR Privacy
        Policy. You can find the last revised date of the privacy policy at the
        end of this page. You are responsible for periodically visiting The Site
        and this privacy policy to check for any amendments.
      </p>
    </ion-col>

    <ion-col size="12">
      <h4><b>What can be collected? </b></h4>
  
      <ol>
        <li>
          personal and contact information such as name, email address, postal
          address, contact details and telephone number;
        </li>
        <li>
          demographic information such as location, gender, age, marital status,
          occupation, the type of device through which you access the Sites, and
          other similar information;
        </li>
        <li>
          self-reported income and/or credit score information; financial
          information such as credit card or bank account numbers;
        </li>
        <li>
          logs, contact information and other information about communications
          between past, present or potential customers and past, present or
          potential customers using our or our affiliated companies'
          communications tools
        </li>
        <li>
          information automatically collected by web beacons and cookies on the
          devices you use to access the Sites (i.e., computers and/or mobile
          devices, as well as internet browsers and other applications on a device
          that interacts with the internet);
        </li>
        <li>
          information based on your interactions with the Sites or Other Sites and
          their advertising, such as search information, device sign-on data,
          statistics on page views, traffic to, from and within the Sites or Other
          Sites, ad data, IP address and standard web log information;
        </li>
        <li>
          information about the pages on the site you visit/access, the links you
          click on the site, the number of times you access the page and any such
          browsing information
        </li>
        <li>
          cross device data, which includes the collection of data from devices
          that are believed to be linked or related (based on statistical
          analysis);
        </li>
        <li>
          device locations, including specific geographic locations, such as
          through GPS, Bluetooth, or WiFi signals;
        </li>
        <li>
          connection information such as the name of your mobile operator or ISP,
          browser type, language and time zone, mobile phone number and IP
          address.
        </li>
      </ol>
    </ion-col>

  <ion-col size="12">
    <h4><b>How do we collect the Information? </b></h4>
    <p>
      DRPL will collect personally identifiable information about you only as
      part of a voluntary registration process, on-line survey or any
      combination thereof. The Site may contain links to other Web sites. DRPL
      is not responsible for the privacy practices of such Web sites which it
      does not own, manage or control. The Site and third-party vendors,
      including Google, use first-party cookies (such as the Google Analytics
      cookie) and third-party cookies (such as the DoubleClick cookie) together
      to inform, optimize, and serve ads based on someone's past visits to the
      Site.
    </p>
  </ion-col>

  <ion-col size="12">
    <h4><b>How is the information used? </b></h4>
    <p>
      DRPL will use your personal information to provide personalized features
      to you on the Site and to provide for promotional offers to you through
      the Site and other channels. DRPL will also provide this information to
      its business associates and partners to get in touch with you when
      necessary to provide the services requested by you. DRPL will use this
      information to preserve transaction history as governed by existing law or
      policy. DRPL may also use contact information internally to direct its
      efforts for product improvement, to contact you as a survey respondent, to
      notify you if you win any contest; and to send you promotional materials
      from its contest sponsors or advertisers.
    </p>
    <p>
      DRPL will also use this information to serve various promotional and
      advertising materials to you via display advertisements through the Google
      Ad network on third party websites. You can opt-out of Google Analytics
      for Display Advertising and customize Google Display Network ads using the
      Ads Preferences Manager. Information about Customers on an aggregate
      (excluding any information that may identify you specifically) covering
      Customer transaction data and Customer demographic and location data may
      be provided to partners of DRPL for the purpose of creating additional
      features on the website, creating appropriate merchandising or creating
      new products and services and conducting marketing research and
      statistical analysis of customer behaviour and transactions.
    </p>
  </ion-col>
  <ion-col size="12">
    <h4><b>Who can access your information? </b></h4>
    <p>
      The personal details shared by the visitors are stored as “Account
      information”. We are dedicated to protect the use of the information only
      by authorized and designated employees and personnel. The interaction
      between the users of the platform is of no concern to the DayKart and it
      is personal choice of customers/visitors for sharing the information.
    </p>
  </ion-col>
  <ion-col size="12">
    <h4><b>What about Cookies? </b></h4>
  
    <p>
      When you visit our Application, we may place “cookies” on your computer to
      recognize you. We do this to store your preferences, collect statistical
      data and track your use of our Service. Cookies are widely used, and most
      web browsers are configured initially to accept cookies automatically. If
      you prefer not to accept cookies, you may adjust and configure your
      browser settings accordingly. However, if you turn off cookies, or refuse
      to accept a request to place a cookie, some features and services on the
      Application may not function properly. Ad companies (including but not
      limited to Google and Facebook) may also place cookies on your computer to
      track your activities across various Applications so that they can display
      targeted advertisements.
    </p>
  </ion-col>
  <ion-col size="12">
    
  <h4><b>Is your data secure? </b></h4>
  
  <p>
    We recognize that you are providing sensitive information in connection
    with your use of the Services and that security is critical to our
    Services. As such, we take security precautions, and will continue to
    vigilantly safeguard your sensitive Personal Information. We update our
    security software with the latest patches frequently. Our servers are
    maintained by a reputable third-party service provider and housed in a
    secured facility. We separate the application server (servers that you
    interact with) and the database servers (servers that store our data).
    This ensures that users of the Website cannot directly interact with the
    database. We encrypt sensitive information, such as bank numbers, routing
    numbers, employment history and income sources, and all passwords using
    256-bit encryption technology.
  </p>
  </ion-col>

  <ion-col size="12">
    <h4><b>How can you correct inaccuracies in the information? </b></h4>
    <p>
      To correct or update any information you have provided, the Site allows
      you to do it online. In the event of loss of access details, you can send
      an email to: support@daykart.com
    </p>
  </ion-col>
  <ion-col size="12">
    <h4><b>Can this policy be changed? </b></h4>
    <p>
      We change this Privacy Policy from time to time. We will not reduce your
      rights under this Privacy Policy without your explicit consent. We always
      indicate the date the last changes were published and we offer access to
      archived versions for your review. If changes are significant, we’ll
      provide a more prominent notice (including, for certain services, email
      notification of Privacy Policy changes).
    </p>
  </ion-col>

  <ion-col size="12">
    <h4><b>Regulatory compliance </b></h4>
    <p>
      We maintain servers around the world and your information may be processed
      on servers located outside of the country where you live. Data protection
      laws vary among countries, with some providing more protection than
      others. Regardless of where your information is processed, we apply the
      same protections described in this policy. We also comply with certain
      legal frameworks relating to the transfer of data, such as the EU-US
      Privacy Shield Frameworks.
    </p>
    <p>
      When we receive formal written complaints, we respond by contacting the
      person who made the complaint. We work with the appropriate regulatory
      authorities, including local data protection authorities, to resolve any
      complaints regarding the transfer of your data that we cannot resolve with
      you directly.
    </p>
  </ion-col>

  <ion-col size="12">
    <h4><b>For legal reasons </b></h4>
    <p>
      We will share personal information outside of DayKart if we have a
      good-faith belief that access, use, preservation, or disclosure of the
      information is reasonably necessary to:
    </p>

    <ol>
      <li>
        Meet any applicable law, regulation, legal process, or enforceable
        governmental request. We share information about the number and type of
        requests we receive from governments in our Transparency Report.
      </li>
      <li>
        Enforce applicable Terms of Service, including investigation of
        potential violations.
      </li>
      <li>
        Detect, prevent, or otherwise address fraud, security, or technical
        issue
      </li>
      <li>
        Protect against harm to the rights, property or safety of DayKart, our
        users, or the public as required or permitted by law.
      </li>
    </ol>
    <p>
      We may share non-personally identifiable information publicly and with our
      partners — like publishers, advertisers, developers, or rights holders.
      For example, we share information publicly to show trends about the
      general use of our services. We also allow specific partners to collect
      information from your browser or device for advertising and measurement
      purposes using their own cookies or similar technologies.
    </p>
    <p>
      If DayKart is involved in a merger, acquisition, or sale of assets, we’ll
      continue to ensure the confidentiality of your personal information and
      give affected users notice before personal information is transferred or
      becomes subject to a different privacy policy and will be governed by
      Indian Laws.
    </p>
  </ion-col>

  <ion-col size="12">
    <h4><b>No liability for disclosure </b></h4>
    <p>
      Additionally, we shall not be liable for any loss or damage sustained by
      reason of any disclosure (inadvertent or otherwise) of any information
      concerning the user’s account and / or information relating to or
      regarding online transactions using credit cards / debit cards and / or
      their verification process and particulars nor for any error, omission or
      inaccuracy with respect to any information so disclosed and used whether
      or not in pursuance of a legal process or otherwise.
    </p>
    <p>For further information, visit <a href="" routerLink="/account/helps">Contact Us</a> page.</p>
    <p>Dated – 20-05-2021</p>
  </ion-col>
  </ion-row>
  
</ion-content>
<!-- -----------end---------- -->
