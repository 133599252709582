import {ApplicationRef, ComponentRef, Injectable, Injector, ViewContainerRef} from '@angular/core';

/**
 * Component Append Service is used to append component to the application and is better than using an ion-modal
 * as we do not have to pollute the global.scss file. Only ```closed``` event is supported for now from the loaded
 * component.
 * @example
 * ```
 * const componentRef = await this.componentAppendService.open(component, data);
 * componentRef.instance.closed.subscribe(async (returnData:any): Promise<void> => {
 *     //listen to the closed event and do something with returnData
 * });
 * ```
 *
 * @author Gaurav Chandra
 */
@Injectable({
	            providedIn: 'root',
            })
export class ComponentAppendService {
	currentComponentRef: ComponentRef<any> | undefined | null;
	
	constructor(private appRef: ApplicationRef) {
	}
	
	async open(component: any, data: any = {}): Promise<ComponentRef<any>> {
		// Get reference to existing component
		const existingComponent: ComponentRef<any> = this.appRef.components[0];
		// Create a view container reference
		const viewContainerRef: ViewContainerRef = existingComponent.injector.get(ViewContainerRef);
		// Get the index where the new component should be inserted (on top of all other components)
		const insertIndex = viewContainerRef.length;
		// Create component reference
		const componentRef: ComponentRef<unknown> = viewContainerRef.createComponent(component, {
			index: insertIndex,
			injector: Injector.create({providers: [{provide: 'data', useValue: data}]}),
		});
		// Attach component view
		//applicationRef.attachView(componentRef.hostView);//do not do this as it will lead to an error
		// Subscribe to the component's closed event
		// @ts-ignore
		componentRef.instance.closed.subscribe(() => {
			componentRef.destroy();
		});
		// Detect changes
		componentRef.changeDetectorRef.detectChanges();
		this.currentComponentRef = componentRef;
		return componentRef;
	}
}