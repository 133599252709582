import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { InfoPopoverComponent } from 'src/app/extra/info-popover/info-popover.component';
import { OfferAlertComponent } from 'src/app/extra/modals/offer-alert/offer-alert.component';
import { ProductDetailComponent } from 'src/app/layout/product-detail/product-detail.component';

@Injectable({
  providedIn: 'root',
})
export class ModalManagerService {
  constructor(private modalController: ModalController, private popoverController: PopoverController,
  ) { }

  // ----------------------Modals-----------------------------------------------------

  async openProductDetailModal(pId: string) {
    let modal = await this.modalController.create({
      component: ProductDetailComponent,
      cssClass: 'product-detail',
      backdropDismiss: true,
      componentProps: {
        productId: pId,
        // eventData: this.eventData
      },
    });

    modal.onDidDismiss().then((data: any) => {
      if (data?.check) { }
    });

    return await modal.present();
  }

  async openOfferPopUpModal(offerData: any) {
    const modal = await this.modalController.create({
      component: OfferAlertComponent,
      cssClass: 'offer-popup-modal',
      componentProps: {
        title: offerData.title,
        description: offerData.description,
        logoUrl: offerData.logoUrl,
        isAnimatedLogo: offerData.isAnimatedLogo || false,
        showButton: offerData.showButton,
        buttonText: offerData.buttonText,
        navigationType: offerData.navigationType,
        navigationData: offerData.navigationData,
      }
    });
    await modal.present();
  }


  // -----------------------------Popover---------------------------------------

  async presentPopover(ev: any, dynamicContent: string, duration: number = 2000) {
    // Create the popover
    const popover = await this.popoverController.create({
      component: InfoPopoverComponent,
      cssClass: 'info-popover',
      backdropDismiss: true,
      event: ev,
      dismissOnSelect: true,
      componentProps: {
        infoMessage: dynamicContent // Pass dynamic content here
      },
      mode: 'ios',
    });

    // Present the popover
    await popover.present();

    // Dismiss the popover after the specified duration
    setTimeout(async () => {
      if (await this.popoverController.getTop() === popover) {
        popover.dismiss();
      }
    }, duration);
  }
}
