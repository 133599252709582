import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private http: HttpClient) { }

  addressList() {
    return this.http.get(environment.apiUrl + `/address/list`);
  }

  addressDetails(id:any){
    return this.http.get(environment.apiUrl + `/address?addressId=${id}`);
  }

  addAddress(body: any) {
    return this.http.post(environment.apiUrl + `/address`, body);
  }

  updateAddress(body: any,id:any) {
    return this.http.put(environment.apiUrl + `/address?addressId=${id}`, body);
  }

  setDefaultAddress(body: any){
    return this.http.put(environment.apiUrl + `/address/default`, body);
  }

  deleteAddress(id: any) {
    return this.http.delete(environment.apiUrl + `/address?addressId=${id}`);
  }

   // ------------------------------store and fetch last set address---------------------------

   get defaultAddress(){
    const address = localStorage.getItem('defaultAddress');
    if(address){
      return JSON.parse(address)
    }
    else{
      return address
    }
  }

  set defaultAddress(address:any){
    const addressData = JSON.stringify(address)
    localStorage.setItem('defaultAddress',addressData);
  }

  removeDefaultAddress(){
    localStorage.removeItem('defaultAddress')
  }
}
