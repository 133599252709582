import {ChangeDetectionStrategy, Component, EventEmitter, Inject, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IonicModule}                                                                                        from '@ionic/angular';
import {OutlineButtonComponent}                                                                             from '../buttons/outline-button/outline-button.component';
import {BlockButtonComponent}                                                                               from '../buttons/block-button/block-button.component';
import {IConfirmPopup}                                                                                      from '../../interfaces';
import {NgIf}                                                                                               from '@angular/common';

@Component({
	           selector: 'daykart-confirm-popup',
	           templateUrl: './confirm-popup.component.html',
	           styleUrls: ['../../shared.scss', './confirm-popup.component.scss'],
	           changeDetection: ChangeDetectionStrategy.OnPush,
	           standalone: true,
	           imports: [
		           IonicModule,
		           OutlineButtonComponent,
		           BlockButtonComponent,
		           NgIf,
	           ],
           })
export class ConfirmPopupComponent implements OnInit, OnChanges {
	@Output() closed: EventEmitter<string | null> = new EventEmitter<string | null>();
	
	constructor(@Inject('data') public data: IConfirmPopup) {
		console.log(data);
	}
	
	ngOnInit() {
	}
	
	public ngOnChanges(changes: SimpleChanges): void {
	}
	
	public onButtonClicked(action: any): void {
		this.closed.emit(action);
	}
	
	public closeThis(): void {
		this.closed.emit(null);
	}
}