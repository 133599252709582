import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Check if user has selected a location
    const locationSelected = !!localStorage.getItem('location');

    if (locationSelected) {
      return true; // Allow access to home page
    } else {
      this.router.navigate(['/location-setup']); // Redirect to location page
      return false;
    }
  }

}
