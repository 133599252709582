import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from './account.service';
import { finalize } from 'rxjs';
import { LoadingControllerService } from './helpers/loading-controller.service';
import { MixedPanelsService } from './extensions/mixed-panels.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router, private alertController: AlertController,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private loadingService: LoadingControllerService,
    private mixpanelService: MixedPanelsService
  ) { }

  getToken() {
    return localStorage.getItem('token');
  }

  get mobileNumber() {
    return localStorage.getItem('mobile')
  }

  checkIfUserLoggedIn() {
    const isLoggedIn = this.getToken()
    if (isLoggedIn) {
      return true
    }
    else {
      return false
    }
  }



  async logoutConfirmation() {
    this.mixpanelService.track("s_logout_confirmation_popup", {})

    const translations = {
      logoutConfirmation: this.translateService.instant('logout_confirmation'),
      confirmLogoutMessage: this.translateService.instant('confirm_logout_message'),
      no: this.translateService.instant('no'),
      yesLogout: this.translateService.instant('yes_logout')
    };

    const alert = await this.alertController.create({
      header: translations.logoutConfirmation,
      message: translations.confirmLogoutMessage,
      buttons: [
        {
          text: translations.no,
          cssClass: 'primary',
          role: 'cancel',
          handler: () => {
            this.mixpanelService.track("b_no", { "screenName" : "Logout"});
          },
        },
        {
          text: translations.yesLogout,
          cssClass: 'danger',
          handler: () => {
            this.mixpanelService.track("b_yes_logout", { "screenName" : "Logout"});
            this.logout();
          },
        },
      ],
    });
    await alert.present();

  }

  set appLanguage(language: string) {
    localStorage.setItem('lang', language)
  }

  get appLanguage() {
    const lang = localStorage.getItem('lang')
    if (lang) {
      return lang
    }
    else {
      return 'en'
    }
  }

  navigateBackFromPublicPages() {
    // let returnUrl:string = ''
    // this.activatedRoute.queryParams.subscribe((params:any)=>{
    //   const backUrl = params['returnUrl'];
    //   if(backUrl === 'about-us' && this.checkIfUserLoggedIn()){
    //     returnUrl = 'account/about-us';
    //   }
    //   else if(backUrl === 'login'){
    //     returnUrl = backUrl;
    //   }
    //   else if(backUrl === undefined){
    //     returnUrl = '/'
    //   }
    //   console.log(returnUrl)
    // })

    // return returnUrl
    let returnUrl: string = '';
    const backUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

    if (backUrl === 'about-us' && this.checkIfUserLoggedIn()) {
      returnUrl = 'account/about-us';
    } else if (backUrl === 'login') {
      returnUrl = backUrl;
    } else if (backUrl === undefined) {
      returnUrl = '/';
    }

    console.log(returnUrl);
    return returnUrl;

  }

  // logout() {
  //   // localStorage.clear();
  //   const keyToRemoveOnLogout = ['token', 'userId', 'mobile', 'url', 'storeTimingModalDisplayTime']
  //   keyToRemoveOnLogout.forEach(key => {
  //     localStorage.removeItem(key);
  //   });
  //   this.router.navigate(['login']);
  // }

  logout(){
    const isCordova = localStorage.getItem('isCordova');
    if (isCordova === 'native') {
      // remove fcm token with logout if platform is android or iOS
      this.logoutFromApp();
    }
    else{
      // only remove local storage keys if platform is browser
      this.logoutFromBrowser();
    }
  }

  logoutFromBrowser() {
    const keyToRemoveOnLogout = ['token', 'userId', 'mobile', 'url', 'storeTimingModalDisplayTime']
    keyToRemoveOnLogout.forEach(key => {
      localStorage.removeItem(key);
    });
    this.router.navigate(['login']);
  }

  logoutFromApp() {
    this.loadingService.showLoading("Logging out, please wait...", 'crescent');

    this.accountService.userLogout().pipe(
      finalize(() => {
        const keyToRemoveOnLogout = ['token', 'userId', 'fcmToken', 'mobile', 'url', 'storeTimingModalDisplayTime'];
        keyToRemoveOnLogout.forEach(key => {
          localStorage.removeItem(key);
        });
        this.router.navigate(['login']);
      })
    ).subscribe({
      next: (response) => {
        console.log('Logout API call successful:', response);
        this.loadingService.dismissLoading();
      },
      error: (error) => {
        console.error('Logout API call failed:', error);
        this.loadingService.dismissLoading();
      }
    });

  }
}
