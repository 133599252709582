import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { environment } from 'src/environments/environment';
import { ProductService } from '../product.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private router: Router,
    private firebase: FirebaseX,
    private http: HttpClient,
    private productService: ProductService,
    private ngZone: NgZone,
    private utilityService: UtilityService
  ) {

    this.firebase.setAnalyticsCollectionEnabled(true);
    this.firebase.setPerformanceCollectionEnabled(true);
    this.firebase.setCrashlyticsCollectionEnabled(true);
    // this.firebase.setAnalyticsConsentMode({
    //     ANALYTICS_STORAGE: "GRANTED",
    //     AD_STORAGE: "GRANTED",
    //     AD_USER_DATA: "GRANTED",
    //     AD_PERSONALIZATION: "GRANTED",
    // });
  }

  userCurrentFcmToken() {
    return this.http.get(environment.apiUrl + `/fcmtoken`)
  }

  updateFcmToken(body: any) {
    return this.http.post(environment.apiUrl + `/fcmtoken`, body)
  }

  checkAndRequestNotificationPermissions(requested: boolean = false) {
    console.log("Checking push notification permissions");
    this.firebase.hasPermission().then(hasPermission => {
      console.log("Has push notification permission: " + hasPermission);
      if (hasPermission) {
        console.log("Remote notifications permission granted");
        this.firebase.getToken().then(token => {
          console.log("Got FCM token: " + token);
          if (token) {
            console.log('token generated and', typeof (token))
            const previousToken = localStorage.getItem('fcmToken')
            if (previousToken) {
              if (previousToken === token) {
                console.log('generated fcm token same as previous token')
              }
              else {
                this.updateFcmToken({ fcmToken: token }).subscribe((res: any) => {
                  if (res?.success) {
                    localStorage.setItem('fcmToken', token);
                    console.log('fcm token updated on DB and localstorage and saved');
                  }
                  else {
                    console.log('fcm token not updated')
                  }
                })
              }
            }
            else {
              localStorage.setItem('fcmToken', token);
              console.log('token saved to local storage');

              this.updateFcmToken({ fcmToken: token }).subscribe((res: any) => {
                if (res?.success) {
                  console.log('fcm token updated to db');
                  // localStorage.setItem('fcmToken', token);
                }
                else {

                }
              })
              // localStorage.setItem('fcmToken', token)
            }
          }
          this.setupMessageReceiver();
        }).catch(error => {
          console.log("Failed to get FCM token", error);
        });
      } else if (!requested) {
        console.log("Requesting remote notifications permission");
        this.firebase.grantPermission().then(hasPermission => {
          console.log("Permission was " + (hasPermission ? "granted" : "denied"));
          this.setupMessageReceiver();

        });
      } else {
        console.log("Notifications won't be shown as permission is denied");
      }
    });
  }

  checkAndRequestNotificationPermissions2(requested: boolean = false) {
    console.log("Checking push notification permissions");

    return new Promise<void>((resolve, reject) => {
      this.firebase.hasPermission().then(hasPermission => {
        console.log("Has push notification permission: " + hasPermission);
        if (hasPermission) {
          console.log("Remote notifications permission granted");
          this.firebase.getToken().then(token => {
            console.log("Got FCM token: " + token);
            if (token) {
              console.log('Token generated:', token);
              const previousToken = localStorage.getItem('fcmToken');
              if (previousToken && previousToken === token) {
                console.log('Generated FCM token same as previous token');
                resolve();
              } else {
                if (!previousToken) {
                  //Since no token found in local storage so store currently generated in it
                  localStorage.setItem('fcmToken', token);
                }
                this.updateFcmToken({ fcmToken: token }).subscribe((res: any) => {
                  if (res?.success) {
                    if (previousToken && previousToken !== token) {
                      //Device has previous token in local storage but that token is not same as currently generated token
                      //so update token in local storage only after successful api response
                      localStorage.setItem('fcmToken', token);
                    }
                    // localStorage.setItem('fcmToken', token);
                    console.log('FCM token updated on DB and local storage');
                  } else {
                    console.log('FCM token update failed');
                  }
                  resolve();
                });
              }
            }
            this.setupMessageReceiver();
            resolve();
          }).catch(error => {
            console.error("Failed to get FCM token", error);
            reject(error);
          });
        } else if (!requested) {
          console.log("Requesting remote notifications permission");
          this.firebase.grantPermission().then(hasPermission => {
            console.log("Permission was " + (hasPermission ? "granted" : "denied"));
            this.setupMessageReceiver();
            resolve();
          }).catch(error => {
            console.error("Failed to request notification permission", error);
            reject(error);
          });
        } else {
          console.log("Notifications won't be shown as permission is denied");
          resolve();
        }
      }).catch(error => {
        console.error("Error checking notification permission", error);
        reject(error);
      });
    });
  }

  checkUserFcmToken(token: string = '') {
    this.userCurrentFcmToken().subscribe((res: any) => {
      if (res?.success) {
        // localStorage.setItem('fcmToken', token);
        console.log('FCM token found');
      } else {
        console.log('FCM token not found');
      }
    });
  }

  updateUserFcmToken(token: string = '') {
    this.updateFcmToken({ fcmToken: token }).subscribe((res: any) => {
      if (res?.success) {
        localStorage.setItem('fcmToken', token);
        console.log('FCM token updated on DB and local storage');
      } else {
        console.log('FCM token update failed');
      }
    });
  }

  setupMessageReceiver() {
    this.firebase.onMessageReceived().subscribe(messagePayload => {
      console.log('FCM Message Received:', messagePayload);
      // this.handleNavigation(messagePayload);
      if (messagePayload?.tap === 'background' || messagePayload?.tap === 'foreground') {
        this.handleNavigation(messagePayload);
      }
    }, error => {
      console.error('Error on message received', error);
    });
  }

  handleNavigation(payload: any) {
    // Close specified popups before navigating
    // await this.utilityService.closePopups('alert', 'modal', 'popover');

    const action = payload?.action;
    const data = payload;
    const activity = payload?.activity;

    switch (activity) {
      case 'order': {
        if (action === 'navigation') {
          this.ngZone.run(() => {
            this.router.navigate(['account/order-list/order-details'], { queryParams: { orderId: data?.orderId } })
          });
          // this.router.navigate(['account/order-list/order-details'], { queryParams: { orderId: data?.orderId } })
        }
        else {

        }
        break;
      }

      case 'wallet': {
        if (action === 'navigation') {
          this.router.navigate(['account/wallet']);
        }
        else {

        }
        break;
      }

      case 'cashback': {
        if (action === 'navigation') {
          this.router.navigate(['account/wallet']);
        }
        else {

        }
        break;
      }


      case 'subscription': {
        if (action === 'navigation') {
          this.router.navigate(['/subscription'])
        }
        else {

        }
        break
      }

      case 'details': {
        if (action === 'navigation') {
          this.productService.showProductDetails(data?.productId);
        }
        else {

        }
        break
      }

      case 'category': {
        if (action === 'navigation') {
          this.ngZone.run(() => {
            this.router.navigate(['/category-list', data?.slug, data?.categoryId])
          });
          // this.router.navigate(['/category-list', data?.slug, data?.categoryId])
        }
        else {

        }
        break
      }

      case 'productList': {
        if (action === 'navigation') {
          this.ngZone.run(() => {
            this.router.navigate(['/product-list', 'banner'], { queryParams: { bannerId: '', sectionId: data?._id } });
          });
          // this.router.navigate(['/product-list','banner'], { queryParams: { bannerId: '', sectionId: data?._id } })
        }
        else {

        }
        break
      }

      case 'bannerProducts': {
        if (action === 'navigation') {
          this.ngZone.run(() => {
            this.router.navigate(['/product-list', 'banner'], { queryParams: { bannerId: data?.bannerId, sectionId: data?.sectionId } });
          });
        }
        else {

        }
        break
      }


      default: {
        this.router.navigate([''])
      }
    }
  }


}
