<ion-header>
  <ion-row class="modal-header">
    <ion-col size="11">
      <h5><span> {{ title | translate }}  {{ subTitle }}</span></h5>
    </ion-col>
    <ion-col size="1" class="ion-text-end">
      <ion-icon name="close-outline" (click)="closeModal()"></ion-icon>
    </ion-col>
  </ion-row>
</ion-header>
