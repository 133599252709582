import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent  implements OnInit {

  @Input() buttonText:string = 'Go To Homepage';
  @Input() buttonRoute:string = '/'
  @Input() message:string = 'Page Not Found'


  constructor(private router:Router) { }

  ngOnInit() {
  }

  navigateTo(){
    this.router.navigate(['/'])
  }

}
