import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CustomerSupportComponent } from 'src/app/extra/modals/customer-support/customer-support.component';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';

@Component({
  selector: 'app-support-icon',
  templateUrl: './support-icon.component.html',
  styleUrls: ['./support-icon.component.scss'],
})
export class SupportIconComponent  implements OnInit {

  @Input() parentScreenName:any;
  @Input() supportIconColor: string | undefined;

  defaultIconColor: string = '#01b4bb';

  constructor(private modalController: ModalController,private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {
    console.log('color is',this.supportIconColor)
  }

  async CustomerSupportModal() {
    this.mixpanelService.track("s_support_popup", { "screenName" : this.parentScreenName})

    let modal = await this.modalController.create({
      component: CustomerSupportComponent,
      cssClass: 'customer-support-modal',
      backdropDismiss: true,
      componentProps: {
        parentScreenName: this.parentScreenName
      }
    })

    return await modal.present();
  }

}
