import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ViewWillEnter } from '@ionic/angular';
import { LocationPermissionService } from 'src/app/services/extensions/location-permission.service';
import { LocationService } from 'src/app/services/location.service';
import { StoreService } from 'src/app/services/store.service';
import { ToastService } from 'src/app/services/toast.service';
import { LocationPermissionModalComponent } from '../location-permission-modal/location-permission-modal.component';
import { NotificationsService } from 'src/app/services/extensions/notifications.service';
import { BuyAtStoreComponent } from '../buy-at-store/buy-at-store.component';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';

@Component({
  selector: 'app-set-user-location',
  templateUrl: './set-user-location.component.html',
  styleUrls: ['./set-user-location.component.scss'],
})
export class SetUserLocationComponent implements OnInit, ViewWillEnter {

  @Input() placeName: string = '';
  @Input() sectionType: string = 'search'

  selectedAddress: string = '';
  showProgressBar: boolean = false;

  @Input() isStoreAvailable: number = 2;

  constructor(private router: Router,
    private modalController: ModalController,
    private toastService: ToastService,
    private locationService: LocationService,
    private storeService: StoreService,
    private permissionService: LocationPermissionService,
    private locationPermissionService: LocationPermissionService,
    private notificationService: NotificationsService,
    private alertController: AlertController,
    private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {
    const isLocationAlreadySet = this.locationService.coordinate.getValue() !== null;
    if (!isLocationAlreadySet) {
      this.useCurrentLocation();
    }
  }

  ionViewWillEnter() {
  }

  get currentAddress() {
    return localStorage.getItem('currentAddress')
  }

  checkStoreAvailabilityOnCurrentLocation() {
    this.locationService.coordinate.subscribe((location: any) => {
      if (location) {
        this.findCurrentAddress(location);
      }
      else {
        this.locationService.requestPermissions();
      }
    });
  }

  findCurrentAddress(location: any) {
    const point = { lat: location?.latitude, lng: location?.longitude };
    this.locationService.pointLocationAddress(point).subscribe(async (res: any) => {

      if (!res?.isStoreAvailable) {
        this.showProgressBar = false;
        this.isStoreAvailable = 0;
        this.placeName = res?.address;
        // this.selectNewLocation(res?.isStoreAvailable, res?.address)
        // this.toastService.showToast('Daykart services is not available at your location')
      }
      else {
        this.showProgressBar = false;
        const isModal = await this.modalController.getTop();
        if (isModal) {
          isModal.dismiss('', 'close')
        }
        this.locationService.setLocation({ latitude: location?.latitude, longitude: location?.longitude, accuracy: 100 })

        this.isStoreAvailable = 1;

        localStorage.setItem('storeDistance', res?.distance);
        localStorage.setItem('storeId', res?.nearestStore?.storeId);
        localStorage.setItem('storeContactNo', res?.nearestStore?.mobile);
        localStorage.setItem('currentAddress', res?.address);
        localStorage.setItem('estimatedDeliveryTime', res?.time)
      }

    });
  }

  async openStoresModal() {
    this.mixpanelService.track("b_visit_our_stores", { "screenName" : "Select Delivery Location"});

    let modal = await this.modalController.create({
      component: BuyAtStoreComponent,
      cssClass: 'buyatStore-modal',
      backdropDismiss: true,
      componentProps: {
        parentScreenName: "Store location popup"
      }
    })

    return await modal.present();
  }

  dismiss() {
    this.modalController.dismiss('', 'close');
  }

  // ---------------------------Location select from search----------------------------------

  searchInput: any = '';
  dataFound: number = 2;
  searchSuggestions: any;


  searchLocation() {
    if (this.searchInput === '') {
      this.searchSuggestions = [];
      return;
    }
    this.locationService.searchPlace({ input: this.searchInput }).subscribe((res: any) => {
      if (res.success) {
        this.searchSuggestions = res.data.predictions;
        if (this.searchSuggestions.length === 0) {
          this.dataFound = 0;
        }
        else {
          this.dataFound = 1;
        }
      }
    },
      (error) => {
        this.toastService.showToast(error?.message)
      })

  }

  selectAddress(prediction: any) {
    this.resetSearchField();
    this.showProgressBar = true;
    this.newLocation(prediction.place_id);
  }

  newLocation(placeId: string) {
    this.locationService.placeDetails({ placeId: placeId }).subscribe((res: any) => {
      if (res.success) {
        this.showProgressBar = false;
        this.isStoreAvailable = 1;
        this.locationService.setLocation({ latitude: res?.location?.lat, longitude: res?.location?.lng, accuracy: 100 })
        // this.storeService.saveStoreIdAndDistance(res?.nearestStore?.storeId,res?.distance);
        localStorage.setItem('storeDistance', res?.distance);
        localStorage.setItem('storeId', res?.nearestStore?.storeId);
        localStorage.setItem('currentAddress', res?.address);
        localStorage.setItem('estimatedDeliveryTime', res?.time);
        localStorage.setItem('storeContactNo', res?.nearestStore?.mobile);

        this.dismiss();
      }
      else {
        this.showProgressBar = false;
        this.isStoreAvailable = 0;
        this.placeName = res?.address;
      }
    },
      (error) => {
        this.toastService.showToast(error?.message);
        this.showProgressBar = false;
      })
  }

  resetSearchField() {
    this.searchInput = '';
    this.searchSuggestions = [];
    this.dataFound = 2;
  }

  // ---------------------------Location select from current location----------------------------------

  async useCurrentLocation() {
    this.showProgressBar = true;
    this.resetSearchField();
    this.askForPermissions();
  }

  async askForPermissions() {
    try {
      const locationGranted = await this.askForLocation();
      if (locationGranted.success) {
        //to call notification receiver
        console.log("Location permission granted");
        await this.notificationService.checkAndRequestNotificationPermissions2();
        console.log("Notification permission granted");
        this.showProgressBar = false;
        this.dismiss();
      }
      else if(!locationGranted.success){
        console.log("Location permission granted");
        await this.notificationService.checkAndRequestNotificationPermissions2();
        console.log("Notification permission granted");
        this.showProgressBar = false;
        this.isStoreAvailable = 0;
        this.placeName = locationGranted.data;
      }
      else {
        console.log("location permission not granted by user");
        await this.handleLocationPermissionDenied();
        this.showProgressBar = false;
      }
    } catch (error) {
      console.log("got error in permission granted by user")
      console.error('Error requesting permissions', error);
      this.showProgressBar = false;
    }
  }

  // async askForPermissions() {
  //   try {
  //     const locationGranted = await this.askForLocation();
  //     if (locationGranted) {
  //       //to call notification receiver
  //       console.log("Location permission granted");
  //       await this.notificationService.checkAndRequestNotificationPermissions2();
  //       console.log("Notification permission granted");
  //       this.showProgressBar = false;
  //       this.dismiss();
  //     }
  //     else {
  //       console.log("location permission not granted by user");
  //       // const alert = await this.alertController.getTop();
  //       // const modal = await this.modalController.getTop();
  //       // console.log("modal",modal, "alert",alert)
  //       // if (alert) {
  //       //   console.log("alert found open")
  //       // }
  //       // else {
  //       //   console.log("alert not found open")

  //       //   await this.handleLocationPermissionDenied();
  //       // }
  //       await this.handleLocationPermissionDenied();
  //       this.showProgressBar = false;
  //     }
  //   } catch (error) {
  //     console.log("got error in permission granted by user")
  //     console.error('Error requesting permissions', error);
  //     this.showProgressBar = false;
  //   }
  // }

  async askForLocation(): Promise<{ success: boolean, data?: any }> {
    try {
      const permissionGranted: any = await this.locationPermissionService.askForLocationPermission();
      if (permissionGranted) {
        const locationServicesEnabled = await this.locationPermissionService.turnOnLocationServices();
        if (locationServicesEnabled) {
          const currentLocation: any = await this.locationPermissionService.findMyCurrentLocation();
          // Call the API to check if the location is available for the store
          const isLocationAvailable = await this.checkLocationAvailability(currentLocation);

          if (isLocationAvailable.isAvailable) {
            this.locationService.setLocation(currentLocation);
            return { success: true, data: isLocationAvailable.address };
          }
          else {
            return { success: false, data: isLocationAvailable.address };
          }
          // this.locationService.setLocation(currentLocation);
          // return { success: true, data: currentLocation };
        } else {
          return { success: false, data: 'Location services not enabled' };
        }
      } else {
        return { success: false, data: 'Permission not granted' };
      }
    } catch (error) {
      console.error('Error requesting location permission', error);
      return { success: false, data: error };
    }
  }

  async checkLocationAvailability(currentLocation: any): Promise<{ isAvailable: boolean, address?: string }> {
    const point = { lat: currentLocation?.latitude, lng: currentLocation?.longitude };

    return new Promise((resolve, reject) => {
      this.locationService.pointLocationAddress(point).subscribe(
        (response: any) => {
          if (response?.isStoreAvailable) {
            resolve({ isAvailable: true, address: response?.address });
          } else {
            resolve({ isAvailable: false, address: response?.address });
          }
        },
        (error: any) => {
          console.error('Error checking location availability', error);
          reject({ isAvailable: false });
        }
      );
    });
  }

  // async askForLocation(): Promise<boolean> {
  //   try {
  //     const permissionGranted: any = await this.locationPermissionService.askForLocationPermission();
  //     if (permissionGranted) {
  //       const locationServicesEnabled = await this.locationPermissionService.turnOnLocationServices();
  //       if (locationServicesEnabled) {
  //         const currentLocation: any = await this.locationPermissionService.findMyCurrentLocation();
  //         this.locationService.setLocation(currentLocation)
  //         return permissionGranted;
  //       }
  //       else {
  //         // this.handleLocationServicesNotEnabled();
  //         return false;
  //       }
  //     }
  //     else {
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error('Error requesting location permission', error);
  //     return false;
  //   }
  // }

  async handleLocationPermissionDenied() {
    const modal = await this.modalController.create({
      component: LocationPermissionModalComponent,
      backdropDismiss: true,
      cssClass: "location-not-allowed-modal",
      initialBreakpoint: .9,
      breakpoints: [.9],
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data === 'requestPermission') {
      this.askForPermissions();
    }
    else if (data === 'openSettings') {
      this.askForPermissions();
    }
    else if (data === 'granted') {
      this.askForPermissions();
    }
  }

  async handleLocationServicesNotEnabled() {
    const alert = await this.alertController.create({
      header: 'Enable Location Services',
      message: 'Please enable location services for the app to work correctly.',
      buttons: [
        {
          text: 'Open Settings',
          handler: () => {
            this.locationPermissionService.openLocationSettings()
          }
        }
      ],
      backdropDismiss: false // Prevents the user from dismissing the alert
    });

    await alert.present();
  }

}
