import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../product.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router,
    private ngZone: NgZone,
    private productService: ProductService,) { }

  private navigateTo(path: string[], queryParams: any = {}) {
    console.log(path, '  and ', queryParams)
    this.ngZone.run(() => {
      this.router.navigate(path, { queryParams });
    });
  }

  handleNavigation(payload: any, returnUrl: string = '') {
    const action = payload?.action;
    const data = payload;
    const activity = payload?.activity;

    if (action !== 'navigation') {
      return; // If action is not 'navigation', we do nothing
    }

    // Common queryParams including returnUrl
    const queryParams = { backButtonRoute: returnUrl || '/' };

    console.log(queryParams)

    switch (activity) {
      case 'order': {
        this.navigateTo(['account/order-list/order-details'], { orderId: data?.orderId, ...queryParams });
        break;
      }

      case 'wallet':
      case 'cashback': {
        this.navigateTo(['account/wallet'], queryParams);
        break;
      }

      case 'subscription': {
        this.navigateTo(['/subscription']);
        break;
      }

      case 'details': {
        this.productService.showProductDetails(data?.productId);
        break;
      }

      case 'category': {
        this.navigateTo(['/category-list', data?.slug, data?.categoryId], queryParams);
        break;
      }

      case 'productList': {
        this.navigateTo(['/product-list', 'banner'], { bannerId: '', sectionId: data?._id, ...queryParams });
        break;
      }

      case 'bannerProducts': {
        this.navigateTo(['/product-list', 'banner'], {
          bannerId: data?.bannerId,
          sectionId: data?.sectionId,
          ...queryParams
        });
        break;
      }

      default: {
        this.navigateTo([''], queryParams); // Default navigation
      }
    }
  }
}
