<ion-header>
  <ion-row>
    <ion-col size="12">
      <h6>{{ title }}</h6>
    </ion-col>
  </ion-row>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <div>
    <p>{{ message }}</p>
    <div class="btn-container">
      <ion-button *ngIf="permissionDenialType === 'denied_once'" expand="block" class="common-btn"
        (click)="requestPermission()">Allow Location Access</ion-button>
      <div>
        <ion-button *ngIf="this.permissionDenialType === 'denied_always'" expand="block" class="common-btn"
          (click)="openSettings()">Go to Settings</ion-button>
      </div>
      <ion-row *ngIf="
          permissionDenialType !== 'denied_always' &&
          permissionDenialType !== 'denied_once'
        ">
        <ion-col size="6">
          <ion-button expand="block" class="common-btn" (click)="openLocationSettings()">Open Settings
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button expand="block" class="common-btn" (click)="checkPermissionAgain()">Try again
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>