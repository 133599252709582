<app-title-header [title]="'returnPolicy'" [backButtonRoute]="returnUrl" [parentScreenName]="'Return Policy Page'">
</app-title-header>

<ion-content class="ion-padding">
  <ion-row>
    <ion-col size="12">
      <p>
        At Daykart, we strive to provide you with a seamless shopping experience. If you are not entirely satisfied with your purchase, we offer a straightforward refund policy to ensure your peace of mind.
      </p>
    </ion-col>
    <ion-col size="12">
      <ol>
        <li>
          <b>Eligibility:</b>
          <ul>
            <li>
              To be eligible for a refund, your item must be unused, in its original packaging, and in the same condition as you received it.
            </li>
            <li>
              Perishable goods such as fresh produce, dairy, and bakery items are not eligible for refunds due to their nature.
            </li>
          </ul>
        </li>
        <li>
          <b>Refund Process:</b>
          <ul>
            <li>
              If you are dissatisfied with your purchase, please contact our customer support team within 24 to 48 hours of receiving your order to initiate the refund process.
            </li>
            <li>
              Our team will guide you through the necessary steps and may require proof of purchase or photographic evidence to facilitate the refund.
            </li>
          </ul>
        </li>
        <li>
          <b>Refund Method:</b>
          <ul>
            <li>
              Refunds will be issued via the original payment method used for the purchase.
            </li>
            <li>
              Please allow 24 to 48 business hours for the refund to reflect in your account, depending on your bank or payment provider.
            </li>
          </ul>
        </li>
        <li>
          <b>Exceptions:</b>
          <ul>
            <li>
              Certain items may not be eligible for refunds, such as gift cards, downloadable products, or items marked as final sale.
            </li>
            <li>
              Refunds for promotional or discounted items will be processed at the discounted price paid at the time of purchase.
            </li>
          </ul>
        </li>
        <li>
          <b>Contact Us:</b>
          <ul>
            <li>
              If you have any questions or concerns regarding our refund policy or need assistance with a refund request, please reach out to our customer support team.
            </li>
          </ul>
        </li>
      </ol>
    </ion-col>
    <ion-col size="12">
      We are committed to ensuring your satisfaction with every purchase at Daykart. Thank you for choosing us as your trusted grocery delivery partner.
    </ion-col>
  </ion-row>

  <!-- Additional sections of the return policy can be added as needed -->
</ion-content>
