import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { StoreService } from 'src/app/services/store.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-buy-at-store',
  templateUrl: './buy-at-store.component.html',
  styleUrls: ['./buy-at-store.component.scss'],
})
export class BuyAtStoreComponent implements OnInit {

  @Input() parentScreenName: any;

  storeData:any=[];

  constructor(private modalController: ModalController, private utilityService: UtilityService,
    private storeService: StoreService, private toastService: ToastsService,
    private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() { 
    this.storeList()
  }

  storeList(){
    this.storeService.storeList().subscribe((res:any)=>{
      if(res?.success){
        this.storeData = res?.stores;
      }
      else{
        this.toastService.showToast(res?.message)
      }
    })
  }

  availableStores = [
    {
      storeName: "Store 1",
      storeAddress: "Shop No. 36, Galleria Market, River Heights Rd, Sehani Khurd, Raj Nagar Extension, Ghaziabad, Uttar Pradesh 201017",
      storeContactNumber: "9311049438"
    },
    {
      storeName: "Store 2",
      storeAddress: "Shop No 1,2,3,4,5,6 and 7, NH-24, Village Mehrauli, Pargana Dasna, Golf Links, Ghaziabad, Ghaziabad, Uttar Pradesh, 201002",
      storeContactNumber: "8700965679"
    },

  ];

  contactStore(mobile: any) {
    this.mixpanelService.track("b_call_us", { "screenName" : this.parentScreenName});
    this.utilityService.callToMobileNumber(mobile)
  }

  showNearbyStoreOnMap(lat: any, lng: any) {
    this.mixpanelService.track("b_get_directions", { "screenName" : this.parentScreenName});
    this.utilityService.openGoogleMaps(lat, lng)
  }

  closeModal() {
    this.modalController.dismiss()
  }
}
