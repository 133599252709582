import { Injectable } from '@angular/core';
import { Mixpanel } from '@awesome-cordova-plugins/mixpanel/ngx';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MixedPanelsService {

  constructor(private mixpanelService: Mixpanel) { }

  initializeMixpanel() {
    // Initialize Mixpanel with your project token
    this.mixpanelService.init(environment.mixpanelToken)

    const userId = localStorage.getItem('userId');
    if (userId) {
      //If logged in , identify the user based on the unique id
      this.identify(userId)
    }
  }

  track(event: string, properties?: any) {
    const userId = localStorage.getItem('userId');
    if (userId) {
      Object.assign(properties, { userId: userId })
    }

    // Check if the session is from Branch
    const isBranchSession = sessionStorage.getItem('isBranchSession') === 'true';
    if (isBranchSession) {
      // Retrieve Branch properties and merge with existing properties
      const branchProperties = this.handleBranchSession();
      Object.assign(properties, branchProperties);
    }

    // console.log(properties, event);
    this.mixpanelService.track(event, properties);
  }

  handleBranchSession(): any {
    const branchDataString = sessionStorage.getItem('branchData');
    const branchData = branchDataString ? JSON.parse(branchDataString) : null;

    if (branchData) {
      // Extract Branch data
      const branchProperties: any = {
        campaign: branchData['~campaign'] || 'Unknown Campaign',
        channel: branchData['~channel'] || 'Unknown Channel',
        feature: branchData['~feature'] || 'Unknown Feature',
        marketingTitle: branchData['$marketing_title'] || 'Unknown Title',
        tags: branchData['~tags'] || [],
        creationSource: branchData['~creation_source'] || 'Unknown Source',
        referringLink: branchData['~referring_link'] || 'Unknown Link',
      };

      return branchProperties; // Return the branch properties
    }

    return {}; // Return an empty object if no branch data is found
  }



  identify(userId: string) {
    this.mixpanelService.identify(userId);
  }

  alias(alias: string) {
    this.mixpanelService.alias(alias);
  }

  peopleSet(properties: any) {
    // this.mixpanelService.people.set(properties);
  }
}
