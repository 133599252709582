import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-store-business-hours',
  templateUrl: './store-business-hours.component.html',
  styleUrls: ['./store-business-hours.component.scss'],
})
export class StoreBusinessHoursComponent implements OnInit {

  @Input() modalData: any;

  constructor(private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit() { }

  proceedToShopping(){
    this.modalController.dismiss({status:true})
  }

}
