
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IonicSlides, IonInput, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '../services/helpers/alerts.service';
import { HttpClient } from '@angular/common/http';

import { MaskitoOptions, MaskitoElementPredicate } from '@maskito/core';
// import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
import { UtilityService } from '../services/utility.service';
import { ToastsService } from '../services/helpers/toasts.service';
import { CartService } from '../services/cart.service';
import { MixedPanelsService } from '../services/extensions/mixed-panels.service';
import { ModalManagerService } from '../services/misc/modal-manager.service';

import { config } from 'src/app/config'
import Swiper from 'swiper';

import { SmsRetrieverApi, SmsRetrieverStatus } from '@awesome-cordova-plugins/sms-retriever-api/ngx';
import { LoadingControllerService } from '../services/helpers/loading-controller.service';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../services/extensions/notifications.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, ViewWillEnter, ViewWillLeave {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  otpSent: Boolean = false;
  agreedToTerms: Boolean = false;
  hash: any
  invalidMobileNumber: boolean = false;
  wrongOtp: boolean = false;

  otpTimer: number = 30;
  timerInterval: any;
  formattedNumber: string = '';

  redirectUrl: string = ''
  enableResendOtp: boolean = false;

  otpRequestInProgress: boolean = false;
  otpVerifyInProgress: boolean = false;

  carouselImages: any = [];

  // --------------------------otp read-------------------------------
  public hashString = '';
  public phoneNumber = '';
  private smsWatcherSubscription!: Subscription;
  private smsArriveSubscription!: Subscription;


  // public isRetrieverStarted = new BehaviorSubject(false);

  constructor(
    private loginService: LoginService,
    private router: Router,
    private alertService: AlertsService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    // private smsRetriever: SmsRetriever,
    private utilityService: UtilityService,
    private toastService: ToastsService,
    private cartService: CartService,
    private mixpanelService: MixedPanelsService,
    private modalManagerService: ModalManagerService,
    private notificationService: NotificationsService,

    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly smsRetrieverApi: SmsRetrieverApi,
    private loadingService: LoadingControllerService
  ) {
    // this.smsRetriever.getAppHash()
    //   .then((res: any) => alert(res))
    //   .catch((error: any) => console.error(error));
    this.handleSmsReceive();
  }



  get mobile() {
    // return this.mobileForm.controls.mobile.value?.split(' ')[2]
    return this.mobileForm.controls.mobile.value
  }

  @ViewChild('autofocus', { static: false }) input!: IonInput;

  ngOnInit() {
    this.loadCarouselImages();

    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation()?.extras.state) {
        this.redirectUrl = this.router.getCurrentNavigation()?.extras.state?.['redirectUrl'];
      }
      else {
        this.redirectUrl = '';
      }
    });

    this.mixpanelService.track("s_mobileregister", {})

    // this.appGetHashString();
    // this.appGetPhoneNumber();
  }

  async appGetHashString() {
    this.hashString = await this.smsRetrieverApi.getHashString();
    alert("app hash : "+ this.hashString);
  }

  async appGetPhoneNumber() {
    this.phoneNumber = await this.smsRetrieverApi.getPhoneNumber();
    this.changeDetectorRef.detectChanges();
    console.log('user mobile number : ', this.phoneNumber);
  }

  // Load carousel images for login screen
  loadCarouselImages() {
    this.http.get<any[]>('assets/metadata/login-carousel.json').subscribe((data) => {
      this.carouselImages = data;
    });
  }

  ionViewWillEnter() {
    this.otpTimer = 30;
    this.formattedNumber = '+91 73*******76'
    setTimeout(() => this.input.setFocus(), 300);
  }

  mobileForm = new FormGroup({
    mobile: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('(^[6-9][0-9]{9}$)'),

      // Validators.pattern('([+]91 - [1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])'),
    ]))
  });

  otpForm = new FormGroup({
    otp: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^[0-9]{4,4}$')
    ]))
  });

  validations = {
    'mobile': [
      // { type: 'required', message: 'Mobile no. is required.' },
      { type: 'pattern', message: 'Please enter valid 10-digit mobile number' },
    ],
    'otp': [
      { type: 'pattern', message: 'Please enter valid 4-digit OTP' },
    ],
  };

  agreeToTerms(event: any) {
    const isChecked = event?.detail?.checked;
    if (isChecked) {
      this.agreedToTerms = true;
    }
    else {
      this.agreedToTerms = false;
    }
  }

  skipLogin() {
    this.router.navigateByUrl('/')
  }

  goBack() {
    this.otpForm.reset();
    this.otpSent = false;
  }

  sendOtp() {
    // if(!this.agreedToTerms){
    //   this.alertService.showAlert('','Please accept the terms and conditions to proceed.',1,false,false)
    // }
    if (this.mobileForm.controls.mobile.hasError('required')) {
      this.toastService.showToast("Mobile field is empty")
    }
    else if (this.mobileForm.controls.mobile.invalid) {
      this.mobileForm.controls.mobile.markAsDirty();
    }
    else {
      const payload = {
        mobile: this.mobile
      }

      this.mixpanelService.track("b_login_signup", { "screenName": "Login" });
      this.mixpanelService.track("s_otp", {});

      this.otpRequestInProgress = true;

      this.loginService.otp(payload).subscribe((res: any) => {
        if (res.success) {
          this.toastService.showToast(res?.message);
          this.formatMobileNumber(String(payload.mobile))
          this.startTimer();
          this.otpSent = true;
          this.hash = res.hash;
          // this.startSmsWatch();
          this.otpRequestInProgress = false;

          this.startSmsWatcher();
        }
        else {
          this.toastService.showToast(res?.message)
          this.invalidMobileNumber = true;
          this.otpRequestInProgress = false;
        }
      })

    }
  }

  // startSmsWatch() {
  //   this.smsRetriever.startWatching()
  //     .then((res: any) => {
  //       console.log(res);
  //       const otpRegex = /\b\d{4}\b/;
  //       // Extracting the OTP from the message using the regular expression
  //       const match = res.Message.match(otpRegex);
  //       if (match) {
  //         const otp = match[0];
  //         this.otpForm.controls.otp.setValue(otp);
  //         setTimeout(() => {
  //           this.signIn();
  //         }, 2000);
  //       } else {
  //         console.log("No OTP found in the message.");
  //       }
  //     })
  //     .catch((error: any) => console.error(error));
  // }

  formatMobileNumber(rawMobileNumber: string) {
    const countryCode = '+91 ';
    this.formattedNumber = countryCode + rawMobileNumber.substring(0, 2) + 'xxxxxxxx' + rawMobileNumber.substring(rawMobileNumber.length - 2);
  }


  startTimer() {
    this.stopTimer();
    this.otpTimer = 30;
    this.timerInterval = setInterval(() => {
      if (this.otpTimer > 0) {
        this.otpTimer--;
      } else {
        this.enableResendOtp = true;
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timerInterval);
  }

  async signIn() {
    if (this.otpForm.controls.otp.invalid) {
      this.otpForm.controls.otp.markAsDirty();
    }
    else {
      const fcm = localStorage.getItem('fcmToken')
      const fcmId = fcm ? fcm : '';
      let fcmTokenValue = '';

      // If FCM token is not available, generate it
      if (!fcm) {
        try {
          fcmTokenValue = await this.notificationService.generateFcmTokenForLogin();
        } catch (error) {
          console.error('Error generating FCM token:', error);
        }
      }

      const loginPayload = {
        mobile: this.mobile,
        otp: this.otpForm.controls.otp.value,
        hash: this.hash,
        // fcmToken: fcmId,
        fcmToken: fcmTokenValue
      }

      this.mixpanelService.track("b_verify_otp", { "screenName": "OTP" });

      this.otpVerifyInProgress = true;

      this.loginService.verifyOtp(loginPayload).subscribe(res => {
        if (res.success) {
          localStorage.setItem('token', res?.token);
          localStorage.setItem('mobile', res?.mobile)
          localStorage.setItem('userId', res?.userId)
          //Get cart on successful login
          this.cartService.reloadCartData();
          if (this.redirectUrl != '') {
            this.router.navigateByUrl(this.redirectUrl)
            history.replaceState({}, '', '/');
            // this.router.navigate([this.redirectUrl], { replaceUrl: true })
          }
          else {
            // this.router.navigate(['/'], { replaceUrl: true });

            // Updated flow, so go to location setup on successful login
            this.router.navigate(['/location-setup'], { replaceUrl: true });

            const showNewUserWelcome: boolean = res?.isNewUser && res?.showNewUserAlert;
            if (showNewUserWelcome) {
              const popupData = res?.newUserAlertData;
              this.modalManagerService.openOfferPopUpModal(popupData);
            }
          }
          this.otpVerifyInProgress = false;
        }
        else {
          this.wrongOtp = false;
          this.toastService.showToast(res?.message);
          this.otpVerifyInProgress = false;
        }
      })
    }

  }

  resendOtp() {
    this.enableResendOtp = false;
    const payload = {
      mobile: this.mobile
    }
    this.loginService.resendOtp(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastService.showToast(res?.message);
        this.otpSent = true;
        this.startTimer();
        this.hash = res.hash;
        // this.startSmsWatch();
        this.startSmsWatcher();
      }
      else {
        this.invalidMobileNumber = true;
        this.toastService.showToast(res?.message);
      }
    })


  }

  navigateTo(route: string = '') {
    this.router.navigate([route], { queryParams: { returnUrl: 'login' } })
  }

  ionViewWillLeave() {
    this.mobileForm.reset();
    this.otpForm.reset();
    this.otpSent = false;
    clearInterval(this.timerInterval);
    this.stopSmsWatcher();
    this.smsWatcherSubscription.unsubscribe();
    this.smsArriveSubscription.unsubscribe();
  }

  swiperConfig = {
    autoplay: {
      delay: 1000
    }
  }

  swiperReady() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }
  swiperModules = [IonicSlides]

  // --------------------------------------SMS Reader------------------------------------------

  startSmsWatcher() {
    this.smsWatcherSubscription = this.smsRetrieverApi.startWatch().subscribe({
      next: (status) => {
        console.log('sms watcher status:', status);
        // this.changeDetectorRef.detectChanges();
        if (status === SmsRetrieverStatus.Done || status === SmsRetrieverStatus.AlreadyStarted) {
          if (this.smsWatcherSubscription) {
            console.log("unsubscribing in sms watcher.....")
            this.smsWatcherSubscription.unsubscribe();
          }
        }
      },
      error: (error) => {
        console.error(error)
        // this.changeDetectorRef.detectChanges();
        if (this.smsWatcherSubscription) {
          this.smsWatcherSubscription.unsubscribe();
        }
        this.stopSmsWatcher();
      },
      complete: () => console.info('sms watch complete')
    })
  }

  handleSmsReceive() {
    this.smsArriveSubscription = this.smsRetrieverApi.onSMSArrive().subscribe((smsValue) => {
      console.log("sms value recieved", smsValue);

      // Check if the smsValue contains the message property
      if (smsValue && smsValue.message) {
        const message = smsValue.message;

        // Extract the OTP using the same regular expression
        const otpMatch = message.match(/OTP (\d{4})/);

        if (otpMatch && otpMatch[1]) {
          const otp = otpMatch[1];  // Extracted OTP
          console.log('Extracted OTP:', otp);
          // Use the OTP here as needed (e.g., autofill in the form);
          this.otpSuccessMatch(otp)
        } else {
          console.log('OTP not found in the message');
        }
      } else {
        console.log('Invalid SMS format');
      }
    })
  }

  otpSuccessMatch(otp:any){
    this.loadingService.showLoading("Fetching OTP...", 'crescent');
    // Use the OTP as needed, e.g., fill in the form
    this.otpForm.controls.otp.setValue(otp);
    setTimeout(() => {
      this.loadingService.dismissLoading();
      this.signIn();
    }, 2000);

  }

  async stopSmsWatcher() {
    const result = await this.smsRetrieverApi.stopWatch();
    console.log('appStopWatch', result);
  }
}
