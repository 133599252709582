<app-header-skeleton></app-header-skeleton>
<ion-content>
  <!-- --------product--cart---list----- -->
  <ion-row class="ion-padding-horizontal" *ngFor="let item of [0, 1, 2, 3]">
    <ion-col size="3">
      <ion-skeleton-text
        [animated]="true"
        style="width: 100%; height: 10vh; border-radius: 10px"
      ></ion-skeleton-text>
    </ion-col>
    <ion-col size="5" class="ion-padding-top">
      <ion-skeleton-text
        [animated]="true"
        style="width: 90%"
      ></ion-skeleton-text>

      <ion-skeleton-text
        [animated]="true"
        style="width: 70%"
      ></ion-skeleton-text>
      <ion-skeleton-text
        [animated]="true"
        style="width: 60%"
      ></ion-skeleton-text>
    </ion-col>
    <ion-col size="4" class="ion-padding-top ion-text-center">
      <ion-skeleton-text
        [animated]="true"
        style="width: 75%; height: 4vh; border-radius: 10px"
      ></ion-skeleton-text>
    </ion-col>
  </ion-row>
  <!-- --------------end----------- -->
  <!-- --------category-card---- -->
  <div style="border: 1px solid #ccc; border-radius: 5px; margin: 0 1rem;">
    <ion-row>
      <ion-col size="12">
        <ion-skeleton-text
          [animated]="true"
          style="width: 50%; height: 13px"
        ></ion-skeleton-text>
      </ion-col>
    </ion-row>
  <ion-row *ngFor="let item of [0, 1, 2, 4, 5]">
    <ion-col size="1">
      <ion-skeleton-text
        [animated]="true"
        style="width: 100%; height: 2.5vh; border-radius: 50%"
      ></ion-skeleton-text>
    </ion-col>
    <ion-col size="6" >
      <ion-skeleton-text
        [animated]="true"
        style="width: 60%"
      ></ion-skeleton-text>
    </ion-col>
    <ion-col size="5">
      <ion-skeleton-text
        [animated]="true"
        style="width: 75%;"
      ></ion-skeleton-text>
    </ion-col>
  </ion-row>
  </div>
</ion-content>

<ion-footer>
   <!-- --------------footer----------- -->
   <ion-row>
    <ion-col size="12">
      <ion-skeleton-text
        [animated]="true"
        style="width: 100%; height: 7vh; border-radius: 10px"
      ></ion-skeleton-text>
    </ion-col>
  </ion-row>
  <!-- --------------end----------- -->
</ion-footer>