import {inject, Injectable} from '@angular/core';
import {StorageService}     from './storage.service';

@Injectable({
	            providedIn: 'root',
            })
/**
 * In memory cache service to store and retrieve data. There is a fallback to indexed db using ionic storage
 * @author Gaurav Chandra
 * @example
 * const cachedData = this.cacheService.retrieveData(cacheKey);
 * this.cacheService.cacheData(cacheKey, data);
 */
export class CacheService {
	private cache: Map<string, any> = new Map();
	private clearCacheInterval: any;
	private storageService: StorageService = inject(StorageService);
	private readonly intervalDuration: number = 10 * 60 * 1000; // 10 minutes in milliseconds
	constructor() {
	}
	
	// Cache data
	cacheData(key: string, data: any): void {
		this.cache.set(key, data);
		this.storageService.set(key, data);
	}
	
	// Retrieve cached data
	async retrieveData(key: string): Promise<any> {
		const data = this.cache.get(key);
		if (!data) {
			return await this.storageService.get(key);
		}
		return data;
	}
	
	// Clear cached data
	clearData(key: string): void {
		this.cache.delete(key);
		this.storageService.remove(key);
	}
	
	// Clear all cached data
	clearAllData(): void {
		this.cache.clear();
		this.storageService.clear();
	}
	
	// Start the interval to clear cache
	private startClearCacheInterval(): void {
		if (!this.clearCacheInterval) {
			this.clearCacheInterval = setInterval(() => {
				console.log('clearing cache');
				this.clearAllData();
			}, this.intervalDuration);
		}
	}
	
	// Stop the interval to clear cache
	private stopClearCacheInterval(): void {
		if (this.clearCacheInterval) {
			clearInterval(this.clearCacheInterval);
			this.clearCacheInterval = null;
		}
	}
}