<!-- -----------header------------- -->
<app-title-header
  [title]="'Your Orders'"
  [backButtonRoute]="'/account'"
  [parentScreenName]="'Order list'"
></app-title-header>
<!-- -----------------end---------------------- -->
<!-- ---------------order---list-------------- -->
<ion-content>
  <ion-grid class="card-item-list" *ngFor="let order of orderListData">
    <!-- <ion-row (click)="navigateTo(order?._id, 'account/order-list/order-details')"> -->
    <ion-row>
      <ion-col size="2" class="cart-icon">
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-col>
      <ion-col size="10" class="ion-padding-start">
        <h5>
          <b>{{ order?.orderStatusDescription }}</b>
        </h5>
        <small class="text-gray">&#8377; {{ order?.itemTotal }} 
          <span *ngIf="order?.orderType !== 'walletRecharge'">&middot; {{order?.products.length +(order?.products.length === 1 ?' item ':' items ')}}</span>
          <span *ngIf="order?.deliveryStatus === 'delivered'">&middot; {{ (order?.deliveryTime | date)}} {{(order?.deliveryTime | date: 'shortTime') }}</span></small
        >
      </ion-col>
    </ion-row>

    <div class="multi-slider item-image ion-margin-top">
      <swiper-container [slidesPerView]="4" [loop]="false">
        <swiper-slide *ngFor="let item of order?.products">
          <ion-img
            src="{{ item?.images[0] }}"
            (ionError)="loadDefaultImg($event)"
          />
        </swiper-slide>
      </swiper-container>
    </div>

    <ion-row *ngIf="order?.orderType !== 'walletRecharge'">
      <ion-col
        size="6"
        (click)="navigateTo(order?._id, 'account/order-list/order-track')"
        *ngIf="order?.allowTracking"
      >
        <ion-button class="custom-outline-btn" expand="block">
          Track Order
        </ion-button>
      </ion-col>

      <ion-col size="6">
        <ion-button
          class="custom-outline-btn"
          expand="block"
          (click)="navigateTo(order?._id, 'account/order-list/order-details')"
        >
          Order Info
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div *ngIf="dataFound < 0" class="no-orders-img ion-text-center">
    <ion-img src="../../../../assets/img/daykart/no-order-found.png"></ion-img>
    <p>No orders yet? Start shopping now!</p>
  </div>

  <ion-infinite-scroll (ionInfinite)="loadMoreOrders($event)" threshold="25%">
    <ion-infinite-scroll-content *ngIf="totalPages > page" [loadingText]="loadingText" loadingSpinner="bubbles">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>
<!-- -------------------end-------------------- -->
