<!-- ------------your--cart-------------- -->
<app-modal-header
  [title]="'Oops! Items not in stock'"
  [subTitle]="cartOutOfStockProduct?.length > 0 ? '(' + cartOutOfStockProduct?.length + ')' : ''"
></app-modal-header>

<ion-content class="common-bg-color ion-padding-horizontal">
  <!-- -------product--list-section------- -->
  <app-product-card
    *ngFor="let product of cartOutOfStockProduct"
    [cardType]="'cart'"
    [productData]="product"
    [showCartButtons]="false"
    [parentScreenName]="'Cart Items popup in footer'"
    (messageEvent)="refreshCartModalList($event)"
  ></app-product-card>
  <!-- ---------------end---------------- -->
</ion-content>

<ion-footer class="out-of-stock-footer">
  <ion-row>
    <ion-col size="6">
      <ion-button expand="block" class="custom-outline-btn btn-gray" (click)="closeModal(false)">Cancel</ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button expand="block" class="common-btn" (click)="removeAllOutOfStockProducts()">
        <ion-icon slot="start" name="trash-outline" ></ion-icon>
        Remove all
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>

